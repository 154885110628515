import { ActionType, ErrorActions } from "./errorActions";

const defaultState: IErrorState = {
  error: { status: 0, title: "", errorList: [] },
};

const errorReducer = (
  state: IErrorState,
  action: ErrorActions
): IErrorState => {
  switch (action.type) {
    case ActionType.setError:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return defaultState;
  }
};

export default errorReducer;
