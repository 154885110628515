import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { ArrowLeftIcon } from "@heroicons/react/outline";

import MasterDataContext from "../../context/Masterdata/MasterDataContext";
import axios from "../../lib/axios/axios.nuvad";
import Card from "antd/es/card";
import Form from "antd/es/form";
import Input from "antd/es/input";
import Select from "antd/es/select";
import { generateSelectOptions } from "../../utils/formUtils";
import Switch from "antd/es/switch";
import Button from "antd/es/button";
import AppContext from "../../context/App/AppContext";
import { getErrorObj } from "../../utils/errorHandlingUtils";
import ErrorContext from "../errorhandling/context/errorContext";

interface IBusinessServiceFormRouteProps {
  type: string;
  serviceTypeId?: number;
}

const initialValues = {
  id: 0,
  businessTypeId: null,
  type: "",
  rateType: null,
  availablePressureType: false,
  availableGender: false,
  availableBookingType: false,
  availablePatientCondition: false,
  availableTag: false,
};

/**
 * Business service form page to and and edit services of businesses
 * @category Forms
 * @component
 * @namespace BusinessServiceForm
 * @returns BusinessServiceForm component form page
 */

const BusinessServiceForm: React.FC = () => {
  //Context
  const { triggerNotification } = useContext(AppContext);
  const { SPBusinessTypes, getBusinessTypes } = useContext(MasterDataContext);
  const { setError } = useContext(ErrorContext);

  const history = useHistory();
  const { state: routeData } = useLocation<IBusinessServiceFormRouteProps>();
  //states
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Custom hooks
  const [form] = Form.useForm();

  /**
   * Fn to handle form submit
   *@method handleSubmit
   *@param event
   */
  const handleSubmit = () => {
    const _Obj: ISPServiceType = {
      id: routeData.serviceTypeId !== undefined ? routeData.serviceTypeId : 0,
      ...form.getFieldsValue(),
    };
    setIsLoading(true);
    if (routeData.type === "edit") {
      updateBusinessService(_Obj);
    } else {
      addBusinessServiceType(_Obj);
    }
  };
  /**
   * Fn to route back to previous page
   *@method handlePreviousPage
   */
  const handlePreviousPage = () => {
    history.goBack();
  };
  /**
   * Fn to call POST endpoint to add new service type
   *@method addBusinessServiceType
   *@param servicePayload
   */
  const addBusinessServiceType = async (servicePayload: ISPServiceType) => {
    axios
      .post("/admin-api/BusinessServiceTypes", servicePayload)
      .then((response) => {
        setIsLoading(false);
        triggerNotification({
          type: "success",
          title: "Add Service",
          message: <p>{form.getFieldValue("type")} added successfully.</p>,
        });
      })
      .catch((error) => {
        setIsLoading(false);
        const _errorObj = getErrorObj(error.response);
        setError(_errorObj);
      });
  };
  /**
   * Fn to call GET business service type
   *@method getBusinessServiceById
   *@param id service type id
   */
  const getBusinessServiceById = async (id: number) => {
    await axios
      .get(`/admin-api/BusinessServiceTypes/${id}`)
      .then((response) => {
        const {
          id,
          businessTypeId,
          type,
          availablePressureType,
          availableGender,
          availableBookingType,
          availablePatientCondition,
          availableTag,
        }: ISPServiceType = response.data;
        const _service: ISPServiceType = {
          id: id,
          businessTypeId: businessTypeId,
          type: type,
          availablePressureType: availablePressureType,
          availableGender: availableGender,
          availableBookingType: availableBookingType,
          availablePatientCondition: availablePatientCondition,
          availableTag: availableTag,
        };
        form.setFieldsValue(_service);
      })
      .catch((error) => {
        const _errorObj = getErrorObj(error.response);
        setError(_errorObj);
      });
  };
  /**
   * Fn to call PUT to update business service type
   *@method updateBusinessService
   *@param servicePayload
   */
  const updateBusinessService = async (servicePayload: ISPServiceType) => {
    axios
      .put("/admin-api/BusinessServiceTypes", servicePayload)
      .then((response) => {
        setIsLoading(false);
        triggerNotification({
          type: "success",
          title: "Edit Service",
          message: <p>{form.getFieldValue("type")} edited successfully.</p>,
        });
      })
      .catch((error) => {
        setIsLoading(false);
        const _errorObj = getErrorObj(error.response);
        setError(_errorObj);
      });
  };

  /**
   * Function to handle form fail
   *@method onFinishFailed
   */
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (SPBusinessTypes.length === 0) getBusinessTypes(2);
  }, []);
  useEffect(() => {
    switch (routeData.type) {
      case "add":
        // fetchFormData();
        break;
      case "edit":
        routeData.serviceTypeId !== undefined &&
          getBusinessServiceById(routeData.serviceTypeId);
        break;
    }
  }, [SPBusinessTypes]);

  return (
    <>
      <div className="flex items-center mb-10" id="title-bar">
        <ArrowLeftIcon
          className="h-7 w-7 mr-4 cursor-pointer text-gray-900"
          onClick={handlePreviousPage}
        />
        <h1 className="mb-0">
          <span className="capitalize">{routeData.type}</span> Business Service
        </h1>
      </div>
      <Card title="Service Information" bordered={false} className="mb-3">
        <Form
          form={form}
          name="businessServiceForm"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 780 }}
          initialValues={initialValues}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <div className="columns-1">
            <Form.Item
              key={"type"}
              id={"type"}
              label={"Service Name"}
              name={"type"}
              style={{ fontWeight: "500", width: "50%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter service name",
                },
              ]}
            >
              <Input placeholder="massage" autoComplete="nope" />
            </Form.Item>
          </div>
          <div className="columns-1 ">
            <Form.Item
              key={"businessTypeId"}
              id={"businessTypeId"}
              label={"Business Category"}
              name={"businessTypeId"}
              style={{ fontWeight: "500", width: "50%" }}
              rules={[
                {
                  required: true,
                  message: "Please select provider type",
                },
              ]}
            >
              <Select
                placeholder="Select business category"
                options={generateSelectOptions(SPBusinessTypes, ["type", "id"])}
              />
            </Form.Item>
          </div>

          <div className="columns-5">
            <Form.Item
              key={"availablePressureType"}
              id={"availablePressureType"}
              label={"Pressure Type"}
              name={"availablePressureType"}
              style={{ fontWeight: "500" }}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              key={"availableGender"}
              id={"availableGender"}
              label={"Gender"}
              name={"availableGender"}
              style={{ fontWeight: "500" }}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              key={"availableBookingType"}
              id={"availableBookingType"}
              label={"Booking Type"}
              name={"availableBookingType"}
              style={{ fontWeight: "500" }}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              key={"availablePatientCondition"}
              id={"availablePatientCondition"}
              label={"Patient Condition"}
              name={"availablePatientCondition"}
              style={{ fontWeight: "500" }}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              key={"availableTag"}
              id={"availableTag"}
              label={"Tags"}
              name={"availableTag"}
              style={{ fontWeight: "500" }}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </div>
          <Form.Item wrapperCol={{ span: 16 }}>
            <Button
              type="primary"
              ghost
              onClick={handlePreviousPage}
              size="large"
            >
              Back
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="btn-antd--primary"
              loading={isLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default BusinessServiceForm;
