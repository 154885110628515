import React, { useCallback } from "react";
import Card from "antd/es/card";
import { Descriptions, Divider, Tag, Timeline, TimelineItemProps } from "antd";
import { getStatusColor } from "../../../utils";
import { CashIcon, CreditCardIcon } from "@heroicons/react/outline";

import CostSummary from "../../../templates/Session/CostSummary";
import UserSummary from "../../../templates/Session/UserSummary";
interface IOutcallSummaryTabProps {
  outcallSession?: IOutcallSessionSummary;
}
const OutcallSummaryTab: React.FC<IOutcallSummaryTabProps> = ({
  outcallSession,
}: IOutcallSummaryTabProps) => {
  const getTimeline = useCallback(() => {
    const _timeLine: TimelineItemProps[] = [
      { children: `Created ${outcallSession?.createdDateTime}` },
    ];
    if (outcallSession?.acceptedDateTime !== "Invalid Date")
      _timeLine.push({
        children: `Accepted ${outcallSession?.acceptedDateTime}`,
      });
    if (outcallSession?.scheduleDateTime !== "Invalid Date")
      _timeLine.push({
        children: `Scheduled ${outcallSession?.scheduleDateTime}`,
      });
    if (outcallSession?.sessionStartedDateTime !== "Invalid Date")
      _timeLine.push({
        children: `Start ${outcallSession?.sessionStartedDateTime}`,
      });
    if (outcallSession?.sessionEndedDateTime !== "Invalid Date")
      _timeLine.push({
        children: `Ended ${outcallSession?.sessionEndedDateTime}`,
      });
    if (outcallSession?.cancelledDateTime !== "Invalid Date")
      _timeLine.push({
        children: `Cancelled ${outcallSession?.cancelledDateTime}`,
        color: "red",
      });
    return _timeLine;
  }, [outcallSession]);

  return (
    <div id="summary-wrapper">
      <div className="grid grid-cols-1 gap-4">
        {/*Row */}
        <div className="grid grid-cols-3 gap-4">
          <Card className="col-span-2">
            <Descriptions title="Session Details" layout="vertical">
              <Descriptions.Item
                label="Session ID"
                labelStyle={{ fontWeight: "500" }}
                span={2}
              >
                {outcallSession?.id}
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                <Tag color={getStatusColor(outcallSession?.status as string)}>
                  {outcallSession?.status.toString().toUpperCase()}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item
                label="Type"
                labelStyle={{ fontWeight: "500" }}
              >
                {outcallSession?.sessionType}
              </Descriptions.Item>
              <Descriptions.Item
                label="Business Type"
                labelStyle={{ fontWeight: "500" }}
                contentStyle={{ textTransform: "capitalize" }}
              >
                {outcallSession?.businessType}
              </Descriptions.Item>
              <Descriptions.Item label="Payment Method" span={2}>
                <div className="flex align-middle">
                  {outcallSession?.paymentMethod.toLowerCase() === "card" ? (
                    <CreditCardIcon className="w-6 mr-2  text-gray-900" />
                  ) : (
                    <CashIcon className="w-6 mr-2  text-gray-900" />
                  )}
                  <span>{outcallSession?.paymentMethod}</span>
                </div>
              </Descriptions.Item>
              <Descriptions.Item
                label="Address"
                labelStyle={{ fontWeight: "500" }}
                span={2}
                contentStyle={{ textTransform: "capitalize" }}
              >
                {outcallSession?.customerAddress}
              </Descriptions.Item>
            </Descriptions>
          </Card>
          <Card>
            <Timeline
              pending={outcallSession?.status === "Pending" ? true : false}
              items={getTimeline()}
            />
          </Card>
        </div>
        {/*Row */}
        <div className="grid grid-cols-3 gap-4">
          <Descriptions layout="vertical" bordered className="col-span-2 ">
            <Descriptions.Item
              label="Service Breakdown"
              labelStyle={{ fontWeight: "500" }}
              span={2}
            >
              {/*service details*/}
              <div className="flex flex-col w-full">
                <div className="flex justify-between align-top mb-2">
                  <span className="capitalize">
                    {outcallSession?.serviceName}
                  </span>

                  <span>
                    {` ${outcallSession?.currency}
                    ${outcallSession?.totalSessionPrice.toFixed(2)}`}
                  </span>
                </div>
                <div className="flex mb-2">
                  <span>{`Gender - ${outcallSession?.gender}`}</span>
                </div>
                {outcallSession?.businessType === "beautician" ? (
                  <div className="flex flex-col">
                    <span className="capitalize mb-2">
                      {`Own Product - ${
                        outcallSession?.useOwnProduct ? "Yes" : "No"
                      }`}
                    </span>
                    <span className="capitalize">
                      {`Note ${outcallSession?.note}`}
                    </span>
                  </div>
                ) : (
                  <span>{outcallSession?.pressureLevel}</span>
                )}
              </div>
              <Divider style={{ margin: ".5rem" }} />
              {/*cost summary */}
              <div className="flex flex-col w-full">
                {outcallSession?.costSummary.map((item, index) => (
                  <CostSummary
                    key={index}
                    currency={outcallSession.currency}
                    {...item}
                  />
                ))}
              </div>
              <Divider style={{ margin: ".5rem" }} />
              {/*cost total */}
              <div className="flex flex-col w-full">
                <div className="flex justify-between align-top mb-2">
                  <span className="capitalize font-semibold">Total</span>

                  <span>
                    {` ${outcallSession?.currency}
                    ${outcallSession?.totalPrice.toFixed(2)}`}
                  </span>
                </div>
              </div>
            </Descriptions.Item>
          </Descriptions>
          <div>
            <Card className=" mb-4">
              <UserSummary user={outcallSession?.serviceProvider} />
            </Card>
            <Card>
              <UserSummary user={outcallSession?.customer} />
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OutcallSummaryTab;
