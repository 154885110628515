export const enum ProviderType {
  individual = 1,
  business = 2,
}

export const enum RateType {
  flat = 1,
  hourly = 2,
  daily = 3,
  weekly = 4,
}
