import React, { Fragment, useMemo, useReducer } from "react";
import { MasterDataActionType } from "../../store/reducers";
import MasterDataContext from "./MasterDataContext";
import masterDataReducer from "../../store/reducers/MasterData";
import axios from "../../lib/axios/axios.nuvad";

interface IMasterDataProviderProps {
  children: React.ReactNode;
}

const initialAppState = {
  SPIndividualTypes: [],
  SPBusinessTypes: [],
};
const oidcStorage: IOIDCStorage = JSON.parse(
  sessionStorage.getItem(
    `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
  ) || "{}"
);
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + oidcStorage.access_token,
  },
};
const MasterDataProvider: React.FC<IMasterDataProviderProps> = ({
  children,
}: IMasterDataProviderProps) => {
  const [state, dispatch] = useReducer(masterDataReducer, initialAppState);

  const memoizedInput: IMasterDataMemo = useMemo(() => {
    return {
      state,
    };
  }, [state]);

  /**
   * Fn to call GET business types by providerType id
   *@method getBusinessTypes
   *@param providerTypeId providerType id
   */
  const getBusinessTypesHandler = async (providerTypeId: number) => {
    await axios
      .get(
        `/admin-api/BusinessTypes/GetByProviderTypeId/${providerTypeId}`,
        config
      )
      .then((response) => {
        const _businessTypes: IBusinessType[] = response.data.businessTypes;

        if (providerTypeId === 1) {
          dispatch({
            type: MasterDataActionType.GetSPIndividualTypes,
            payload: _businessTypes,
          });
        } else if (providerTypeId === 2) {
          dispatch({
            type: MasterDataActionType.GetSPBusinessTypes,
            payload: _businessTypes,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const appProviderValue: IMasterDataContext = {
    memoizedInput: memoizedInput,
    SPIndividualTypes: state.SPIndividualTypes,
    SPBusinessTypes: state.SPBusinessTypes,
    getBusinessTypes: getBusinessTypesHandler,
  };

  return (
    <MasterDataContext.Provider value={appProviderValue}>
      <Fragment>{children}</Fragment>
    </MasterDataContext.Provider>
  );
};

export default MasterDataProvider;
