/**
 * Function to build summary
 *@method handleWalletSummary
 *@param wallet wallet data from endpoint
 *@returns  array of summary sections
 */

export const handleWalletSummary = (
  wallet: IWalletSummary
): ISummarySection[] => {
  const _summary: ISummarySection[] = [
    {
      header: {
        title: "Wallet Details",
        subtitle: "Wallet details and application.",
      },
      body: [
        { id: "accountName", label: "Account Name", value: "" },
        { id: "accountNumber", label: "Account Number", value: "" },
        {
          id: "bankName",
          label: "Bank Name",
          value: "",
        },
        { id: "branchName", label: "Branch Name", value: "" },
        { id: "address", label: "Branch Address", value: "" },
        { id: "swiftCode", label: "Swift Code", value: "" },
        { id: "countryId", label: "Country", value: "" },
      ],
    },
  ];
  Object.entries(wallet).forEach((sessionProp: Record<string, any>) => {
    _summary.forEach((section) => {
      section.body.forEach((formObj) => {
        if (formObj.id === sessionProp[0]) {
          formObj.value = sessionProp[1];
        }
      });
    });
  });
  return _summary;
};
