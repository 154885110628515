import Layout, { Content, Footer, Header } from "antd/es/layout/layout";
import Sider from "antd/es/layout/Sider";
import theme from "antd/es/theme";
import React, { useContext } from "react";
import { SideBar } from "../components/organisms";
import { AuthContext } from "../providers/authProvider";

export const AuthLayout = ({ children }: AuthLayoutProps): JSX.Element => {
  //Context
  const { logout } = useContext(AuthContext);

  const handleLogout = () => {
    logout();
  };
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  return (
    <div className="bg-gray-100 dark:bg-gray-900 dark:text-white text-gray-600 flex h-full ">
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          breakpoint="lg"
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            console.log(collapsed, type);
          }}
          style={{ width: "220px" }}
          width={220}
        >
          <SideBar logoutFn={handleLogout} />
        </Sider>
        <Layout>
          <Content style={{ margin: "24px 16px 0" }}>{children}</Content>
          <Footer style={{ textAlign: "center" }}>
            Ant Design ©2023 Created by Ant UED
          </Footer>
        </Layout>
      </Layout>
      {/* <SideBar logoutFn={handleLogout} />
      <div className="flex-grow min-h-screen flex flex-col">
        <div className="container mx-auto relative z-10  p-4 h-full">
          {children}
        </div>
      </div> */}
    </div>
  );
};
