import React, {
  ChangeEvent,
  FormEvent,
  useContext,
  useEffect,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";

import { ArrowLeftIcon } from "@heroicons/react/outline";

import AppContext from "../../context/App/AppContext";
import axios from "../../lib/axios/axios.nuvad";
import {
  Button,
  Card,
  Form,
  Popconfirm,
  Select,
  Switch,
  Table,
  Typography,
} from "antd";
import Input from "antd/es/input/Input";
import { generateCountriesOptions } from "../../utils/formUtils";

import EditableCell from "./components/EditableCell";
import { getErrorObj } from "../../utils/errorHandlingUtils";
import ErrorContext from "../errorhandling/context/errorContext";
import Measure from "../../components/atoms/Measure/Measure";

interface IBankFormRouteProps {
  type: string;
  bankId?: number;
}

interface DataType extends IBankBranch {
  key: React.Key;
}

const initialValues = {
  id: 0,
  active: false,
  countryId: null,
  name: "",
};

/**
 * Bank form page to and and edit banks
 * @category Forms
 * @component
 * @namespace BankForm
 * @returns BankForm component form page
 */

export const BankForm: React.FC = () => {
  //Context
  const { countries, getActiveCountires, triggerNotification } =
    useContext(AppContext);
  const { setError } = useContext(ErrorContext);

  const history = useHistory();
  const { state: routeData } = useLocation<IBankFormRouteProps>();

  const [branchName, setBranchName] = useState<string>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editingKey, setEditingKey] = useState<React.Key>("");

  const [dataSource, setDataSource] = useState<DataType[]>([]);

  // Custom hooks
  const [form] = Form.useForm();
  const [branchForm] = Form.useForm();
  const before = process.env.NODE_ENV === "development" ? performance.now() : 0;

  const isEditing = (record: DataType) => record.key === editingKey;

  /**
   * Function to edit in table
   *@method edit
   *@param record
   */
  const edit = (record: Partial<DataType> & { key: React.Key }) => {
    branchForm.setFieldsValue({
      name: "",
      address: "",
      swiftCode: "",
      routingCode: "",
      ...record,
    });
    setEditingKey(record.key);
  };

  /**
   * Function to cancel in table
   *@method cancel
   *@param record
   */
  const cancel = () => {
    setEditingKey("");
  };

  /**
   * Function to save in table
   *@method save
   *@param save
   */
  const save = async (key: React.Key) => {
    try {
      const row = (await branchForm.validateFields()) as DataType;

      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });
        setDataSource(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setDataSource(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };
  const tbComponents = {
    body: {
      cell: EditableCell,
    },
  };

  //keys to build editable table
  const columns = [
    {
      title: "name",
      dataIndex: "name",
      width: "30%",
      editable: true,
    },
    {
      title: "Address",
      dataIndex: "address",
      editable: true,
    },
    {
      title: "Swift Code",
      dataIndex: "swiftCode",
      editable: true,
    },
    {
      title: "Routing No",
      dataIndex: "routingCode",
      editable: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: DataType) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        inputType: "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        required: record.routingCode || record.swiftCode ? false : true,
      }),
    };
  });

  // const tbKeys = [
  //   {
  //     key: "id",
  //     title: "ID",
  //   },
  //   {
  //     key: "bankId",
  //     title: "Bank ID",
  //   },
  //   {
  //     key: "name",
  //     title: "Branch Name",
  //   },
  //   {
  //     key: "address",
  //     title: "Address",
  //   },
  //   {
  //     key: "swiftCode",
  //     title: "Swift Code",
  //   },
  //   {
  //     key: "routingCode",
  //     title: "Routing Code",
  //   },
  // ];

  // const tbRowConFig: IEditableCell[] = [
  //   {
  //     id: "id",
  //     value: "",
  //     isEditable: false,
  //     inputType: "input",
  //     inputConfig: {
  //       name: "",
  //       placeholder: "id",
  //       type: "input",
  //     },
  //   },
  //   {
  //     id: "bankId",
  //     value: "",
  //     isEditable: false,
  //     inputType: "input",
  //     inputConfig: {
  //       name: "",
  //       placeholder: "Bank id",
  //       type: "input",
  //     },
  //   },
  //   {
  //     id: "name",
  //     value: "",
  //     isEditable: true,
  //     inputType: "input",
  //     inputConfig: {
  //       name: "",
  //       placeholder: "Branch name",
  //       type: "input",
  //     },
  //   },
  //   {
  //     id: "address",
  //     value: "",
  //     isEditable: true,
  //     inputType: "input",
  //     inputConfig: {
  //       name: "",
  //       placeholder: "Enter address",
  //       type: "input",
  //     },
  //   },
  //   {
  //     id: "swiftCode",
  //     value: "",
  //     isEditable: true,
  //     inputType: "input",
  //     inputConfig: {
  //       name: "",
  //       placeholder: "Enter swift code",
  //       type: "input",
  //     },
  //   },
  //   {
  //     id: "routingCode",
  //     value: "",
  //     isEditable: true,
  //     inputType: "input",
  //     inputConfig: {
  //       name: "",
  //       placeholder: "Enter routing code",
  //       type: "input",
  //     },
  //   },
  // ];

  /**
   * Function to call POST to add new bank
   *@method addNewBank
   *@param bankPayload
   */
  const addNewBank = async (bankPayload: IBankPayload) => {
    axios
      .post("/admin-api/Banks", bankPayload)
      .then((response) => {
        setIsLoading(false);
        triggerNotification({
          type: "success",
          title: "Add Bank",
          message: <p>{form.getFieldValue("name")} added successfully.</p>,
        });
      })
      .catch((error) => {
        setIsLoading(false);
        const _errorObj = getErrorObj(error.response);
        setError(_errorObj);
      });
  };
  /**
   * Function to call PUT to update bank
   *@method updateBank
   *@param bankPayload
   */
  const updateBank = (bankPayload: IBankPayload) => {
    axios
      .put("/admin-api/Banks", bankPayload)
      .then((response) => {
        setIsLoading(false);
        triggerNotification({
          type: "success",
          title: "Edit Bank",
          message: <p>{form.getFieldValue("name")} edited successfully.</p>,
        });
      })
      .catch((error) => {
        setIsLoading(false);
        const _errorObj = getErrorObj(error.response);
        setError(_errorObj);
      });
  };

  /**
   * Function to generate bank form's state
   *@method fetchFormData
   */
  // const fetchFormData = async () => {
  //   const _form: IBankFormConfig = await MASTER_FORM_CONFIG.bankForm; //await statement
  //   const formElementsArray: IFormInput[] = [];
  //   Object.keys(_form).forEach((elm) => {
  //     switch (elm) {
  //       case "name":
  //         formElementsArray.push({
  //           id: elm,
  //           label: _form[elm].label,
  //           elementType: _form[elm].elementType,
  //           elementConfig: _form[elm].elementConfig,
  //           value: _form[elm].value !== undefined ? _form[elm].value : "",
  //         });
  //         break;
  //       case "active":
  //         formElementsArray.push({
  //           id: elm,
  //           label: _form[elm].label,
  //           elementType: _form[elm].elementType,
  //           elementConfig: _form[elm].elementConfig,
  //           value: _form[elm].checked !== undefined ? _form[elm].checked : "",
  //         });
  //         break;
  //       case "countryId":
  //         const _countries: SelectOption[] = [];
  //         countries?.length !== 0 &&
  //           countries?.map((country) =>
  //             _countries.push({ id: country.id, value: country.name })
  //           );
  //         formElementsArray.push({
  //           id: elm,
  //           label: _form[elm].label,
  //           elementType: _form[elm].elementType,
  //           elementConfig: _form[elm].elementConfig,
  //           value: _form[elm].value !== undefined ? _form[elm].value : "",
  //           options: _countries,
  //         });
  //         break;
  //     }
  //   });

  //   setForm(formElementsArray);
  // };
  /**
   * Function to handle branch name input
   *@method handleBranchName
   *@param event
   */
  const handleBranchName = (
    event:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>
      | FormEvent<HTMLSelectElement>
  ) => {
    event.persist();
    const { value } = event.currentTarget;
    setBranchName(value);
  };
  /**
   * Function to add new bank branch
   *@method handleAddNewBranch
   */
  const handleAddNewBranch = () => {
    const newData: DataType = {
      key: branchName !== undefined ? branchName : "",
      id: 0,
      bankId: 0,
      name: branchName !== undefined ? branchName : "",
      address: "",
      swiftCode: "",
      routingCode: "",
    };
    setDataSource([...dataSource, newData]);

    // const _tbRows: IEditableCell[][] = [...tbRows];
    // const _tempRow: IEditableCell[] = [];
    // // get index to set rowId
    // const index = _tbRows.length !== 0 ? _tbRows.length : _tbRows.length;
    // Object.entries(_branch).forEach((item) => {
    //   const _tbCell: IEditableCell = {
    //     id: "",
    //     value: "",
    //     isEditable: true,
    //     inputType: "input",
    //     inputConfig: {
    //       name: "",
    //       placeholder: "",
    //       type: "input",
    //     },
    //     rowId: index,
    //   };
    //   switch (item[0]) {
    //     case "name":
    //       _tbCell.id = item[0];
    //       _tbCell.value = branchName !== undefined ? branchName : "";
    //       _tempRow.push(_tbCell);
    //       break;
    //     case "bankId":
    //       _tbCell.id = item[0];
    //       _tbCell.value =
    //         routeData.type === "edit" ? values.id.toString() : "0";
    //       _tbCell.isEditable = false;
    //       _tempRow.push(_tbCell);
    //       break;
    //     case "id":
    //       _tbCell.id = item[0];
    //       _tbCell.value = item[1].toString();
    //       _tbCell.isEditable = false;
    //       _tempRow.push(_tbCell);
    //       break;
    //     default:
    //       _tbCell.id = item[0];
    //       _tbCell.value = item[1] as unknown as string;
    //       _tempRow.push(_tbCell);
    //   }
    // });

    // _tbRows[0] && _tbRows[0].length === 0
    //   ? (_tbRows[0] = _tempRow)
    //   : _tbRows.push(_tempRow);

    // setTbRows(_tbRows);
  };
  /**
   * Function to handle form submit
   *@method handleSubmit
   *@param event
   */
  const handleSubmit = async () => {
    try {
      await form.validateFields();
      const _branches = dataSource.map(({ key, ...branch }) => branch);
      const _Obj: IBankPayload = {
        id: routeData.bankId !== undefined ? routeData.bankId : 0,
        branches: _branches,
        ...form.getFieldsValue(),
      };

      setIsLoading(true);

      if (routeData.type === "edit") {
        updateBank(_Obj);
      } else {
        addNewBank(_Obj);
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  const handlePreviousPage = () => {
    history.goBack();
  };
  /**
   * Function to get bank by id
   *@method getBankById
   *@param id bank id
   */
  const getBankById = (id: number) => {
    axios
      .get(`/admin-api/Banks/${id}`)
      .then((response) => {
        const { bank, branches }: IBankGetPayload = response.data;
        const _bank: IBankPayload = {
          ...bank,
          branches: branches,
        };

        const _branches = branches.map((obj) => ({
          ...obj,
          key: `${obj.id}-${obj.name} `,
        }));
        form.setFieldsValue(bank);
        setDataSource(_branches);

        console.log(_bank);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    if (countries?.length === 0) getActiveCountires();
  }, []);
  useEffect(() => {
    switch (routeData.type) {
      case "add":
        // fetchFormData();
        break;
      case "edit":
        routeData.bankId !== undefined &&
          countries?.length !== 0 &&
          getBankById(routeData.bankId);
        break;
    }
  }, [countries]);

  return (
    <>
      <div className="flex items-center mb-10" id="title-bar">
        <ArrowLeftIcon
          className="h-7 w-7 mr-4 cursor-pointer text-gray-900"
          onClick={handlePreviousPage}
        />
        <h1 className="mb-0">
          <span className="capitalize">{routeData.type}</span> Bank
        </h1>
      </div>
      <Card title="Bank Form" bordered={false} className="mb-3">
        <Form
          form={form}
          name="bankForm"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 780 }}
          initialValues={initialValues}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <div className="columns-1 ">
            <Form.Item
              key={"countryId"}
              id={"countryId"}
              label={"Country"}
              name={"countryId"}
              style={{ fontWeight: "500", width: "50%" }}
              rules={[{ required: true, message: "Select a country" }]}
            >
              <Select
                options={generateCountriesOptions(countries ? countries : [])}
              />
            </Form.Item>
          </div>
          <div className="columns-1">
            <Form.Item
              key={"name"}
              id={"name"}
              label={"Name"}
              name={"name"}
              style={{ fontWeight: "500", width: "50%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter bank name",
                },
              ]}
            >
              <Input autoComplete="nope" />
            </Form.Item>
          </div>

          <div className="columns-1">
            <Form.Item
              key={"active"}
              id={"active"}
              label={"Status"}
              name={"active"}
              style={{ fontWeight: "500" }}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </div>
        </Form>
      </Card>
      <Card title="Branches" bordered={false} className="mb-3">
        <div className=" flex">
          <div className="px-2 md:w-1/3 mb-2">
            <Input
              autoComplete="nope"
              onChange={handleBranchName}
              value={branchName}
              placeholder="Enter branch name"
            />
          </div>
          <div className="w-full px-2 md:w-1/2 mb-3">
            <Button onClick={handleAddNewBranch} type="primary">
              Add a row
            </Button>
          </div>
        </div>
        <Form form={branchForm} component={false}>
          <Table
            components={tbComponents}
            rowClassName="editable-row"
            bordered
            dataSource={dataSource}
            columns={mergedColumns}
          />
        </Form>
      </Card>
      <div className="flex">
        <Button type="primary" ghost onClick={handlePreviousPage} size="large">
          Back
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          className="btn-antd--primary"
          loading={isLoading}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
      {process.env.NODE_ENV === "development" && <Measure before={before} />}
    </>
  );
};
