import { ArrowLeftIcon } from "@heroicons/react/outline";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { FormState, useFormInput } from "../../../hooks";
import { PAYOUTS } from "../../../constants/payoutForms";
import axios from "../../../lib/axios/axios.nuvad";
import message from "antd/es/message";
import AppContext from "../../../context/App/AppContext";
import FormInput from "../../../components/atoms/FormInput/FormInput";

interface ICreatePayoutsFormConfig {
  countryId: IFormConfig;
  // paymentMethod: IFormConfig;
  // payoutBufferDays: IFormConfig;
  // payoutStartDate: IFormConfig;
}

const oidcStorage: IOIDCStorage = JSON.parse(
  sessionStorage.getItem(
    `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
  ) || "{}"
);
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + oidcStorage.access_token,
  },
};
const paymentOptions = [{ id: 1, value: "Bank In" }];
const initialValues = {
  countryId: 0,
  payoutStartDate: "",
  payoutBufferDays: 0,
  paymentMethod: 1,
};
/**
 * Payout Create form page to manually generates pending payouts
 * @category Forms
 * @component
 * @namespace Payouts
 * @returns CreatePayouts component form page
 */

export const CreatePayouts: React.FC = () => {
  //Context
  const { countries, getActiveCountires } = useContext(AppContext);

  const history = useHistory();
  const [form, setForm] = useState<IFormInput[]>([]);

  // Custom hooks
  const { values, handleChange } = useFormInput(
    initialValues as unknown as FormState
  );
  /**
   * fn to generate form
   *@method fetchFormData
   */
  const fetchFormData = async () => {
    const _form: ICreatePayoutsFormConfig = await PAYOUTS.createPayoutForm; //await statement
    const formElementsArray: IFormInput[] = [];
    Object.keys(_form).forEach((elm) => {
      switch (elm) {
        // case "payoutBufferDays":
        // case "payoutStartDate":
        //   formElementsArray.push({
        //     id: elm,
        //     label: _form[elm].label,
        //     elementType: _form[elm].elementType,
        //     elementConfig: _form[elm].elementConfig,
        //     value: _form[elm].value !== undefined ? _form[elm].value : "",
        //   });
        //   break;
        case "countryId":
          const _countries: SelectOption[] = [];
          countries?.length !== 0 &&
            countries?.map((country) =>
              _countries.push({ id: country.id, value: country.name })
            );
          formElementsArray.push({
            id: elm,
            label: _form[elm].label,
            elementType: _form[elm].elementType,
            elementConfig: _form[elm].elementConfig,
            value: _form[elm].value !== undefined ? _form[elm].value : "",
            options: _countries,
          });
          break;
        // case "paymentMethod":
        //   formElementsArray.push({
        //     id: elm,
        //     label: _form[elm].label,
        //     elementType: _form[elm].elementType,
        //     elementConfig: _form[elm].elementConfig,
        //     value: _form[elm].value !== undefined ? _form[elm].value : "",
        //     options: paymentOptions,
        //   });
        //   break;
      }
    });

    setForm(formElementsArray);
  };

  /**
   * fn to toggle multi-select table
   *@method handleBulkApprove
   */
  const createPendingPayouts = async (_payload: ICreatePayout) => {
    await axios
      .post(`finance-api/Payouts/Create`, _payload, config)
      .then((response) => {
        console.log(response.data);
        message.success(`Create payouts successful `);
      })
      .catch((error) => {
        console.log(error);
        message.error(`Create payouts unsuccessful `);
      });
  };
  /**
   * Function to handle form submit
   *@method handleSubmit
   *@param event
   */
  const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    const __createPayout: ICreatePayout = {
      countryId: 0,
      // payoutStartDate: "",
      // payoutBufferDays: 0,
      // paymentMethod: 1,
    };
    Object.keys(__createPayout).forEach((propName) => {
      switch (propName) {
        // case "payoutStartDate":
        //   typeof values[propName] === "string" &&
        //     (__createPayout[propName] = values[propName] as string);
        //   break;

        case "countryId":
          // case "payoutBufferDays":
          // case "paymentMethod":
          typeof values[propName] === "number" &&
            (__createPayout[propName] = values[propName] as number);
          break;
        default:
      }
    });

    console.log(__createPayout, "handleSubmit");
    createPendingPayouts(__createPayout);
  };

  // fn to handle routing back to previous page
  const handlePreviousPage = () => {
    history.goBack();
  };
  useEffect(() => {
    countries?.length === 0 ? getActiveCountires() : fetchFormData();
  }, [countries]);

  return (
    <div className="p-8">
      <div className="flex justify-between items-center mb-10" id="title-bar">
        <div className="flex items-center ">
          <ArrowLeftIcon
            className=" h-7 w-7 mr-4 cursor-pointer text-gray-900"
            onClick={handlePreviousPage}
          />
          <h1 className="mb-0">Create Payout</h1>
        </div>
      </div>
      <div className="form bg-white p-4">
        <form className="space-y-4 text-gray-700" id="promotion-form">
          {form.map((elem, index) => {
            if (elem.id === "countryId" || elem.id === "paymentMethod") {
              return (
                <div className="flex flex-wrap " key={index}>
                  <div className="w-full px-2 md:w-1/2">
                    <FormInput
                      id={elem.id}
                      label={elem.label}
                      elementType={elem.elementType}
                      elementConfig={elem.elementConfig}
                      value={
                        values[elem.elementConfig.name] !== undefined
                          ? values[elem.elementConfig.name]
                          : ""
                      }
                      handleChange={handleChange}
                      options={elem.options}
                    />
                  </div>
                </div>
              );
            } else {
              return (
                <div className="flex flex-wrap" key={index}>
                  <div className="w-full px-2 md:w-1/2">
                    <FormInput
                      id={elem.id}
                      label={elem.label}
                      elementType={elem.elementType}
                      elementConfig={elem.elementConfig}
                      value={
                        values[elem.elementConfig.name] !== undefined
                          ? values[elem.elementConfig.name]
                          : ""
                      }
                      handleChange={handleChange}
                    />
                  </div>
                </div>
              );
            }
          })}
        </form>
      </div>
      <div className="flex mt-4">
        <button onClick={handlePreviousPage} className="btn mr-2">
          Back
        </button>
        <button onClick={(event) => handleSubmit(event)} className="btn">
          Create
        </button>
      </div>
    </div>
  );
};
