import { ArrowNarrowRightIcon } from "@heroicons/react/outline";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import DynamicTable from "../../../components/organisms/DynamicTable/DynamicTable";

interface IDailyIncallSessionsTabProps {
  tbData: ITableCell[] | undefined;
}

export const DailyIncallSessionsTab: React.FC<IDailyIncallSessionsTabProps> = ({
  tbData,
}: IDailyIncallSessionsTabProps) => {
  const history = useHistory();
  const dailyOutcallColumns: ITableColumns[] = [
    {
      key: "rowNo",
      title: "#",
    },
    {
      key: "id",
      title: "ID",
    },
    {
      key: "paymentMethod",
      title: "Payment Method",
    },
    {
      key: "totalSessionPrice",
      title: "Amount",
    },
    {
      key: "createdDateTime",
      title: "Created Date",
    },
    {
      key: "status",
      title: "Status",
    },
    {
      key: "action",
      title: "",
      buttons: [{ key: "id", label: "View" }],
    },
  ];
  /**
   * fn to handle table's primary button
   *@method handleViewSummary
   *@param id id of session as number or string
   */
  const handleViewSummary = (id: number | string) => {
    history.push({
      pathname: `/sessionsummary/${id}/incall`,
    });
  };
  return (
    <>
      <div className="mb-3 flex justify-between items-center" id="title-bar">
        <h2 className="dark:text-slate-900">Daily Incall Sessions</h2>
        <Link
          to={{ pathname: "/allsessions", state: { type: "incall" } }}
          className="flex text-sm content-center text-slate-900"
        >
          View All
          <ArrowNarrowRightIcon className="cursor-pointer h-6 w-6 ml-2" />
        </Link>
      </div>
      <DynamicTable
        tbData={tbData}
        tbColumns={dailyOutcallColumns}
        id="daily-incall-sessions-tb"
        handlePrimaryFn={(id) => handleViewSummary(id)}
      />
    </>
  );
};
