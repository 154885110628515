import React, { useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";

import { TableCustomCell } from ".";

import axios from "../../../lib/axios/axios.nuvad";
import MasterDataContext from "../../../context/Masterdata/MasterDataContext";
import message from "antd/es/message";
import Table from "antd/es/table/Table";
import { ColumnsType } from "antd/es/table/InternalTable";
import ActionCell from "../../../templates/CustomCells/ActionCell";
import Modal from "antd/es/modal/Modal";
import ConfirmationTpl from "../../../templates/Modal/ConfirmationTpl";

interface ISPBConfigTabProps {
  tbBusinessTypes: ITableCell[] | undefined;
  tbServicesTypes: ITableCell[] | undefined;
  getBusinessServiceTypes: () => void;
}

const oidcStorage: IOIDCStorage = JSON.parse(
  sessionStorage.getItem(
    `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
  ) || "{}"
);
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + oidcStorage.access_token,
  },
};

export const SPBusinessesConfigTab: React.FC<ISPBConfigTabProps> = ({
  tbBusinessTypes,
  tbServicesTypes,
  getBusinessServiceTypes,
}: ISPBConfigTabProps) => {
  //Context

  const { getBusinessTypes } = useContext(MasterDataContext);

  const history = useHistory();
  //UI states
  const [showBusinessTypeModal, setShowBusinessTypeModal] =
    useState<boolean>(false);
  const [showBusinessServiceModal, setShowBusinessServiceModal] =
    useState<boolean>(false);
  //Data states
  const selectedBusinessTypeId = useRef<number | undefined>(undefined);
  const selectedBusinessServiceId = useRef<number | undefined>(undefined);
  // const typeColumns: ITableColumns[] = [
  //   {
  //     key: "rowNo",
  //     title: "#",
  //   },
  //   // {
  //   //   key: "id",
  //   //   title: "ID",
  //   // },
  //   {
  //     key: "type",
  //     title: "Type",
  //     customCell: true,
  //   },
  //   {
  //     key: "providerType",
  //     title: "Provider Type",
  //   },

  //   {
  //     key: "action",
  //     title: "",

  //     buttons: [
  //       { key: "id", label: "View" },
  //       { key: "id", label: "Edit" },
  //       { key: "id", label: "Delete" },
  //     ],
  //   },
  // ];
  const businessTypeColumns: ColumnsType<Record<string, any>> = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "type",
      title: "Type",
      dataIndex: "type",
      render: (_, record) => <TableCustomCell data={record} itemKey={`type`} />,
    },
    {
      key: "providerType",
      title: "Provider Type",
      dataIndex: "providerType",
    },

    {
      key: "action",
      title: "Action",
      render: (_, record) => (
        <ActionCell
          data={record}
          actions={[
            { key: "id", label: "Edit" },
            { key: "id", label: "Delete" },
          ]}
          handlePrimaryFn={handleEditBusinessType}
          handleSecondaryFn={handleDeleteBusinessType}
        />
      ),
    },
  ];
  const serviceColumns: ColumnsType<Record<string, any>> = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "type",
      title: "Type",
      dataIndex: "type",
    },
    {
      key: "businessTypeId",
      title: "Business Type",
      dataIndex: "businessTypeId",
    },

    {
      key: "action",
      title: "Action",
      render: (_, record) => (
        <ActionCell
          data={record}
          actions={[
            { key: "id", label: "Edit" },
            { key: "id", label: "Delete" },
          ]}
          handlePrimaryFn={handleEditService}
          handleSecondaryFn={handleDeleteService}
        />
      ),
    },
  ];
  // const serviceColumns: ITableColumns[] = [
  //   {
  //     key: "rowNo",
  //     title: "#",
  //   },

  //   {
  //     key: "type",
  //     title: "Type",
  //   },
  //   {
  //     key: "businessTypeId",
  //     title: "Business Type",
  //   },

  //   {
  //     key: "action",
  //     title: "",
  //     buttons: [
  //       { key: "id", label: "View" },
  //       { key: "id", label: "Edit" },
  //       { key: "id", label: "Delete" },
  //     ],
  //   },
  // ];
  /**
   * Fn to route to new SP business type form page
   *@method handleAddBusinessType
   */
  const handleAddBusinessType = () => {
    history.push({
      pathname: "/businesstypeform",
      state: {
        type: "add",
      },
    });
  };
  /**
   * Fn to route to new SP business type form page
   *@method handleEditBusinessType
   */
  const handleEditBusinessType = (id: string) => {
    history.push({
      pathname: "/businesstypeform",
      state: {
        type: "edit",
        businessTypeId: parseInt(id),
      },
    });
  };

  /**
   * Fn to route to new business service page
   *@method handleAddService
   */
  const handleAddService = () => {
    history.push({
      pathname: "/businessServiceForm",
      state: {
        type: "add",
      },
    });
  };

  /**
   * Fn to handle drop down secondary button click of services table
   *@method handleEditService
   *@param id id of the row clicked
   */
  const handleEditService = (id: string) => {
    history.push({
      pathname: "/businessServiceForm",
      state: {
        type: "edit",
        serviceTypeId: parseInt(id),
      },
    });
  };
  /**
   * Fn to route to view
   *@method handleViewBusinessType
   *@param id id of the row clicked
   */
  const handleViewBusinessType = (id: number | string) => {
    history.push({
      pathname: "/businesstypeform",
      state: {
        type: "view",
        businessTypeId: id,
      },
    });
  };

  /**
   * Fn to handle drop down tertiary button click
   *@method handleDeleteBusinessType
   *@param id id of the row clicked
   */
  const handleDeleteBusinessType = (id: string) => {
    selectedBusinessTypeId.current = parseInt(id);
    setShowBusinessTypeModal(true);
  };
  /**
   * Fn to handle drop down tertiary button click of SPI services table
   *@method handleDeleteService
   *@param id id of the row clicked
   */
  const handleDeleteService = (id: string) => {
    selectedBusinessServiceId.current = parseInt(id);
    setShowBusinessServiceModal(true);
  };
  /**
   * Fn to call DELETE endpoint to delete selected business type
   *@method deleteBusinessType
   *@param id id of the row clicked
   */
  const deleteBusinessType = async (id: number | undefined) => {
    await axios
      .delete(`/admin-api/BusinessTypes/${id}`, config)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          message.success(`Delete business type successful `);
          getBusinessTypes(2);
          setShowBusinessTypeModal(false);
        }
      })
      .catch((error) => {
        message.error(`Delete business type unsuccessful `);
        setShowBusinessTypeModal(false);
        console.log(error);
      });
  };
  /**
   * Fn to call DELETE endpoint to delete selected business service
   *@method deleteBusinessService
   *@param id id of the row clicked
   */
  const deleteBusinessService = async (id: number | undefined) => {
    await axios
      .delete(`/admin-api/BusinessServiceTypes/${id}`, config)
      .then((response) => {
        if (response.status === 200) {
          message.success(`Delete business service type successful`);
          getBusinessServiceTypes();
          setShowBusinessServiceModal(false);
        }
      })
      .catch((error) => {
        message.error(`Delete business service type unsuccessful`);
        setShowBusinessServiceModal(false);
        console.log(error);
      });
  };

  return (
    <>
      <div>
        <div className="flex items-center  mt-3 mb-2" id="table-toolbar">
          <h2 className="flex-none dark:text-slate-900">Business Types</h2>
          <div className="flex flex-grow justify-end">
            <button onClick={handleAddBusinessType} className="btn-sm mr-2">
              Add New
            </button>
          </div>
        </div>
        <Table dataSource={tbBusinessTypes} columns={businessTypeColumns} />
        {/* <DynamicTable
          tbData={tbBusinessTypes}
          tbColumns={typeColumns}
          id="spb-types-tb"
          handlePrimaryFn={(id) => handleViewBusinessType(id)}
          handleSecondaryFn={(id) => handleEditBusinessType(id)}
          handleTertiaryFn={(id) => handleDeleteBusinessType(id)}
          RenderTableCustomCell={(props: ITableCustomCell) => (
            <TableCustomCell {...props} />
          )}
        /> */}
        <div className="flex items-center  mt-3 mb-2" id="table-toolbar">
          <h2 className="flex-none dark:text-slate-900">Business Services</h2>
          <div className="flex flex-grow justify-end">
            <button onClick={handleAddService} className="btn-sm mr-2">
              Add New
            </button>
          </div>
        </div>
        <Table dataSource={tbServicesTypes} columns={serviceColumns} />
        {/* <DynamicTable
          tbData={tbServicesTypes}
          tbColumns={serviceColumns}
          id="spb-services-tb"
          handlePrimaryFn={(id) => handleViewBusinessType(id)}
          handleSecondaryFn={(id) => handleEditService(id)}
          handleTertiaryFn={(id) => handleDeleteService(id)}
          RenderTableCustomCell={(props: ITableCustomCell) => (
            <TableCustomCell {...props} />
          )}
        /> */}
      </div>
      <Modal
        title="Delete"
        open={showBusinessTypeModal}
        onOk={() => deleteBusinessType(selectedBusinessTypeId.current)}
        onCancel={() => setShowBusinessTypeModal(false)}
      >
        <ConfirmationTpl
          title="Delete Business Type"
          message="Are you sure you want to delete this business type?"
        />
      </Modal>
      <Modal
        title="Delete"
        open={showBusinessServiceModal}
        onOk={() => deleteBusinessService(selectedBusinessTypeId.current)}
        onCancel={() => setShowBusinessServiceModal(false)}
      >
        <ConfirmationTpl
          title="Delete Business Service"
          message="Are you sure you want to delete this business service type?"
        />
      </Modal>

      {/* {showBusinessServiceModal ? (
        <Modal
          modalActive={showBusinessTypeModal}
          setModalActive={setShowBusinessServiceModal}
          title={`Delete Business Service`}
          message={`Are you sure you want to delete this business service?`}
          primaryBtn={() =>
            deleteBusinessService(selectedBusinessServiceId.current)
          }
          testId="business-service-modal"
          primaryBtnText="Ok"
        />
      ) : null} */}
    </>
  );
};
