import React from "react";
import { useHistory } from "react-router-dom";

import { TableCustomCell } from ".";
import DynamicTable from "../../../components/organisms/DynamicTable/DynamicTable";
import Table from "antd/es/table/Table";
import { ColumnsType } from "antd/es/table";
import ActionCell from "../../../templates/CustomCells/ActionCell";

interface IBanksTabProps {
  tbData: ITableCell[] | undefined;
}

export const BanksTab: React.FC<IBanksTabProps> = ({
  tbData,
}: IBanksTabProps) => {
  const history = useHistory();
  const tableColumns: ColumnsType<Record<string, any>> = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "name",
      title: "Bank",
      dataIndex: "name",
    },
    {
      key: "countryId",
      title: "Country",
      dataIndex: "countryId",
    },
    {
      key: "branches",
      title: "Branches",
      dataIndex: "branches",
    },

    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (_, record) => (
        <TableCustomCell data={record} itemKey={`status`} />
      ),
    },
    {
      key: "action",
      title: "Action",
      render: (_, record) => (
        <ActionCell
          data={record}
          actions={[{ key: "id", label: "Edit" }]}
          handlePrimaryFn={() => handleEditBank(record?.id)}
        />
      ),
    },
  ];

  const handleEditBank = (id: string) => {
    history.push({
      pathname: "/bankform",
      state: {
        type: "edit",
        bankId: parseInt(id),
      },
    });
  };
  const addNewHandler = () => {
    history.push({
      pathname: "/bankform",
      state: {
        type: "add",
      },
    });
  };
  return (
    <>
      <div className="flex items-center mb-2" id="table-toolbar">
        <h2 className="flex-none dark:text-slate-900">Registered Banks</h2>
        <div className="flex flex-grow justify-end">
          <button onClick={addNewHandler} className="btn-sm mr-2">
            Add New
          </button>
        </div>
      </div>
      <Table dataSource={tbData} columns={tableColumns} />
      {/* <DynamicTable
        tbData={tbData}
        tbColumns={tableColumns}
        id="app-config-tb"
        handlePrimaryFn={(id) => handleTablePrimaryBtnFn(id)}
        handleSecondaryFn={(id) => handleTableSecondaryBtnFn(id)}
        RenderTableCustomCell={(props: ITableCustomCell) => (
          <TableCustomCell {...props} />
        )}
      /> */}
    </>
  );
};
