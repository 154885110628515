import Dropdown from "antd/es/dropdown/dropdown";
import { MenuProps } from "antd/es/menu/menu";
import { memo } from "react";

interface IActionCell {
  data: Record<string, any>;
  actions?: TableButton[];
  handlePrimaryFn?: (id: string) => void;
  handleSecondaryFn?: (id: string) => void;
  handleTertiaryFn?: (id: string) => void;
}
const ActionCell = ({
  data,
  actions,
  handlePrimaryFn,
  handleSecondaryFn,
  handleTertiaryFn,
}: IActionCell): JSX.Element => {
  const buildTableActionMenu = (
    data: ITableCell,
    buttons?: TableButton[]
  ): MenuProps => {
    const _items: MenuProps["items"] = [];
    buttons &&
      buttons.forEach((btn, btnIndex) => {
        _items.push({
          key: `${data.id}-${btnIndex}`,
          label: (
            <div
              onClick={() => {
                switch (btnIndex) {
                  case 0:
                    handlePrimaryFn && handlePrimaryFn(data[btn.key] as string);
                    break;
                  case 1:
                    handleSecondaryFn &&
                      handleSecondaryFn(data[btn.key] as string);
                    break;
                  case 2:
                    handleTertiaryFn &&
                      handleTertiaryFn(data[btn.key] as string);
                    break;
                }
              }}
            >
              {btn.label}
            </div>
          ),
        });
      });

    const menu: MenuProps = { items: _items };
    return menu;
  };

  return (
    <>
      <Dropdown menu={buildTableActionMenu(data, actions)} trigger={["click"]}>
        <button
          className="ant-dropdown-link"
          onClick={(e) => e.preventDefault()}
        >
          <svg
            className="inline-block h-6 w-6 fill-current text-gray-400 hover:text-gray-700"
            viewBox="0 0 24 24"
          >
            <path d="M12 6a2 2 0 110-4 2 2 0 010 4zm0 8a2 2 0 110-4 2 2 0 010 4zm-2 6a2 2 0 104 0 2 2 0 00-4 0z" />
          </svg>
        </button>
      </Dropdown>
    </>
  );
};

export default memo(ActionCell);
