export const VERIFY_PROFILE_FORM = {
  verifyForm: {
    action: {
      elementType: "select",
      label: "Approval Action",
      elementConfig: {
        name: "action",
      },
      value: "",
      options: [
        { value: 0, label: "Pending" },
        { value: 1, label: "Verified" },
        { value: 2, label: "Pending from Author" },
        { value: 3, label: "Rejected" },
      ],
    },
    section: {
      elementType: "select",
      label: "Sections",
      elementConfig: {
        name: "section",
      },
      value: "",
      options: [
        { value: 0, label: "All" },
        { value: 1, label: "Profile" },
        { value: 2, label: "Education" },
      ],
    },
    comment: {
      elementType: "input",
      label: "Comments",
      elementConfig: {
        type: "text",
        placeholder: "Enter a reason",
        name: "comment",
      },
      value: "",
    },
  },
};
