import { Avatar } from "antd";
import { memo } from "react";
import { UserIcon } from "@heroicons/react/outline";
interface IUserSummaryProps {
  user?: SessionUserProfile;
}
const UserSummary = ({ user }: IUserSummaryProps): JSX.Element => {
  return (
    <div className="flex align-top mb-2">
      {user?.profilePicture ? (
        <Avatar
          size={64}
          style={{ backgroundColor: "#fde3cf" }}
          src={<img src={user?.profilePicture} alt="avatar" />}
        />
      ) : (
        <Avatar
          size={64}
          style={{
            backgroundColor: "#87d068",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          icon={<UserIcon className="text-gray-900 w-8" />}
        />
      )}
      <div className="flex flex-col ml-2">
        <span>{user?.displayName}</span>
        <span>{user?.userName}</span>
        <span>{user?.email}</span>
      </div>
    </div>
  );
};

export default memo(UserSummary);
