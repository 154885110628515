export enum ActionType {
  AddCountires,
}

export interface IAddCountires {
  type: ActionType.AddCountires;
  payload: ICountries[];
}

export type AppActions = IAddCountires;
