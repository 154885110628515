export enum MasterDataActionType {
  GetSPIndividualTypes,
  GetSPBusinessTypes,
}

export interface IGetSPIndividualTypes {
  type: MasterDataActionType.GetSPIndividualTypes;
  payload: IBusinessType[];
}
export interface IGetSPBusinessTypes {
  type: MasterDataActionType.GetSPBusinessTypes;
  payload: IBusinessType[];
}
export type MasterDataActions = IGetSPIndividualTypes | IGetSPBusinessTypes;
