export const VFC_FORM_CONFIG = {
  vfcForm: {
    countryId: {
      elementType: "select",
      label: "Select Country",
      elementConfig: {
        name: "countryId",
      },
      value: "",
    },
    process: {
      elementType: "select",
      label: "Select process",
      elementConfig: {
        name: "process",
      },
      value: "",
    },
    name: {
      elementType: "input",
      label: "Label Name",
      elementConfig: {
        type: "text",
        placeholder: "Enter label name",
        name: "name",
      },
      value: "",
    },
    amount: {
      elementType: "input",
      label: "Amount",
      elementConfig: {
        type: "number",
        placeholder: "",
        name: "amount",
      },
      value: "",
    },
    isPercentage: {
      elementType: "checkbox",
      label: "Percentage",
      elementConfig: {
        type: "checkbox",
        name: "isPercentage",
      },
      checked: false,
    },
    isDeductable: {
      elementType: "checkbox",
      label: "Deductable",
      elementConfig: {
        type: "checkbox",
        name: "isDeductable",
      },
      checked: false,
    },
    providerType: {
      elementType: "select",
      label: "Select Provider Type",
      elementConfig: {
        name: "providerType",
      },
      value: "",
    },
  },
};
