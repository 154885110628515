import React from "react";
import { useHistory } from "react-router-dom";
import { TableCustomCell } from ".";

import Table from "antd/es/table/Table";
import { ColumnsType } from "antd/es/table";
import ActionCell from "../../../templates/CustomCells/ActionCell";
interface IAppConfigTabProps {
  tbCountires: ITableCell[] | undefined;
  tbQualifications: ITableCell[] | undefined;
}

export const AppConfigTab: React.FC<IAppConfigTabProps> = ({
  tbCountires,
  tbQualifications,
}: IAppConfigTabProps) => {
  const history = useHistory();
  const countryColumns: ColumnsType<Record<string, any>> = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "country",
      title: "Country",
      dataIndex: "country",
      render: (_, record) => (
        <TableCustomCell data={record} itemKey={`country`} />
      ),
    },
    {
      key: "countryCode",
      title: "Country Code",
      dataIndex: "countryCode",
    },
    {
      key: "currency",
      title: "Currency",
      dataIndex: "currency",
    },
    {
      key: "searchRadius",
      title: "Search Radius",
      dataIndex: "searchRadius",
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (_, record) => (
        <TableCustomCell data={record} itemKey={`status`} />
      ),
    },
    {
      key: "action",
      title: "Action",
      render: (_, record) => (
        <ActionCell
          data={record}
          actions={[{ key: "id", label: "Edit" }]}
          handlePrimaryFn={handleEditCountry}
        />
      ),
    },
  ];

  const qualificationsColumns: ColumnsType<Record<string, any>> = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
    },
    {
      key: "description",
      title: "Description",
      dataIndex: "description",
    },

    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      render: (_, record) => (
        <TableCustomCell data={record} itemKey={`status`} />
      ),
    },
    {
      key: "action",
      title: "Action",
      render: (_, record) => (
        <ActionCell
          data={record}
          actions={[{ key: "id", label: "Edit" }]}
          handlePrimaryFn={() => handleEditQualification(parseInt(record.id))}
        />
      ),
    },
  ];

  /**
   * Fn to route to country page
   *@method handleViewCountry
   */
  const handleViewCountry = (id: number | string) => {
    history.push({
      pathname: "/countryform",
      state: {
        type: "view",
        countryId: id,
      },
    });
  };

  /**
   * Fn to route to edit country form page
   *@method handleViewCountry
   */
  const handleEditCountry = (id: string) => {
    history.push({
      pathname: "/countryform",
      state: {
        type: "edit",
        countryId: parseInt(id),
      },
    });
  };

  /**
   * Fn to route to add country form page
   *@method handleAddCountry
   */
  const handleAddCountry = () => {
    history.push({
      pathname: "/countryform",
      state: {
        type: "add",
      },
    });
  };
  /**
   * Fn to route to new  qualification page
   *@method handleAddQualification
   */
  const handleAddQualification = () => {
    history.push({
      pathname: "/qualificationForm",
      state: {
        type: "add",
      },
    });
  };

  /**
   * Fn to route to edit qualification page
   *@method handleAddQualification
   *@param id number
   */
  const handleEditQualification = (id: number) => {
    history.push({
      pathname: "/qualificationForm",
      state: {
        type: "edit",
        qualificationId: id,
      },
    });
  };
  return (
    <>
      <div className="flex items-center mb-2" id="table-toolbar">
        <h2 className="flex-none dark:text-slate-900">App Config</h2>
        <div className="flex flex-grow justify-end">
          <button onClick={handleAddCountry} className="btn-sm mr-2">
            Add New
          </button>
        </div>
      </div>
      {/* <DynamicTable
        tbData={tbCountires}
        tbColumns={countryColumns}
        id="country-tb"
        handlePrimaryFn={(id) => handleViewCountry(id)}
        handleSecondaryFn={(id) => handleEditCountry(id)}
        RenderTableCustomCell={(props: ITableCustomCell) => (
          <TableCustomCell {...props} />
        )}
      /> */}
      <Table dataSource={tbCountires} columns={countryColumns} />
      <div className="flex items-center  mb-2" id="table-toolbar">
        <h2 className="flex-none dark:text-slate-900">
          Education Qualifications
        </h2>
        <div className="flex flex-grow justify-end">
          <button onClick={handleAddQualification} className="btn-sm mr-2">
            Add New
          </button>
        </div>
      </div>
      <Table dataSource={tbQualifications} columns={qualificationsColumns} />
    </>
  );
};
