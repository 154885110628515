import { ChangeEvent, FormEvent, useState, useEffect } from "react";
import dayjs from "dayjs";

export type FormState = {
  [key: string]: string | number | boolean;
};

interface IUserFormInputCb {
  values: FormState;

  handleChange: (
    event:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>
      | FormEvent<HTMLSelectElement>
  ) => void;
}
export const useFormInput = (initialValues: FormState): IUserFormInputCb => {
  const [values, setValues] = useState<FormState>(initialValues);

  const handleChange = (
    event:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>
      | FormEvent<HTMLSelectElement>
  ) => {
    event.persist();
    const { name, value } = event.target as
      | HTMLInputElement
      | HTMLTextAreaElement
      | HTMLSelectElement;

    switch (name) {
      case "isExternal":
      case "isDeductable":
      case "active":
      case "availablePressureType":
      case "availableGender":
      case "availableBookingType":
      case "availablePatientCondition":
      case "availableTag":
      case "isActive":
      case "isEnabled":
      case "isPercentage":
        setValues((prevState) => {
          return {
            ...prevState,
            [name]: (event.target as HTMLInputElement).checked,
          };
        });
        break;
      case "id":
      case "countryId":
      case "businessTypeId":
      case "rateType":
      case "process":
      case "action":
      case "section":
      case "searchRadius":
      case "providerType":
      case "paymentMethod":
      case "transportFeeMinRadius":
      case "payoutBufferDays":
      case "categoryId":
        setValues((prevState) => {
          return {
            ...prevState,
            [name]: parseInt(value),
          };
        });
        break;
      case "rate":
      case "amount":
        setValues((prevState) => {
          return {
            ...prevState,
            [name]: parseFloat(value),
          };
        });
        break;
      case "expiryDate":
      case "payoutStartDate":
        const tempDate = dayjs(value).format();

        setValues((prevState) => {
          return {
            ...prevState,
            [name]: tempDate,
          };
        });
        break;
      default:
        setValues((prevState) => {
          return {
            ...prevState,
            [name]: value,
          };
        });
    }
  };

  useEffect(() => {
    setValues(initialValues);
  }, [initialValues]);
  return {
    values,
    handleChange,
  };
};
