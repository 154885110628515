export const generateSelectOptions = (
  data: any,
  key: [string, string]
): TSelectOption[] => {
  const _options: TSelectOption[] = [];

  data?.map((item: any) =>
    _options.push({ label: item[key[0]], value: item[key[1]] })
  );

  return _options;
};
export const generateCountriesOptions = (
  data: ICountries[]
): TSelectOption[] => {
  const _options: TSelectOption[] = [];

  data?.map((item: any) => _options.push({ label: item.name, value: item.id }));

  return _options;
};

export const getCountryName = (
  id: number,
  countries?: ICountries[]
): string => {
  const _tepmCountry = countries?.find((country) => country.id === id);

  return _tepmCountry !== undefined ? _tepmCountry.name : "Country invalid";
};
