import dayjs from "dayjs";

/**
 * Function to build topup sp details summary
 *@method handleTopupDetails
 *@param topup required topup data from endpoint
 *@returns  array of summary sections
 */
export const handleTopupDetails = (topup: ITopupPayload): ISummarySection[] => {
  const _summary: ISummarySection[] = [
    {
      header: {
        title: "Service Provider Details",
        subtitle: "Banks details of service provider",
      },
      body: [
        { id: "serviceProviderId", label: "Service Provider ID", value: "" },
        { id: "serviceProviderName", label: "Name", value: "" },
        {
          id: "accountNo",
          label: "Account No",
          value: "",
        },
        { id: "accountName", label: "Account Name", value: "" },
        { id: "bankName", label: "Bank", value: "" },
        { id: "bankBranch", label: "Branch", value: "" },
      ],
    },
  ];

  Object.entries(topup).forEach((topupProp: Record<string, any>) => {
    _summary.forEach((section) => {
      section.body.forEach((formObj) => {
        if (formObj.id === topupProp[0]) {
          switch (formObj.id) {
            default:
              formObj.value = topupProp[1];
          }
        }
      });
    });
  });
  return _summary;
};

/**
 * Function to build topup transaction summary
 *@method handleTopupSummary
 *@param topup required topup transaction data from endpoint
 *@returns  array of summary sections
 */
export const handleTopupSummary = (
  topupSummary: ITopupTransactionSummary
): ISummarySection[] => {
  const _summary: ISummarySection[] = [
    {
      header: {
        title: "Transaction Details",
        subtitle: "Top-up transaction details",
      },
      body: [
        { id: "id", label: "Transaction ID", value: "" },
        { id: "transactionDate", label: "Transaction Date", value: "" },
        { id: "amount", label: "Amount", value: "" },
        { id: "paymentMethod", label: "Payment Method", value: "" },
      ],
    },
    {
      header: {
        title: "Top Up Details",
        subtitle: "Other top-up information",
      },
      body: [
        { id: "serviceProviderId", label: "Service Provider ID", value: "" },
        { id: "serviceProviderName", label: "Service Provider", value: "" },
        { id: "bankReferenceNo", label: "Bank Reference No", value: "" },
        { id: "accountName", label: "Bank Account Name", value: "" },
        { id: "accountNo", label: "Bank Account No", value: "" },
        { id: "bankName", label: "Bank Name", value: "" },
        { id: "bankBranch", label: "Bank Branch", value: "" },
        { id: "remarks", label: "Remarks", value: "" },
        {
          id: "receiptUrl",
          label: "Attached Receipt",
          value: "",
          list: [],
        },
      ],
    },
  ];

  Object.entries(topupSummary).forEach((result: Record<string, any>) => {
    _summary.forEach((section) => {
      section.body.forEach((formObj) => {
        if (formObj.id === result[0]) {
          switch (formObj.id) {
            case "transactionDate":
              formObj.value = dayjs(result[1]).format("DD/MM/YYYY h:mm A");
              break;
            case "amount":
              formObj.value = result[1].toString();
              break;
            case "receiptUrl":
              const _attachment: SummaryList[] = [];
              const _obj: SummaryList = {
                id: "",
                label: "attachment",
                value: result[1],
                icon: "PaperClipIcon",
              };
              _attachment.push(_obj);
              formObj.list = _attachment;
              break;
            default:
              formObj.value = result[1];
          }
        }
      });
    });
  });
  return _summary;
};
