import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { SummaryForm } from "../../../components";
import { getStatusColor, handleTopupSummary } from "../../../utils";

import axios from "../../../lib/axios/axios.nuvad";

interface ITopupTransactionProps {
  id: string;
  currency: string;
}

const oidcStorage: IOIDCStorage = JSON.parse(
  sessionStorage.getItem(
    `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
  ) || "{}"
);
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + oidcStorage.access_token,
  },
};
/**
 * Top-up transaction summary page to show top-up transaction details
 * @category Pages
 * @component
 * @namespace TopupSummary
 * @returns TopupSummary page
 */

export const TopupTransaction: React.FC = () => {
  const { state: routeData } = useLocation<ITopupTransactionProps>();
  const history = useHistory();
  const [topupTransaction, setTopupTransaction] = useState<ITopupTransaction>();
  const [summarySections, setSummarySections] = useState<ISummarySection[]>([]);

  const handlePreviousPage = () => {
    history.goBack();
  };

  useEffect(() => {
    console.log(routeData);

    /**
     * fn to call GET top-up transaction details
     *@method getTopupSummary
     *@param guid string
     */
    const getTopupTransaction = async (id: string) => {
      await axios
        .get(`finance-api/TopUp/GetTopUpSummary/${id}`, config)
        .then((response) => {
          const _topup: ITopupTransaction = response.data.summary;

          const _tempTopupSummary: ITopupTransactionSummary = {
            id: "",
            status: "",
            remarks: "",
            transactionDate: "",
            receiptUrl: "",
            bankReferenceNo: "",
            amount: "",
            paymentMethod: "",
            bankBranch: "",
            bankName: "",
            accountName: "",
            accountNo: "",
            serviceProviderName: "",
            serviceProviderId: "",
          };
          if (_topup) {
            //get keys from response
            const objKeys = Object.keys(_topup);

            // get the required data from endpoint to _tempWalletSummary
            objKeys.forEach((propName: string) => {
              switch (propName) {
                case "id":
                case "status":
                case "receiptUrl":
                case "bankReferenceNo":
                case "paymentMethod":
                case "bankBranch":
                case "bankName":
                case "accountName":
                case "accountNo":
                case "serviceProviderName":
                case "serviceProviderId":
                case "transactionDate":
                case "remarks":
                  _tempTopupSummary[propName] = _topup[propName];
                  break;
                case "amount":
                  _tempTopupSummary[
                    propName
                  ] = `${routeData.currency} ${_topup[propName]}`;

                  break;
              }
            });
            // call function to build wallet summary
            const _summarySections = handleTopupSummary(_tempTopupSummary);
            setSummarySections(_summarySections);
            setTopupTransaction(_topup);
            console.log(_summarySections);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    getTopupTransaction(routeData.id);
  }, [routeData]);
  return (
    <div className="p-8">
      <div className="flex items-center mb-10" id="title-bar">
        <ArrowLeftIcon
          className=" h-7 w-7 mr-4 cursor-pointer"
          onClick={handlePreviousPage}
        />
        <h1 className="mb-0">Top-up Transaction Summary</h1>
        <span
          className={`px-3 py-1 font-semibold  leading-tight rounded-full capitalize ${
            topupTransaction && getStatusColor(topupTransaction.status)
          } ml-2`}
        >
          <span className="text-white text-sm">
            {topupTransaction && topupTransaction.status}
          </span>
        </span>
      </div>
      <div className="" id="topup-summary-wrapper">
        <SummaryForm summaryData={summarySections} />
      </div>
    </div>
  );
};
