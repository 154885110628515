import React, { useState, useEffect, useContext } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/outline";

import axios from "../../lib/axios/axios.nuvad";
import { SummaryForm } from "../../components";
import AppContext from "../../context/App/AppContext";
import { handleWalletSummary } from "../../utils";

interface IMatchParams {
  guid: string;
}

const oidcStorage: IOIDCStorage = JSON.parse(
  sessionStorage.getItem(
    `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
  ) || "{}"
);
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + oidcStorage.access_token,
  },
};
/**
 * Wallet page to show service provider's wallet details.
 * @category Pages
 * @component
 * @namespace Wallet
 * @returns Wallet page
 */

export const Wallet: React.FC<RouteComponentProps<IMatchParams>> = ({
  match,
}: RouteComponentProps<IMatchParams>) => {
  //Context
  const { countries, getActiveCountires } = useContext(AppContext);
  const { guid } = match.params;
  const history = useHistory();

  const [summarySections, setSummarySections] = useState<ISummarySection[]>([]);

  const handlePreviousPage = () => {
    history.goBack();
  };

  useEffect(() => {
    /**
     * fn to call GET wallet details
     *@method getWalletDetails
     *@param guid string
     */
    const getWalletDetails = async (guid: string) => {
      await axios
        .get(`provider-api/UserProfiles/GetUserBankDetails/${guid}`, config)
        .then((response) => {
          const _wallet: IWallet = response.data;

          const _tempWalletSummary: IWalletSummary = {
            accountName: "",
            accountNumber: "",
            bankName: "",
            branchName: "",
            address: "",
            swiftCode: "",
            countryId: "",
          };
          if (_wallet) {
            //get keys from response
            const objKeys = Object.keys(_wallet);

            // get the required data from endpoint to _tempWalletSummary
            objKeys.forEach((propName: string) => {
              switch (propName) {
                case "accountName":
                case "accountNumber":
                  _tempWalletSummary[propName] = _wallet[propName];
                  break;
                case "bank":
                  _tempWalletSummary.bankName = _wallet[propName].name;
                  const _country = countries?.find(
                    (result) => result.id === _wallet[propName].countryId
                  );
                  _country && (_tempWalletSummary.countryId = _country.name);
                  break;
                case "bankBranch":
                  _tempWalletSummary.branchName = _wallet[propName].name;
                  _tempWalletSummary.address = _wallet[propName].address;
                  _tempWalletSummary.swiftCode = _wallet[propName].swiftCode;
                  break;
              }
            });
            // call function to build wallet summary
            const _summarySections = handleWalletSummary(_tempWalletSummary);
            setSummarySections(_summarySections);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };
    countries?.length === 0 ? getActiveCountires() : getWalletDetails(guid);
  }, [countries, getActiveCountires, guid]);
  return (
    <div className="p-8">
      <div className="flex items-center mb-10" id="title-bar">
        <ArrowLeftIcon
          className=" h-7 w-7 mr-4 cursor-pointer"
          onClick={handlePreviousPage}
        />
        <h1 className="mb-0">Wallet Details Page</h1>
      </div>
      <div className="" id="summary-wrapper">
        <SummaryForm summaryData={summarySections} />
      </div>
    </div>
  );
};
