import React, { memo, useCallback } from "react";
import { ChevronRightIcon, ChevronLeftIcon } from "@heroicons/react/outline";
import dayjs from "dayjs";
import Dropdown from "antd/es/dropdown";
import { MenuProps } from "antd";

interface DynamicTableProps {
  tbData: ITableCell[] | undefined;
  tbColumns: ITableColumns[];
  id: string;
  totalPages?: number;
  page?: number;
  pageLimit?: number;
  handlePrimaryFn?: (id: string) => void;
  handleSecondaryFn?: (id: string) => void;
  handleTertiaryFn?: (id: string) => void;
  RenderTableCustomCell?: (props: ITableCustomCell) => JSX.Element;
  handleNext?: () => void;
  handlePrev?: () => void;
  handlePageItems?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  handlePageNo?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const DynamicTable: React.FC<DynamicTableProps> = ({
  tbData,
  tbColumns,
  id,
  totalPages,
  page,
  pageLimit,
  handlePrimaryFn,
  handleSecondaryFn,
  handleTertiaryFn,
  RenderTableCustomCell,
  handleNext,
  handlePrev,
  handlePageItems,
  handlePageNo,
}: DynamicTableProps) => {
  const _menuItems = [
    { label: "2", key: "limit-2" },
    { label: "10", key: "limit-10" },
    { label: "15", key: "limit-15" },
    { label: "30", key: "limit-30" },
  ];

  const handlePrimaryDropdownItem = (rowId: string) => {
    handlePrimaryFn && handlePrimaryFn(rowId);
  };
  const handleSecondaryDropdownItem = (rowId: string) => {
    handleSecondaryFn && handleSecondaryFn(rowId);
  };

  const handleTertiaryDropdownItem = (rowId: string) => {
    handleTertiaryFn && handleTertiaryFn(rowId);
  };

  const buildTableActionMenu = (
    buttons: TableButton[],
    data: ITableCell
  ): MenuProps => {
    const _items: MenuProps["items"] = [];
    buttons.length !== 0 &&
      buttons.forEach((btn, btnIndex) => {
        _items.push({
          key: btn.key,
          label: (
            <div
              onClick={() => {
                switch (btnIndex) {
                  case 0:
                    handlePrimaryDropdownItem(data[btn.key] as string);
                    break;
                  case 1:
                    handleSecondaryDropdownItem(data[btn.key] as string);
                    break;
                  case 2:
                    handleTertiaryDropdownItem(data[btn.key] as string);
                    break;
                }
              }}
            >
              {btn.label}
            </div>
          ),
        });
      });

    const menu: MenuProps = { items: _items };
    return menu;
  };

  const buildPageNumbers = (totalPages: number): JSX.Element[] => {
    let _options = [];

    for (let i = 1; i <= totalPages; i++) {
      let _option = <option key={`${i}-pg-no`}>{i}</option>;
      _options.push(_option);
    }

    return _options;
  };

  return (
    <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto" id={id}>
      <div className="inline-block min-w-full shadow-md rounded-lg overflow-hidden">
        <table className="min-w-full leading-normal">
          <thead>
            <tr>
              {tbColumns.map((col, colIndex) => (
                <th
                  key={colIndex + 1}
                  className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider"
                >
                  {col.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tbData &&
              tbData.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {tbColumns.map((col, colIndex) => {
                    if (col.key === "user") {
                      return (
                        <td
                          className="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                          key={colIndex}
                        >
                          <div className="flex">
                            <div className="flex-shrink-0 w-12 h-12">
                              <img
                                className="object-cover h-12 w-full rounded-full"
                                src={row.profilePictureUrl as string}
                                alt="user-profile"
                              />
                            </div>
                            <div className="ml-3">
                              <p className="text-gray-900 whitespace-no-wrap text-transform: capitalize">
                                {row.name}
                              </p>
                              <p className="text-gray-600 whitespace-no-wrap">
                                {row.mobile}
                              </p>
                            </div>
                          </div>
                        </td>
                      );
                    } else if (col.key === "created") {
                      return (
                        <td
                          className="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                          key={colIndex}
                        >
                          <p className="text-gray-900 whitespace-no-wrap">
                            {dayjs(row[col.key] as string).format("DD-MM-YYYY")}
                          </p>
                        </td>
                      );
                    } else if (col.key === "action") {
                      return (
                        <td
                          className="px-5 py-5 border-b border-gray-200 bg-white text-sm text-right"
                          key={colIndex}
                        >
                          {col.buttons && (
                            <Dropdown
                              menu={buildTableActionMenu(col.buttons, row)}
                              trigger={["click"]}
                            >
                              <button
                                className="ant-dropdown-link"
                                onClick={(e) => e.preventDefault()}
                              >
                                <svg
                                  className="inline-block h-6 w-6 fill-current text-gray-400 hover:text-gray-700"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M12 6a2 2 0 110-4 2 2 0 010 4zm0 8a2 2 0 110-4 2 2 0 010 4zm-2 6a2 2 0 104 0 2 2 0 00-4 0z" />
                                </svg>
                              </button>
                            </Dropdown>
                          )}
                        </td>
                      );
                    } else if (col.key === "rowNo") {
                      return (
                        <td
                          className="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                          key={colIndex}
                        >
                          <p className="text-gray-900 whitespace-no-wrap">
                            {rowIndex + 1}
                          </p>
                        </td>
                      );
                    } else if (col.customCell) {
                      return (
                        <td
                          className="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                          key={colIndex}
                        >
                          {RenderTableCustomCell !== undefined ? (
                            <RenderTableCustomCell
                              data={row}
                              itemKey={col.key}
                            />
                          ) : null}
                        </td>
                      );
                    } else {
                      return (
                        <td
                          key={col.key}
                          className="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                        >
                          <p className="text-gray-900 whitespace-no-wrap text-transform: capitalize">
                            {row[col.key] !== null ? row[col.key] : "error"}
                          </p>
                        </td>
                      );
                    }
                  })}
                </tr>
              ))}
          </tbody>
        </table>
        {handlePageItems &&
          buildPageNumbers &&
          handlePrev &&
          handleNext &&
          totalPages &&
          pageLimit &&
          page && (
            <div
              className="flex w-full items-center mt-7 bg-white p-2"
              id="table-bottom-bar"
            >
              <div className="w-1/4">
                <div className="text-gray-700 md:flex md:items-center mx-2">
                  <div className="mb-1 md:mb-0 pr-1">
                    <label htmlFor="date-range-picker">Items per page:</label>
                  </div>
                  <div className="md:w-16 ">
                    <select
                      className="border rounded-lg w-full h-10 pr-3 block relative leading-10"
                      onChange={handlePageItems}
                      value={pageLimit}
                    >
                      {_menuItems.map((item) => (
                        <option key={item.key}>{item.label}</option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              <div className="flex-grow flex justify-end">
                <div className="text-gray-700 md:flex md:items-center mx-2">
                  <div className="md:w-16 ">
                    <select
                      className="border rounded-lg w-full h-10 pr-3 block relative leading-10"
                      onChange={handlePageNo}
                      value={page}
                    >
                      {buildPageNumbers(totalPages)}
                    </select>
                  </div>
                  <div className="mb-1 md:mb-0 pl-1">
                    <label htmlFor="date-range-picker">
                      of {totalPages} pages
                    </label>
                  </div>
                </div>
                <div className="text-gray-700 md:flex md:items-center mx-2">
                  <button
                    className="cursor-pointer"
                    onClick={() => handlePrev()}
                  >
                    <ChevronLeftIcon className=" h-6 w-6" />
                  </button>
                  <button
                    className="cursor-pointer"
                    onClick={() => handleNext()}
                  >
                    <ChevronRightIcon className=" h-6 w-6" />
                  </button>
                </div>
              </div>
            </div>
          )}
      </div>
    </div>
  );
};

export default memo(DynamicTable);
