export const enum ProfileVerificationActions {
  pending = 0,
  verified = 1,
  pendingFromServiceProvider = 2,
  rejected = 3,
}
export const enum ProfileVerificationSections {
  all = 0,
  profile = 1,
  education = 2,
}
export const enum ProfileStatus {
  pending = 0,
  verified = 1,
  pendingFromAuthor = 2,
  rejected = 3,
}
