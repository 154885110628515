import React, {
  Fragment,
  useEffect,
  useState,
  useContext,
  FormEvent,
  useRef,
} from "react";
import { useHistory } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import AppContext from "../../context/App/AppContext";

import axios from "../../lib/axios/axios.nuvad";
import message from "antd/es/message";
import { Modal } from "../../components/organisms/Modal/Modal";

import Table from "antd/es/table/Table";
import { ColumnsType } from "antd/es/table";

import ActionCell from "../../templates/CustomCells/ActionCell";
import Measure from "../../components/atoms/Measure/Measure";
import { getCountryName } from "../../utils/formUtils";

const oidcStorage: IOIDCStorage = JSON.parse(
  sessionStorage.getItem(
    `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
  ) || "{}"
);
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + oidcStorage.access_token,
  },
};
/**
 * AllFinanceVariables list down all the finance variables
 * @category Pages
 * @component
 * @namespace AllFinanceVariables
 * @returns AllFinanceVariables page
 */
export const AllFinanceVariables: React.FC = () => {
  const { countries, getActiveCountires } = useContext(AppContext);
  const [selectedCountry, setSelectedCountry] = useState<number | undefined>();
  const [allVFComponents, setAllVFComponents] = useState<ITableCell[]>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const selectedFinanceVariableId = useRef<number | undefined>(undefined);

  const history = useHistory();
  const columns: ColumnsType<Record<string, any>> = [
    {
      key: "1",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "2",
      title: "Country",
      dataIndex: "countryId",
    },
    {
      key: "3",
      title: "Process",
      dataIndex: "process",
    },
    {
      key: "4",
      title: "Type",
      dataIndex: "providerType",
    },

    {
      key: "name",
      title: "Label Name",
      dataIndex: "name",
    },
    {
      key: "amount",
      title: "Value",
      dataIndex: "amount",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <ActionCell
          data={record}
          actions={[{ key: "id", label: "View" }]}
          handlePrimaryFn={handleEditBtn}
        />
      ),
    },
  ];
  // const allVfcColumns: ITableColumns[] = [
  //   {
  //     key: "id",
  //     title: "#",
  //   },

  //   {
  //     key: "countryId",
  //     title: "Country",
  //   },
  //   {
  //     key: "process",
  //     title: "Process",
  //   },
  //   {
  //     key: "providerType",
  //     title: "Provider Type",
  //   },
  //   {
  //     key: "name",
  //     title: "Label Name",
  //   },
  //   {
  //     key: "amount",
  //     title: "Value",
  //   },
  //   {
  //     key: "isPercentage",
  //     title: "Percentage",
  //   },

  //   {
  //     key: "action",
  //     title: "",
  //     buttons: [
  //       { key: "id", label: "View" },
  //       { key: "id", label: "Edit" },
  //       { key: "id", label: "Delete" },
  //     ],
  //   },
  // ];

  /**
   * Fn to handle drop down primary button click
   *@method handleTablePrimaryBtnFn
   *@param id id of the row clicked
   */
  const handleTablePrimaryBtnFn = (id: number | string) => {
    history.push({
      pathname: "/vfcform",
      state: {
        type: "view",
        vfcId: id,
      },
    });
  };
  /**
   * Fn to handle drop down secondary button click
   *@method handleTableSecondaryBtnFn
   *@param id id of the row clicked
   */
  const handleEditBtn = (id: string) => {
    history.push({
      pathname: "/vfcform",
      state: {
        type: "edit",
        vfcId: parseInt(id),
      },
    });
  };
  /**
   * Fn to handle drop down tertiary button click
   *@method handleTableTertiaryBtnFn
   *@param id id of the row clicked
   */
  const handleTableTertiaryBtnFn = (id: string) => {
    selectedFinanceVariableId.current = parseInt(id);
    setShowModal(true);
  };
  /**
   * Fn to call DELETE method to delete vfc
   *@method deleteFinanceVariable
   */
  const deleteFinanceVariable = async (id: number | undefined) => {
    await axios
      .delete(`admin-api/FinanceVariables/${id}`, config)
      .then((response) => {
        console.log(response);
        if (response.status === 200) {
          message.success(`Delete finance variable successful`);
          selectedCountry && getAllFinanceVariables(selectedCountry);
          setShowModal(false);
        }
      })
      .catch((error) => {
        message.error(`Delete finance variable unsuccessful`);
        setShowModal(false);
        console.log(error);
      });
  };

  /**
   * Fn to call GET method to get all finance variables
   *@method getAllFinanceVariables
   *@param id
   */
  const getAllFinanceVariables = async (id: number) => {
    await axios
      .get(`admin-api/FinanceVariables/GetByCountry/${id}`, config)
      .then((response) => {
        const _temp: ITableCell[] = [];
        const _financeVariables: IVFComponent[] =
          response.data.financeVariables;
        if (_financeVariables.length !== 0) {
        }
        //get keys from response
        const objKeys = Object.keys(_financeVariables[0]);

        //Build final array to bind to table
        _financeVariables.forEach((vfc: IVFComponent, index) => {
          const _Obj: ITableCell = {};
          objKeys.forEach((colName: string) => {
            switch (colName) {
              case "id":
              case "amount":
              case "isPercentage":
                _Obj[colName] = vfc[colName].toString();
                break;
              case "name":
                _Obj[colName] = vfc[colName];
                break;
              case "process":
                _Obj[colName] = vfc[colName].name;
                break;
              case "countryId":
                _Obj[colName] = getCountryName(vfc[colName], countries);
                break;
              case "providerType":
                if (vfc[colName] !== null) {
                  _Obj[colName] = vfc[colName]!.name;
                } else {
                  _Obj[colName] = "";
                }

                break;
              default:
            }
            _Obj.key = (index + 1).toString();
          });
          _temp.push(_Obj);
        });
        setAllVFComponents(_temp);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // const getCountryName = (id: number): string => {
  //   const _tepmCountry = countries?.find((country) => country.id === id);

  //   return _tepmCountry !== undefined ? _tepmCountry.name : "Country invalid";
  // };
  const addNewHandler = () => {
    history.push({
      pathname: "/vfcform",
      state: {
        type: "create",
        vfcId: selectedCountry,
      },
    });
  };

  const handleChange = (event: FormEvent<HTMLSelectElement>) => {
    event.persist();
    const { value } = event.target as HTMLSelectElement;
    if (value !== "") {
      setSelectedCountry(parseInt(value));
      getAllFinanceVariables(parseInt(value));
    }
  };

  // fn to handle routing back to previous page
  const handlePreviousPage = () => {
    history.goBack();
  };

  useEffect(() => {
    countries?.length === 0 && getActiveCountires();
  }, []);
  const before = process.env.NODE_ENV === "development" ? performance.now() : 0;
  return (
    <Fragment>
      <div className="p-8">
        <div className="flex items-center mb-10" id="title-bar">
          <ArrowLeftIcon
            className=" h-7 w-7 mr-4 cursor-pointer text-gray-900"
            onClick={handlePreviousPage}
          />
          <h1>Finance Variables</h1>
        </div>
        <div className="cardbox">
          <div className="flex items-center" id="table-toolbar">
            <h2 className="flex-none">All Finance Variables</h2>
            <div className="flex flex-grow justify-end">
              <button onClick={addNewHandler} className="btn-sm mr-2">
                Add New
              </button>
              <div className="relative inline-block max-w-xs text-gray-700">
                <select
                  className="w-full h-full py-2 pl-3 pr-6 text-base placeholder-gray-600 border rounded appearance-none focus:shadow-outline"
                  value={selectedCountry !== 0 ? selectedCountry : ""}
                  onChange={handleChange}
                >
                  <option value="">Filter by country</option>
                  {countries &&
                    countries.map((elm) =>
                      elm.id === selectedCountry ? (
                        <option key={elm.id} value={elm.id} selected>
                          {elm.name}
                        </option>
                      ) : (
                        <option key={elm.id} value={elm.id}>
                          {elm.name}
                        </option>
                      )
                    )}
                </select>
                <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                  <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                    <path
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                      fillRule="evenodd"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <Table dataSource={allVFComponents} columns={columns} />
        </div>
      </div>
      {showModal ? (
        <Modal
          modalActive={showModal}
          setModalActive={setShowModal}
          title={`Delete Finance Variable`}
          message={`Are you sure you want to delete this finance variable?`}
          primaryBtn={() =>
            deleteFinanceVariable(selectedFinanceVariableId.current)
          }
          testId="finance-variable-modal"
          primaryBtnText="Ok"
        />
      ) : null}

      {process.env.NODE_ENV === "development" && <Measure before={before} />}
    </Fragment>
  );
};
