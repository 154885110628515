import React, { ChangeEvent, FormEvent, Fragment, memo } from "react";
import dayjs from "dayjs";

type FormInputOptions = {
  id: number;
  value: string;
};

interface IFormInputProps {
  id: string;
  label?: string | undefined;
  elementType: string;
  elementConfig: ElementConfig;
  value: string | number | boolean | undefined;
  inputRef?: React.MutableRefObject<HTMLInputElement | null>;
  isDisable?: boolean;
  handleChange?: (
    event:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>
      | FormEvent<HTMLSelectElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => void;
  options?: FormInputOptions[];
  hideLabel?: boolean;
  handleImageChange?: (event: FormEvent<HTMLInputElement>) => void;
  handleBlur?: (
    event:
      | ChangeEvent<HTMLInputElement>
      | ChangeEvent<HTMLTextAreaElement>
      | FormEvent<HTMLSelectElement>
  ) => void;
}

const FormInput = ({
  id,
  label,
  elementType,
  elementConfig,
  value,
  handleChange,
  options,
  hideLabel,
  inputRef,
  isDisable,
  handleBlur,
  handleImageChange,
}: IFormInputProps): JSX.Element => {
  let inputElement = null;

  switch (elementType) {
    case "input":
      inputElement = (
        <div className="text-gray-700">
          <input
            id={id}
            className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"
            {...elementConfig}
            value={
              value && value !== undefined && typeof value !== "boolean"
                ? value
                : ""
            }
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={isDisable}
          />
        </div>
      );
      break;
    case "select":
      inputElement = (
        <div className="relative  text-gray-700 ">
          <select
            className="w-full h-10 pl-3 pr-6 text-base placeholder-gray-600 border rounded-lg appearance-none focus:shadow-outline"
            {...elementConfig}
            onChange={handleChange}
            onBlur={handleBlur}
            value={
              value !== undefined &&
              typeof value !== "boolean" &&
              !isNaN(value as number)
                ? value
                : ""
            }
            disabled={isDisable ? isDisable : false}
          >
            <option value="">{label && label}</option>
            {options &&
              options.map((elm) => (
                <option key={elm.id} value={elm.id}>
                  {elm.value}
                </option>
              ))}
          </select>
          <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
            <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
              <path
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
                fillRule="evenodd"
              ></path>
            </svg>
          </div>
        </div>
      );
      break;
    case "textarea":
      inputElement = (
        <div className="text-gray-700">
          <textarea
            id={id}
            rows={4}
            className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline textarea"
            {...elementConfig}
            value={
              value !== undefined && typeof value !== "boolean" ? value : ""
            }
            onChange={handleChange}
          />
        </div>
      );
      break;
    case "checkbox":
      inputElement = (
        <input
          className=" inline-block w-4 h-4"
          {...elementConfig}
          // checked={
          //   value !== undefined && typeof value == "boolean" ? value : false
          // }
          checked={value as boolean}
          type="checkbox"
          onChange={handleChange}
          onBlur={handleBlur}
          id={id}
          disabled={isDisable}
        />
      );
      break;
    case "date":
      const _value =
        value?.toString() !== "Invalid Date" && value?.toString() !== ""
          ? dayjs(value?.toString()).format("YYYY-MM-DD")
          : dayjs().format("YYYY-MM-DD");

      inputElement = (
        <div className="text-gray-700">
          <input
            id={id}
            className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"
            {...elementConfig}
            onChange={handleChange}
            onBlur={handleBlur}
            value={_value}
          />
        </div>
      );
      break;
    case "file":
      inputElement = (
        <div className="text-gray-700">
          <input
            id={id}
            className="w-full h-10 px-3 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline"
            {...elementConfig}
            onChange={handleImageChange}
            ref={inputRef}
          />
        </div>
      );
      break;
    default:
      inputElement = (
        <input
          id={id}
          className="fromInput"
          {...elementConfig}
          value={
            value && value !== undefined && typeof value !== "boolean"
              ? value
              : ""
          }
          onChange={handleChange}
          onBlur={handleBlur}
          disabled={isDisable}
        />
      );
  }

  return (
    <Fragment>
      {elementType === "checkbox" ? (
        <label className="text-gray-700" htmlFor={id}>
          {label && <span className="block mb-1 font-semibold">{label}</span>}
          {inputElement}
        </label>
      ) : (
        <div className="text-gray-700">
          {label && (
            <label className="block mb-1 font-semibold" htmlFor={id}>
              {label}
            </label>
          )}

          {inputElement}
        </div>
      )}
    </Fragment>
  );
};

export default memo(FormInput);
