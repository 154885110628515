export enum ActionType {
  setError,
}

export interface ISetError {
  type: ActionType.setError;
  payload: IError;
}

export type ErrorActions = ISetError;
