import React, { Fragment, useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
export const Modal = ({
  modalActive,
  title,
  testId,
  primaryBtn,
  secondaryBtn,
  children,
  isCloseable,
  icon,
  message,
  setModalActive,
  wrapperStyle,
  primaryBtnText,
}: IModalProps): JSX.Element => {
  const _root = document.querySelector("#modal") as Element;
  const inputEl = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (inputEl.current) inputEl.current.focus();
  }, []);
  return (
    <Fragment>
      {ReactDOM.createPortal(
        <Fragment>
          <div
            id={`${testId}-modal`}
            className="fixed left-0 top-0 w-full h-full z-20"
          >
            <div
              className="relative px-4 min-h-screen md:flex md:items-center md:justify-center"
              id="modal-inner"
            >
              <div
                className="bg-white rounded-lg md:max-w-md md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 mb-4 mx-4 md:relative"
                id="modal-content"
              >
                <div className="md:flex items-center">
                  <div className="rounded-full border border-gray-300 flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto">
                    <ExclamationCircleIcon />
                  </div>
                  <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                    <p className="font-bold dark:text-slate-900">{title}</p>
                    <p className="dark:text-slate-900">{message}</p>
                    <p className="text-sm text-gray-700 mt-1">
                      {children && <div className=" pr-8 mb-6">{children}</div>}
                    </p>
                  </div>
                </div>

                <div className="text-center md:text-right mt-4 md:flex md:justify-end">
                  <button
                    className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-red-200 text-red-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2"
                    onClick={primaryBtn}
                  >
                    {primaryBtnText ? primaryBtnText : "Okay"}
                  </button>
                  <button
                    className="block w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-gray-200 rounded-lg font-semibold text-sm mt-4
              md:mt-0 md:order-1"
                    onClick={() => setModalActive(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <div
              className="bg-black opacity-25 w-full h-full absolute z-10 inset-0"
              id=" modal-backdrop"
            />
          </div>
        </Fragment>,
        _root
      )}
    </Fragment>
  );
};

Modal.displayName = "Modal";
