import { memo } from "react";

interface ICostSummaryProps extends ICostSummary {
  currency: string;
}
const CostSummary = ({
  name,
  isDeductable,
  isPercentage,
  amount,
  currency,
  evaluatedAmount,
}: ICostSummaryProps): JSX.Element => {
  return (
    <div className="flex justify-between align-top mb-2">
      <div>
        <span className=" mr-1">{name}</span>
        <span>{isPercentage && `${amount}%`}</span>
      </div>

      <span>
        {`${isDeductable ? "-" : ""}${
          currency ? currency : "$"
        } ${evaluatedAmount}`}
      </span>
    </div>
  );
};

export default memo(CostSummary);
