import React, { useContext, useEffect, useState } from "react";
import { MASTER_FORM_CONFIG } from "../../../constants/appConfigForms";
import { useHistory, useLocation } from "react-router-dom";

import { ArrowLeftIcon } from "@heroicons/react/outline";

import { Button, Input, Switch } from "antd";

import axios from "../../../lib/axios/axios.nuvad";
import Card from "antd/es/card/Card";
import Form from "antd/es/form";
import Measure from "../../../components/atoms/Measure/Measure";
import { getErrorObj } from "../../../utils/errorHandlingUtils";
import ErrorContext from "../../../features/errorhandling/context/errorContext";
import AppContext from "../../../context/App/AppContext";

interface IQualificationFormRouteProps {
  type: string;
  qualificationId?: number;
}
interface IQualificationFormConfig {
  name: IFormConfig;
  description: IFormConfig;
  active: IFormConfig;
}

/**
 * Qualification form page to add and edit education qualifications
 * @category Forms
 * @component
 * @namespace QualificationForm
 * @returns QualificationForm component form page
 */

export const QualificationForm: React.FC = () => {
  //context
  const { triggerNotification } = useContext(AppContext);
  const { setError } = useContext(ErrorContext);

  const history = useHistory();
  const { state: routeData } = useLocation<IQualificationFormRouteProps>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // const [form, setForm] = useState<IFormInput[]>([]);

  // Custom hooks
  const [form] = Form.useForm();
  const before = process.env.NODE_ENV === "development" ? performance.now() : 0;

  /**
   * Function to handle form submit
   *@method handleSubmit
   *@param event
   */
  const handleSubmit = (formData: IQualificationForm) => {
    const _qualification: IQualification = {
      ...formData,
      id:
        routeData.qualificationId !== undefined ? routeData.qualificationId : 0,
    };

    setIsLoading(true);
    if (routeData.type === "edit") {
      editQualification(_qualification);
    } else {
      addQualification(_qualification);
    }
  };
  /**
   * Function to add new business type
   *@method addQualification
   *@param payload
   */
  const addQualification = (payload: IQualification) => {
    axios
      .post("admin-api/Qualifications", payload)
      .then((response) => {
        setIsLoading(false);
        triggerNotification({
          type: "success",
          title: "Add Qualification",
          message: (
            <p>
              Qualification {form.getFieldValue("name")} added successfully.
            </p>
          ),
        });
      })
      .catch((error) => {
        setIsLoading(false);
        const _errorObj = getErrorObj(error.response);
        setError(_errorObj);
      });
  };
  /**
   * Function to edit qualification
   *@method editQualification
   *@param payload
   */
  const editQualification = (payload: IQualification) => {
    axios
      .put("admin-api/Qualifications", payload)
      .then((response) => {
        setIsLoading(false);
        triggerNotification({
          type: "success",
          title: "Edit Qualification",
          message: (
            <p>
              Qualification {form.getFieldValue("name")} edited successfully.
            </p>
          ),
        });
      })
      .catch((error) => {
        setIsLoading(false);
        const _errorObj = getErrorObj(error.response);
        setError(_errorObj);
      });
  };
  /**
   * Fn to call GET method to get qualification details by ID
   *@method getQualificationById
   *@param id id of qualification
   */
  const getQualificationById = (id: number) => {
    axios
      .get(`admin-api/Qualifications/${id}`)
      .then((response) => {
        console.log(response.data);
        const _qualification: IQualification = {
          ...response.data,
          id:
            routeData.qualificationId !== undefined
              ? routeData.qualificationId
              : 0,
        };
        const _form: IQualificationForm = {
          name: "",
          description: "",
          active: false,
        };

        _form.name = _qualification.name;
        _form.description = _qualification.description;
        _form.active = _qualification.active;

        form.setFieldsValue(_form);
      })
      .catch((error) => {
        setIsLoading(false);
        const _errorObj = getErrorObj(error.response);
        setError(_errorObj);
      });
  };
  /**
   * Function to go back to previous page
   *@method handlePreviousPage
   */
  const handlePreviousPage = () => {
    history.goBack();
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    switch (routeData.type) {
      case "add":
        // fetchFormData();
        break;
      case "edit":
        routeData.qualificationId !== undefined &&
          getQualificationById(routeData.qualificationId);
        break;
      default:
    }
  }, []);
  return (
    <>
      <div className="flex items-center mb-10" id="title-bar">
        <ArrowLeftIcon
          className="h-7 w-7 mr-4 cursor-pointer text-gray-900"
          onClick={handlePreviousPage}
        />
        <h1 className="mb-0">
          <span className="capitalize">{routeData.type}</span> Education
          Qualification
        </h1>
      </div>
      <Card title="Qualification Form" bordered={false}>
        <Form
          form={form}
          name="qualificationForm"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 780 }}
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <div className="columns-1 ">
            <Form.Item
              key={"name"}
              id={"name"}
              label={"Qualification Title"}
              name={"name"}
              style={{ fontWeight: "500" }}
              rules={[{ required: true, message: "Enter qualification name" }]}
            >
              <Input
                autoComplete="nope"
                placeholder="Diploma"
                style={{ width: "50%" }}
              />
            </Form.Item>
          </div>
          <div className="columns-1">
            <Form.Item
              key={"description"}
              id={"description"}
              label={"Description"}
              name={"description"}
              style={{ fontWeight: "500", width: "50%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter a description",
                },
              ]}
            >
              <Input autoComplete="nope" />
            </Form.Item>
          </div>

          <div className="columns-1">
            <Form.Item
              key={"active"}
              id={"active"}
              label={"Status"}
              name={"active"}
              style={{ fontWeight: "500" }}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </div>

          <Form.Item wrapperCol={{ span: 16 }}>
            <Button
              type="primary"
              ghost
              onClick={handlePreviousPage}
              size="large"
            >
              Back
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="btn-antd--primary"
              loading={isLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>

      {process.env.NODE_ENV === "development" && <Measure before={before} />}
    </>
  );
};
