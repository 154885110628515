export const getErrorObj = (error: any): IError => {
  console.log(error, "error");
  const _error: IError = {
    status: 0,
    title: "",
    errorList: [],
  };
  if (error?.status === 409) {
    _error.status = error.status;
    _error.title = error?.data?.Message;
    _error.errorList = Object.values(error?.data?.Errors[0]);
  }
  if (error?.status === 400) {
    _error.status = error.status;
    _error.title = error?.data?.Message;
    const _list = Object.values(error?.data?.Errors) as ErrorListObj[][];
    _error.errorList = _list.flat(1);
  }
  console.log(_error, "_error");
  return _error;
};
