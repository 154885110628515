import React from "react";
import { useHistory } from "react-router-dom";
import DynamicTable from "../../../components/organisms/DynamicTable/DynamicTable";

import { TableCustomCell } from "./TableCustomCell";

interface IVerifiedRegistrationsTabProps {
  tbData: ITableCell[] | undefined;
}
export const VerifiedRegistrationsTab: React.FC<
  IVerifiedRegistrationsTabProps
> = ({ tbData }: IVerifiedRegistrationsTabProps) => {
  const history = useHistory();
  const tableColumns: ITableColumns[] = [
    {
      key: "id",
      title: "ID",
    },
    {
      key: "user",
      title: "Applicant Name",
    },
    {
      key: "country",
      title: "Country",
    },
    {
      key: "businessType",
      title: "Service Category",
    },
    {
      key: "created",
      title: "Created Date",
    },
    {
      key: "status",
      title: "Status",
      customCell: true,
    },
    {
      key: "action",
      title: "",
      buttons: [{ key: "id", label: "View" }],
    },
  ];

  const handleTablePrimaryBtnFn = (id: number | string) => {
    history.push({
      pathname: `/practitionerprofile/${id}`,
    });
  };
  return (
    <div className="p-8 ">
      <div className="mb-3" id="title-bar">
        <h2 className="dark:text-slate-900">Verified Registrations</h2>
      </div>
      <DynamicTable
        tbData={tbData}
        tbColumns={tableColumns}
        id="verified-registrations-tb"
        handlePrimaryFn={(id) => handleTablePrimaryBtnFn(id)}
        RenderTableCustomCell={(props: ITableCustomCell) => (
          <TableCustomCell {...props} />
        )}
      />
    </div>
  );
};
