import React, { useState, useEffect } from "react";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { useHistory, RouteComponentProps } from "react-router-dom";
import { VERIFY_PROFILE_FORM } from "../../../constants/verifyProfileForm";
import { FormState, useFormInput } from "../../../hooks";

import axios from "../../../lib/axios/axios.nuvad";
import message from "antd/es/message";
import Timeline from "antd/es/timeline";
import Empty from "antd/es/empty";
import { Button, Form, Input, Select } from "antd";
import Measure from "../../../components/atoms/Measure/Measure";

interface IMatchParams {
  id: string;
}
type VerifyProfileFormConfig = {
  action: IFormConfig2;
  section: IFormConfig2;
  comment: IFormConfig2;
};
const oidcStorage: IOIDCStorage = JSON.parse(
  sessionStorage.getItem(
    `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
  ) || "{}"
);
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + oidcStorage.access_token,
  },
};
const initialValues = {
  action: 0,
  section: 0,
  comment: "",
  id: 0,
};
/**
 * ProfileVerificationForm used to verify a user profile
 * @category Forms
 * @component
 * @namespace ProfileVerificationForm
 * @returns Profile verification form
 */

export const ProfileVerificationForm: React.FC<
  RouteComponentProps<IMatchParams>
> = ({ match }: RouteComponentProps<IMatchParams>) => {
  const { id } = match.params;
  const history = useHistory();
  const [verificationHistory, setVerificationHistory] =
    useState<IVerificationHistory[]>();

  const [form, setForm] = useState<IFormInput2[]>([]);
  const [initialFormValues] = useState<IVerificationHistory>(initialValues);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Custom hooks
  const { values, handleChange } = useFormInput(
    initialFormValues as unknown as FormState
  );

  const fetchFormData = async () => {
    const _form: VerifyProfileFormConfig = await VERIFY_PROFILE_FORM.verifyForm; //await statement
    const _formElementsArray: IFormInput2[] = [];
    Object.keys(_form).forEach((elm) => {
      if (elm === "action" || elm === "section") {
        _formElementsArray.push({
          id: elm,
          label: _form[elm].label,
          elementType: _form[elm].elementType,
          elementConfig: _form[elm].elementConfig,
          value: _form[elm].value !== undefined ? _form[elm].value : "",
          options: _form[elm].options,
        });
      } else if (elm === "comment") {
        _formElementsArray.push({
          id: elm,
          label: _form[elm].label,
          elementType: _form[elm].elementType,
          elementConfig: _form[elm].elementConfig,
          value: _form[elm].value !== undefined ? _form[elm].value : "",
        });
      }
    });

    setForm(_formElementsArray);
  };

  // Fn to call verify profile for verification endpoint
  const verifyProfile = async (payload: IVerificationPayload) => {
    axios
      .post("provider-api/UserProfiles/VerifyUserProfile", payload, config)
      .then((response) => {
        console.log(response, "response");
        setIsLoading(false);
        message.success(`Profile ${getAction(payload.action)} successful `);
      })
      .catch((error) => {
        console.log(error, "error");
        setIsLoading(false);
        message.error(`Profile ${getAction(payload.action)} unsuccessful `);
      });
  };
  const getProfileVerificationHistory = async (id: number) => {
    await axios
      .get(`provider-api/UserProfiles/${id}`, config)
      .then((response) => {
        const { verificationHistories } = response.data;
        if (verificationHistories !== null)
          setVerificationHistory(verificationHistories);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getAction = (id: number): string => {
    let _action = "";
    switch (id) {
      case 0:
        _action = "Pending";
        break;
      case 1:
        _action = "Verified";
        break;
      case 2:
        _action = "Pending from SP";
        break;
      case 3:
        _action = "Rejected";
        break;
      default:
        _action = "error";
    }
    return _action;
  };
  const getSection = (id: number): string => {
    let _section = "";
    switch (id) {
      case 0:
        _section = "All";
        break;
      case 1:
        _section = "Profile";
        break;
      case 2:
        _section = "Education";
        break;
      default:
        _section = "error";
    }
    return _section;
  };
  const handlePreviousPage = () => {
    history.goBack();
  };

  useEffect(() => {
    getProfileVerificationHistory(parseInt(id));
    fetchFormData();
  }, []);

  //Handle form submit
  const handleFormSubmit = (values: IVerificationForm) => {
    setIsLoading(true);
    const _Obj: IVerificationPayload = {
      userProfileId: parseInt(id),
      action: parseInt(values.action),
      section: parseInt(values.section),
      comment: values.comment,
    };
    verifyProfile(_Obj);
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  const before = process.env.NODE_ENV === "development" ? performance.now() : 0;
  return (
    <div className="p-8">
      <div className="flex items-center mb-10" id="title-bar">
        <ArrowLeftIcon
          className=" h-7 w-7 mr-4 cursor-pointer text-gray-900"
          onClick={handlePreviousPage}
        />
        <h1 className="mb-0">Review User Profile</h1>
      </div>
      <div className="grid grid-cols-2 gap-4 form bg-white p-4">
        <Form
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          style={{ maxWidth: 480 }}
          initialValues={{ remember: true }}
          onFinish={handleFormSubmit}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          {form.map((elem, index) => {
            if (elem.id === "section" || elem.id === "action") {
              return (
                <Form.Item
                  key={elem.id}
                  id={elem.id}
                  label={elem.label}
                  name={elem.elementConfig.name}
                  rules={[
                    { required: true, message: "Please select an option" },
                  ]}
                >
                  <Select
                    options={
                      elem.options ? (elem.options as TSelectOption[]) : []
                    }
                  />
                </Form.Item>
              );
            } else {
              return (
                <Form.Item
                  key={elem.id}
                  id={elem.id}
                  label={elem.label}
                  name={elem.elementConfig.name}
                >
                  <Input />
                </Form.Item>
              );
            }
          })}

          <Form.Item wrapperCol={{ span: 16 }}>
            <Button
              type="primary"
              ghost
              onClick={handlePreviousPage}
              size="large"
            >
              Back
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="btn-antd--primary"
              loading={isLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
        <div id="verification-history">
          <h3 className="mb-3">Verification History</h3>
          {verificationHistory && verificationHistory.length !== 0 ? (
            <Timeline>
              {verificationHistory?.map((item, itemIndex) => (
                <Timeline.Item key={itemIndex} color="red">
                  <p>
                    <span className="font-bold mr-1">{item.id}</span>
                    {item.comment}
                  </p>
                  <p className="text-sm">{`${getSection(
                    item.section
                  )} | ${getAction(item.action)}`}</p>
                </Timeline.Item>
              ))}
            </Timeline>
          ) : (
            <Empty description="No verification history, new profile" />
          )}
        </div>
      </div>

      {process.env.NODE_ENV === "development" && <Measure before={before} />}
    </div>
  );
};
