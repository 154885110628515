import React, { useContext } from "react";
import { Route } from "react-router-dom";
import { AuthContext } from "../providers/authProvider";
import { AuthLayout } from "../layout";

interface PrivateRouteProps {
  component: React.ElementType;
  path: string;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({
  component,
  ...rest
}: PrivateRouteProps): JSX.Element => {
  const { isAuthenticated, signinRedirect } = useContext(AuthContext);
  // eslint-disable-next-line react/display-name
  const renderFn = (Component: React.ElementType) => (props: any) => {
    if (!!Component && isAuthenticated()) {
      return (
        <AuthLayout>
          <Component {...props} />
        </AuthLayout>
      );
    } else {
      signinRedirect();
      return <span>loading</span>;
    }
  };

  return <Route {...rest} render={renderFn(component)} />;
};
