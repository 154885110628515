import React from "react";
import { Route, Switch } from "react-router-dom";
import { PrivateRoute } from "./privateRoute";
import AppProvider from "../context/App/AppProvider";
import MasterDataProvider from "../context/Masterdata/MasterDataProvider";
import {
  Dashboard,
  Practitioners,
  FinanceVariablesFrom,
  AllFinanceVariables,
  LoginPage,
  AllSessions,
  ProfileVerificationForm,
  MasterData,
  CountryForm,
  BankForm,
  Businesses,
  // BusinessProfile,
  Wallet,
  TopupForm,
  TopupTransaction,
  PendingTopups,
  QualificationForm,
  CreatePayouts,
} from "../pages";

import CategoryForm from "../features/businessCategories/CategoryForm";
import PractitionerServiceForm from "../features/businessServices/PractitionerServiceForm";
import BusinessServiceForm from "../features/businessServices/BusinessServiceForm";

const SessionSummary = React.lazy(
  () => import("../features/sessions/SessionSummary")
);
const PaymentSummary = React.lazy(
  () => import("../features/finance/payments/PaymentSummary")
);
const PromotionForm = React.lazy(
  () => import("../features/promotions/PromotionForm")
);
const Promotions = React.lazy(
  () => import("../features/promotions/Promotions")
);
const PendingPayouts = React.lazy(
  () => import("../features/finance/payouts/PendingPayouts")
);
const PayoutSummary = React.lazy(
  () => import("../features/finance/payouts/PayoutSummary")
);
const BusinessProfile = React.lazy(
  () => import("../features/provider/business/Profile")
);
const EarningSummary = React.lazy(
  () => import("../features/earnings/EarningSummary")
);
const PractitionerProfile = React.lazy(
  () => import("../features/provider/practitioner/Profile")
);
export const Routes: React.FC = (): JSX.Element => (
  <AppProvider>
    <MasterDataProvider>
      <Switch>
        <PrivateRoute
          path="/earning/summary/:guid"
          component={EarningSummary}
        />
        <PrivateRoute path="/createPayouts" component={CreatePayouts} />
        <PrivateRoute path="/payoutSummary/:guid" component={PayoutSummary} />
        <PrivateRoute path="/pendingpayouts" component={PendingPayouts} />
        <PrivateRoute path="/qualificationForm" component={QualificationForm} />
        <PrivateRoute path="/allpendingtopups" component={PendingTopups} />
        <PrivateRoute path="/topupsummary" component={TopupTransaction} />
        <PrivateRoute path="/topupForm" component={TopupForm} />
        <PrivateRoute path="/promotionForm" component={PromotionForm} />

        <PrivateRoute path="/promotions" component={Promotions} />
        <PrivateRoute path="/wallet/:guid" component={Wallet} />
        <PrivateRoute
          path="/businessServiceForm"
          component={BusinessServiceForm}
        />
        <PrivateRoute
          path="/practitionerServiceform"
          component={PractitionerServiceForm}
        />
        <PrivateRoute path="/businesstypeform" component={CategoryForm} />
        <PrivateRoute path="/bankform" component={BankForm} />
        <PrivateRoute path="/paymentSummary/:id" component={PaymentSummary} />
        <PrivateRoute path="/countryform" component={CountryForm} />
        <PrivateRoute path="/masterdata" component={MasterData} />
        <PrivateRoute path="/businessprofile/:id" component={BusinessProfile} />
        <PrivateRoute
          path="/practitionerprofile/:id"
          component={PractitionerProfile}
        />
        <PrivateRoute
          path="/verifyprofile/:id"
          component={ProfileVerificationForm}
        />
        <PrivateRoute
          path="/sessionsummary/:id/:type"
          component={SessionSummary}
        />
        <PrivateRoute path="/allsessions" component={AllSessions} />
        <PrivateRoute path="/vfcform" component={FinanceVariablesFrom} />
        <PrivateRoute path="/allvfcomponents" component={AllFinanceVariables} />
        <PrivateRoute path="/spbusiness" component={Businesses} />
        <PrivateRoute path="/practitioners" component={Practitioners} />
        <PrivateRoute path="/dashboard" component={Dashboard} />
        <Route path="/" component={LoginPage} />
      </Switch>
    </MasterDataProvider>
  </AppProvider>
);
