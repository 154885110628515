import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { ArrowLeftIcon } from "@heroicons/react/outline";

import axios from "../../../lib/axios/axios.nuvad";
import Form from "antd/es/form";
import Input from "antd/es/input";

import Button from "antd/es/button";
import Measure from "../../../components/atoms/Measure/Measure";
import Card from "antd/es/card";
import { InputNumber, Switch } from "antd";
import { getErrorObj } from "../../../utils/errorHandlingUtils";
import ErrorContext from "../../../features/errorhandling/context/errorContext";
import AppContext from "../../../context/App/AppContext";

interface ICountryFormRouteProps {
  type: string;
  countryId?: number;
}

/**
 * Country form page to and and edit countries, states are managed independently
 * @category Forms
 * @component
 * @namespace CountryForm
 * @returns CountryForm component form page
 */

export const CountryForm: React.FC = () => {
  //context
  const { triggerNotification } = useContext(AppContext);
  const { setError } = useContext(ErrorContext);
  const history = useHistory();
  const { state: routeData } = useLocation<ICountryFormRouteProps>();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  // Custom hooks
  const [form] = Form.useForm();
  const before = process.env.NODE_ENV === "development" ? performance.now() : 0;

  /**
   * Function to get country by id
   *@method getCountryById
   *@param id type number
   */
  const getCountryById = async (id: number) => {
    axios
      .get(`/admin-api/Countries/${id}`)
      .then((response) => {
        const _country: ICountryPayload = {
          ...response.data,
          id: routeData.countryId !== undefined ? routeData.countryId : 0,
        };
        // add countryId to satte obj
        _country.states.map((state) => (state.countryId = id));

        const _from: ICountryForm = {
          active: false,
          countryCode: "",
          currency: "",
          currencyDescription: "",
          imageUrl: "",
          isoCode: "",
          name: "",
          searchRadius: 0,
          transportFeeMinRadius: 0,
          payoutBufferDays: 0,
        };

        _from.countryCode = _country.countryCode;
        _from.currency = _country.currency;
        _from.currencyDescription = _country.currencyDescription;
        _from.active = _country.active;
        _from.imageUrl = _country.imageUrl;
        _from.isoCode = _country.isoCode;
        _from.name = _country.name;
        _from.searchRadius = _country.searchRadius;
        _from.transportFeeMinRadius = _country.transportFeeMinRadius;
        _from.payoutBufferDays = _country.payoutBufferDays;

        form.setFieldsValue(_from);
      })
      .catch((error) => {
        setIsLoading(false);
        const _errorObj = getErrorObj(error.response);
        setError(_errorObj);
      });
  };
  /**
   * Function to update country information
   *@method updateCountry
   *@param country type ICountryPayload
   */
  const updateCountry = (country: ICountryPayload) => {
    axios
      .put("/admin-api/Countries", country)
      .then((response) => {
        setIsLoading(false);
        triggerNotification({
          type: "success",
          title: "Edit Country",
          message: (
            <p>Country {form.getFieldValue("name")} edited successfully.</p>
          ),
        });
      })
      .catch((error) => {
        setIsLoading(false);
        const _errorObj = getErrorObj(error.response);
        setError(_errorObj);
      });
  };
  /**
   * Function to add new country
   *@method addCountry
   *@param country type ICountryPayload
   */
  const addCountry = (country: ICountryPayload) => {
    axios
      .post("admin-api/Countries", country)
      .then((response) => {
        setIsLoading(false);
        triggerNotification({
          type: "success",
          title: "Add Country",
          message: (
            <p>Country {form.getFieldValue("name")} added successfully.</p>
          ),
        });
      })
      .catch((error) => {
        setIsLoading(false);
        const _errorObj = getErrorObj(error.response);
        setError(_errorObj);
      });
  };
  /**
   * Fn to generate country form elements
   *@method fetchFormData
   */
  // const fetchFormData = async () => {
  //   const _form: ICountryFormConfig = await MASTER_FORM_CONFIG.countryForm; //await statement
  //   const formElementsArray: IFormInput[] = [];
  //   Object.keys(_form).forEach((elm) => {
  //     switch (elm) {
  //       case "name":
  //       case "countryCode":
  //       case "currency":
  //       case "currencyDescription":
  //       case "isoCode":
  //       case "imageUrl":
  //       case "active":
  //       case "searchRadius":
  //       case "transportFeeMinRadius":
  //       case "payoutBufferDays":
  //         formElementsArray.push({
  //           id: elm,
  //           label: _form[elm].label,
  //           elementType: _form[elm].elementType,
  //           elementConfig: _form[elm].elementConfig,
  //           value: _form[elm].value !== undefined ? _form[elm].value : "",
  //         });
  //         break;
  //     }
  //   });

  //   // setForm(formElementsArray);
  // };

  /**
   * Function to build payload and call post endpoint
   *@method handleSubmit
   * @param event
   */
  const handleSubmit = (formData: ICountryForm) => {
    const _country: ICountryPayload = {
      ...formData,
      id: routeData.countryId !== undefined ? routeData.countryId : 0,
      states: [],
    };

    setIsLoading(true);
    if (routeData.type === "edit") {
      updateCountry(_country);
    } else {
      addCountry(_country);
    }
  };

  /**
   * Function to go back to previous page
   *@method handlePreviousPage
   */
  const handlePreviousPage = () => {
    history.goBack();
  };
  /**
   * Function to handle form fail
   *@method onFinishFailed
   */
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    switch (routeData.type) {
      case "add":
        break;
      case "edit":
        routeData.countryId !== undefined &&
          getCountryById(routeData.countryId);
        break;
    }
  }, []);
  return (
    <>
      <div className="flex items-center mb-10" id="title-bar">
        <ArrowLeftIcon
          className=" h-7 w-7 mr-4 cursor-pointer text-gray-900"
          onClick={handlePreviousPage}
        />
        <h1 className="mb-0">
          <span className="capitalize">{routeData.type}</span> Country
        </h1>
      </div>
      <Card title="Country Information" bordered={false}>
        <Form
          form={form}
          name="countryForm"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 780 }}
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <div className="columns-1 ">
            <Form.Item
              key={"name"}
              id={"name"}
              label={"Name"}
              name={"name"}
              style={{ fontWeight: "500" }}
              rules={[{ required: true, message: "Please enter country name" }]}
            >
              <Input autoComplete="nope" placeholder="Country name" />
            </Form.Item>
          </div>
          <div className="columns-2 ">
            <Form.Item
              key={"countryCode"}
              id={"countryCode"}
              label={"Country Code"}
              name={"countryCode"}
              style={{ fontWeight: "500" }}
              rules={[
                {
                  required: true,
                  message: "Please enter country code",
                },
                {
                  pattern: /^(\+?\d{1,3}|\d{1,4})$/,
                  message: "Please enter a valid country code",
                },
              ]}
            >
              <Input placeholder="+60" autoComplete="nope" />
            </Form.Item>
            <Form.Item
              key={"isoCode"}
              id={"isoCode"}
              label={"ISO Code"}
              name={"isoCode"}
              style={{ fontWeight: "500" }}
              rules={[
                {
                  required: true,
                  message: "Please enter ISO code",
                },
                {
                  type: "string",
                  max: 2,
                },
              ]}
            >
              <Input placeholder="MY" autoComplete="nope" />
            </Form.Item>
          </div>
          <div className="columns-1 ">
            <div className="columns-2 ">
              <Form.Item
                key={"currency"}
                id={"currency"}
                label={"Currency"}
                name={"currency"}
                style={{ fontWeight: "500" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter currency",
                  },
                  {
                    type: "string",
                    max: 3,
                  },
                ]}
              >
                <Input autoComplete="nope" placeholder="MYR" />
              </Form.Item>
              <Form.Item
                key={"currencyDescription"}
                id={"currencyDescription"}
                label={"Symbol"}
                name={"currencyDescription"}
                style={{ fontWeight: "500" }}
                rules={[
                  {
                    required: true,
                    message: "Please enter currency symbol",
                  },
                ]}
              >
                <Input autoComplete="nope" placeholder="RM" />
              </Form.Item>
            </div>
          </div>
          <div className="columns-1 ">
            <Form.Item
              key={"imageUrl"}
              id={"imageUrl"}
              label={"Image Url"}
              name={"imageUrl"}
              style={{ fontWeight: "500" }}
              rules={[
                {
                  required: true,
                  message: "Please enter image url",
                },
              ]}
            >
              <Input autoComplete="nope" />
            </Form.Item>
          </div>
          <div className="columns-2 ">
            <Form.Item
              key={"searchRadius"}
              id={"searchRadius"}
              label={"Search Radius"}
              name={"searchRadius"}
              style={{ fontWeight: "500" }}
              rules={[
                {
                  required: true,
                  message: "Please enter search radius",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} autoComplete="nope" />
            </Form.Item>
            <Form.Item
              key={"transportFeeMinRadius"}
              id={"transportFeeMinRadius"}
              label={"Transport Fee Min Radius"}
              name={"transportFeeMinRadius"}
              style={{ fontWeight: "500" }}
              rules={[
                {
                  required: true,
                  message: "Please enter min transport radius",
                },
              ]}
            >
              <InputNumber style={{ width: "100%" }} autoComplete="nope" />
            </Form.Item>
          </div>
          <div className="columns-1">
            <Form.Item
              key={"payoutBufferDays"}
              id={"payoutBufferDays"}
              label={"Payout Buffer Days"}
              name={"payoutBufferDays"}
              style={{ fontWeight: "500" }}
              rules={[
                {
                  required: true,
                  message: "Please enter payout buffer days",
                },
              ]}
            >
              <InputNumber style={{ width: "50%" }} autoComplete="nope" />
            </Form.Item>
          </div>
          <div className="columns-1">
            <Form.Item
              key={"active"}
              id={"active"}
              label={"Status"}
              name={"active"}
              style={{ fontWeight: "500" }}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </div>
          {/* {form.map((elem) => {
            if (elem.id === "active") {
              return (
                <Form.Item
                  key={elem.id}
                  id={elem.id}
                  label={elem.label}
                  name={elem.elementConfig.name}
                >
                  <Checkbox>Active</Checkbox>
                </Form.Item>
              );
            } else {
              return (
                <Form.Item
                  key={elem.id}
                  id={elem.id}
                  label={elem.label}
                  name={elem.elementConfig.name}
                >
                  <Input />
                </Form.Item>
              );
            }
          })} */}
          <Form.Item wrapperCol={{ span: 16 }}>
            <Button
              type="primary"
              ghost
              onClick={handlePreviousPage}
              size="large"
            >
              Back
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="btn-antd--primary"
              loading={isLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>

      {process.env.NODE_ENV === "development" && <Measure before={before} />}
    </>
  );
};
