import React, { useReducer } from "react";
import { ActionType } from "../../store/reducers";

import AppContext from "./AppContext";
import appReducer from "../../store/reducers/App";
import axios from "../../lib/axios/axios.nuvad";
import { notification } from "antd";
import { ExclamationIcon } from "@heroicons/react/outline";
interface IAppProviderProps {
  children: React.ReactNode;
}

type NotificationType = "success" | "info" | "warning" | "error";

const initialAppState = {
  countries: [],
};

const AppProvider: React.FC<IAppProviderProps> = ({
  children,
}: IAppProviderProps) => {
  const [state, dispatch] = useReducer(appReducer, initialAppState);
  //hooks
  const [api, contextHolder] = notification.useNotification();
  /**
   * Get active countires
   * @method getActiveCountriesHandler
   */
  const getActiveCountriesHandler = async () => {
    await axios
      .get("/admin-api/Countries/GetAvailable")
      .then((response) => {
        dispatch({
          type: ActionType.AddCountires,
          payload:
            response.data.countries.length !== 0
              ? response.data.countries
              : null,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   * trigger notification
   * @method triggerNotification
   */
  const triggerNotificationHandler = (notification: INotification) => {
    api[notification.type as NotificationType]({
      message: notification.title,
      description: notification.message,
      duration: notification?.duration,
    });
  };
  const appProviderValue: IAppContext = {
    countries: state.countries,
    triggerNotification: triggerNotificationHandler,
    getActiveCountires: getActiveCountriesHandler,
  };
  return (
    <AppContext.Provider value={appProviderValue}>
      {contextHolder}
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
