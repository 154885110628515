import React, { Fragment } from "react";

export const ThumbnailCell = ({
  data,
  itemKey,
  type,
}: ITableCustomCell): JSX.Element => {
  return (
    <Fragment>
      <div className="flex" id={itemKey}>
        <div className="flex-shrink-0 h-20 w-32">
          <img
            className={`${
              type === "contain" ? "object-contain" : "object-cover"
            } h-20 w-full rounded-md`}
            src={data.coverImageUrl as string}
            alt="country-flag"
          />
        </div>
        <div className="ml-3">
          <p className="text-gray-900 whitespace-no-wrap">{`${data.type}`}</p>
          {/* <p className="text-gray-600 whitespace-no-wrap">{data.isoCode}</p> */}
        </div>
      </div>
    </Fragment>
  );
};
