import Input from "antd/es/input";
import { Checkbox, Form, InputNumber, Select } from "antd";

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: any;
  inputType: "number" | "text" | "select" | "checkbox";
  record: CountryRate;
  index: number;
  required: boolean;
  options?: TSelectOption[];
  children: React.ReactNode;
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  required,
  options,
  ...restProps
}) => {
  let inputNode = <Input />;
  // const inputNode = inputType === "number" ? <InputNumber /> : <Input />;
  switch (inputType) {
    case "number":
      inputNode = <InputNumber />;
      break;
    case "select":
      inputNode = <Select options={options} />;
      break;
    case "checkbox":
      inputNode = <Checkbox />;
      break;
  }
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{ margin: 0 }}
          rules={[
            {
              required: required,
              message: `Please Input ${title}!`,
            },
          ]}
          valuePropName={inputType === "checkbox" ? "checked" : undefined}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};
export default EditableCell;
