import React, { useEffect, useReducer } from "react";
import ErrorContext from "./errorContext";
import errorReducer from "../reducers/errorReducer";
import axiosInstance from "../../../lib/axios/axios.nuvad";
import { ActionType } from "../reducers/errorActions";
import notification from "antd/es/notification";
import ExclamationIcon from "@heroicons/react/outline/ExclamationIcon";

interface IErrorProviderProps {
  children: React.ReactNode;
}

const initialState: IErrorState = {
  error: { status: 0, title: "", errorList: [] },
};

const ErrorProvider: React.FC<IErrorProviderProps> = ({
  children,
}: IErrorProviderProps) => {
  //state
  const [state, dispatch] = useReducer(errorReducer, initialState);
  //hooks
  const [api, contextHolder] = notification.useNotification();
  /**
   * update error state
   * @method updateErrorHandler
   */
  const updateErrorHandler = (error: IError) => {
    dispatch({ type: ActionType.setError, payload: error });

    api.error({
      message: error.title,
      description: error.errorList.map((error, index) => (
        <p key={index}>{error.Message}</p>
      )),
      icon: (
        <ExclamationIcon style={{ width: "2rem" }} className=" text-red-600" />
      ),
      duration: 0,
    });
  };

  const errorProviderValue: IErrorContext = {
    error: state.error,
    setError: updateErrorHandler,
  };
  const requestInterceptor = axiosInstance.interceptors.request.use(
    (config) => {
      // Do something before request is sent
      const oidcStorage: IOIDCStorage = JSON.parse(
        sessionStorage.getItem(
          `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
        ) || "{}"
      );
      config.headers!["Authorization"] = "Bearer " + oidcStorage.access_token;
      config.headers!["Content-Type"] = "application/json";

      return config;
    },
    (error) => {
      // Do something with request error
      return Promise.reject(error);
    }
  );

  const responseInterceptor = axiosInstance.interceptors.response.use(
    (response) => {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data

      return response;
    },
    (error) => {
      // Any status codes that falls outside the range of 2xx cause this function to trigger

      return Promise.reject(error);
    }
  );
  useEffect(() => {
    return () => {
      axiosInstance.interceptors.request.eject(requestInterceptor);
      axiosInstance.interceptors.response.eject(responseInterceptor);
    };
  }, []);
  return (
    <ErrorContext.Provider value={errorProviderValue}>
      <>
        {contextHolder}
        {children}
      </>
    </ErrorContext.Provider>
  );
};

export default ErrorProvider;
