export const TOPUP = {
  topupForm: {
    remarks: {
      elementType: "input",
      label: "remarks",
      elementConfig: {
        type: "text",
        placeholder: "Enter remarks",
        name: "remarks",
      },
      value: "",
    },
    bankReferenceNo: {
      elementType: "input",
      label: "Bank Reference No",
      elementConfig: {
        type: "text",
        placeholder: "Enter bank reference no",
        name: "bankReferenceNo",
      },
      value: "",
    },
    paymentMethod: {
      elementType: "select",
      label: "Payment Method",
      elementConfig: {
        name: "paymentMethod",
      },
      value: "",
      options: [
        { id: 1, value: "Cash" },
        { id: 2, value: "Card" },
        { id: 3, value: "Bank In" },
      ],
    },
    amount: {
      elementType: "input",
      label: "Amount",
      elementConfig: {
        type: "number",
        placeholder: "Enter amount",
        name: "amount",
        pattern: "^d*(.d{0,2})?$",
      },
      value: "",
    },
    receiptUrl: {
      elementType: "file",
      label: "Thumbnail",
      elementConfig: {
        type: "file",
        name: "receiptUrl",
      },
      value: "",
    },
  },
};
