import React, {useContext} from "react";
import { AuthContext } from "../../providers/authProvider";

export const Callback = () => {
    const { signinRedirectCallback} = useContext(AuthContext);
    signinRedirectCallback();
    return (
            <span>loading</span>
    )
}
    
    
