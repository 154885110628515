import { Suspense } from "react";
import { Routes } from "./routes/Routes";
import { Route, Switch } from "react-router-dom";
import { Callback, Logout, LogoutCallback, SilentRenew } from "./components";
import { LoginPage } from "./pages";
import "antd/dist/reset.css";
import ErrorProvider from "./features/errorhandling/context/ErrorProvider";

function App(): JSX.Element {
  return (
    <Suspense fallback={<div>loading</div>}>
      <div className="App">
        <ErrorProvider>
          <Switch>
            <Route exact={true} path="/signin-oidc" component={Callback} />
            <Route exact={true} path="/logout" component={Logout} />
            <Route
              exact={true}
              path="/logout/callback"
              component={LogoutCallback}
            />
            <Route exact={true} path="/login" component={LoginPage} />
            <Route exact={true} path="/silentrenew" component={SilentRenew} />
            <Routes />
            <Route path="/" component={LoginPage} />
          </Switch>
        </ErrorProvider>
      </div>
    </Suspense>
  );
}

export default App;
