import React, { useState, useEffect, useContext } from "react";

import {
  AppConfigTab,
  BanksTab,
  SPIndividualsConfigTab,
  SPBusinessesConfigTab,
} from "./Tabs";
import { getProviderType, getRateType } from "../../utils";
import AppContext from "../../context/App/AppContext";
import MasterDataContext from "../../context/Masterdata/MasterDataContext";
import axios from "../../lib/axios/axios.nuvad";
import Tabs, { TabsProps } from "antd/es/tabs";

const oidcStorage: IOIDCStorage = JSON.parse(
  sessionStorage.getItem(
    `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
  ) || "{}"
);
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + oidcStorage.access_token,
  },
};

export const MasterData: React.FC = () => {
  //Context
  // const { countries, getActiveCountires } = useContext(AppContext);
  const [allCountries, setAllCountries] = useState<ICountry[]>([]);
  const { SPIndividualTypes, SPBusinessTypes, getBusinessTypes } =
    useContext(MasterDataContext);
  // Table data states
  const [tbCountryData, setTbCountryData] = useState<ITableCell[]>();
  const [tbBankData, setTbBankData] = useState<ITableCell[]>();
  const [tbQualificationsData, setTbQualificationsData] =
    useState<ITableCell[]>();
  const [tbIndividualTypes, setTbIndividualTypes] = useState<ITableCell[]>();
  const [tbBusinessTypes, setTbBusinessTypes] = useState<ITableCell[]>();
  const [individualServices, setIndividualServices] = useState<ITableCell[]>();
  const [businessServices, setBusinessServices] = useState<ITableCell[]>();
  const [tab, setTab] = useState<string>("config");

  /**
   * Fn to get all countires
   *@method getAllCountries
   */
  const getAllCountries = async () => {
    await axios
      .get("admin-api/Countries", config)
      .then((response) => {
        const _tempCountries: ITableCell[] = [];
        const countries: ICountry[] = response.data.countries;
        if (countries.length !== 0) {
          //get keys from response
          const objKeys = Object.keys(countries[0]);

          //Build final array to bind to table
          countries.forEach((country) => {
            const _Obj: ITableCell = {};
            objKeys.forEach((colName: string) => {
              switch (colName) {
                case "name":
                case "currency":
                case "currencyDescription":
                case "imageUrl":
                case "countryCode":
                case "isoCode":
                  _Obj[colName] = country[colName];
                  break;
                case "id":
                case "searchRadius":
                case "transportFeeMinRadius":
                  _Obj[colName] = country[colName].toString();
                  break;
                case "active":
                  country[colName]
                    ? (_Obj["status"] = "active")
                    : (_Obj["status"] = "inactive");
                  break;
                default:
              }
            });
            _tempCountries.push(_Obj);
          });
          setAllCountries(countries);
          setTbCountryData(_tempCountries);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /**
   * Fn to get all banks
   *@method getAllBanks
   */
  const getAllBanks = async () => {
    await axios
      .get("/admin-api/Banks", config)
      .then((response) => {
        const _tempBanks: ITableCell[] = [];

        const _banks: Bank[] = response.data.banks;

        if (_banks.length !== 0) {
          //get keys from response
          const objKeys = Object.keys(_banks[0]);

          //Build final array to bind to table
          _banks.forEach((bank) => {
            const _Obj: ITableCell = {};
            objKeys.forEach((colName: string) => {
              switch (colName) {
                case "name":
                  _Obj[colName] = bank[colName];
                  break;
                case "id":
                  _Obj[colName] = bank[colName].toString();
                  break;
                case "active":
                  bank[colName]
                    ? (_Obj["status"] = "active")
                    : (_Obj["status"] = "inactive");
                  break;
                case "countryId":
                  const _country = allCountries.find(
                    (result) => result.id === bank[colName]
                  );
                  _Obj[colName] = _country ? _country.name : "invalid country";
                  break;
                default:
              }
            });
            _tempBanks.push(_Obj);
          });

          setTbBankData(_tempBanks);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /**
   * Fn to build business type tables
   *@method buildBusinessTypesTable
   *@param id providerType id
   */
  const buildBusinessTypesTable = (providerTypeId: number) => {
    const _tempRow: ITableCell[] = [];
    let _businessTypes: IBusinessType[] = [];
    providerTypeId === 1
      ? (_businessTypes = SPIndividualTypes)
      : (_businessTypes = SPBusinessTypes);
    // const _businessTypes: IBusinessType[] = SPBusinessTypes;
    if (_businessTypes.length !== 0) {
      //get keys from response
      const objKeys = Object.keys(_businessTypes[0]);
      _businessTypes.forEach((type) => {
        const _Obj: ITableCell = {};
        objKeys.forEach((colName: string) => {
          switch (colName) {
            case "type":
            case "coverImageUrl":
              _Obj[colName] = type[colName];
              break;
            case "providerType":
              _Obj[colName] = getProviderType(type[colName]);
              break;
            case "id":
              _Obj[colName] = type[colName].toString();
              break;
          }
        });
        _tempRow.push(_Obj);
      });

      providerTypeId === 1 && setTbIndividualTypes(_tempRow);
      providerTypeId === 2 && setTbBusinessTypes(_tempRow);
    }
  };

  /**
   * Fn to call GET  business services
   *@method getBusinessServiceTypes
   *@param businessTypes array of business types to match id and get business type name
   */
  const getBusinessServiceTypes = async (businessTypes: IBusinessType[]) => {
    await axios
      .get(`/admin-api/BusinessServiceTypes`, config)
      .then((response) => {
        const _tempRow: ITableCell[] = [];

        const _services: ISPServiceType[] = response.data.serviceTypes;
        if (_services.length !== 0) {
          //get keys from response
          const objKeys = Object.keys(_services[0]);
          _services.forEach((service) => {
            const _Obj: ITableCell = {};
            objKeys.forEach((colName: string) => {
              switch (colName) {
                case "type":
                  _Obj[colName] = service[colName];
                  break;
                case "id":
                  _Obj[colName] = service[colName].toString();
                  break;
                case "businessTypeId":
                  const _business = businessTypes.find(
                    (business) => business.id === service[colName]
                  );
                  _Obj[colName] = _business
                    ? _business.type
                    : "invalid business type";
                  break;
              }
            });
            _tempRow.push(_Obj);
          });

          setBusinessServices(_tempRow);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   * Fn to call GET qualifications
   *@method getQualifications
   */
  const getQualifications = async () => {
    await axios
      .get("admin-api/Qualifications", config)
      .then((response) => {
        const _tempQualifications: ITableCell[] = [];
        const qualifications: IQualification[] = response.data.qualifications;
        if (qualifications.length !== 0) {
          //get keys from response
          const objKeys = Object.keys(qualifications[0]);
          console.log(qualifications);
          //Build final array to bind to table
          qualifications.forEach((result) => {
            const _Obj: ITableCell = {};
            objKeys.forEach((colName: string) => {
              switch (colName) {
                case "name":
                case "description":
                  _Obj[colName] = result[colName];
                  break;
                case "id":
                  _Obj[colName] = result[colName].toString();
                  break;
                case "active":
                  result[colName]
                    ? (_Obj["status"] = "active")
                    : (_Obj["status"] = "inactive");
                  break;
                default:
              }
            });
            _tempQualifications.push(_Obj);
          });

          setTbQualificationsData(_tempQualifications);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /**
   * fn to switch tabs
   *@method switchTabs
   *@param index tab number
   */
  const switchTabs = (key: string) => {
    setTab(key);
  };

  const tabs: TabsProps["items"] = [
    {
      key: "config",
      label: `App Config`,
      children: (
        <AppConfigTab
          tbCountires={tbCountryData}
          tbQualifications={tbQualificationsData}
        />
      ),
    },
    {
      key: "banks",
      label: `Registered Banks`,
      children: <BanksTab tbData={tbBankData} />,
    },
    {
      key: "spi",
      label: `SPIndividuals Config`,
      children: <SPIndividualsConfigTab tbData={tbIndividualTypes} />,
    },
    {
      key: "spb",
      label: `SPBusiness Config`,
      children: (
        <SPBusinessesConfigTab
          tbBusinessTypes={tbBusinessTypes}
          tbServicesTypes={businessServices}
          getBusinessServiceTypes={() =>
            getBusinessServiceTypes(SPBusinessTypes)
          }
        />
      ),
    },
  ];
  useEffect(() => {
    switch (tab) {
      case "config":
        getAllCountries();
        getQualifications();
        break;
      case "banks":
        getAllBanks();
        break;
      case "spi":
        buildBusinessTypesTable(1);

        break;
      case "spb":
        buildBusinessTypesTable(2);
        getBusinessServiceTypes(SPBusinessTypes);
        break;
    }
  }, [tab]);

  useEffect(() => {
    allCountries.length === 0 && getAllCountries();
    SPIndividualTypes.length === 0 && getBusinessTypes(1);
    SPBusinessTypes.length === 0 && getBusinessTypes(2);
  }, []);
  return (
    <>
      <h1 className="mb-10">Master Data</h1>
      <div className="" id="tab-wrapper">
        <Tabs
          defaultActiveKey="new"
          items={tabs}
          size="large"
          onChange={switchTabs}
        />
      </div>
    </>
  );
};
