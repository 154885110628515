import React from "react";
import { useHistory } from "react-router-dom";
import DynamicTable from "../../../components/organisms/DynamicTable/DynamicTable";
import { TableCustomCell } from "./TableCustomCell";
import Table, { ColumnsType } from "antd/es/table";
import { UserCell } from "../../../templates/CustomCells/UserCell";
import ActionCell from "../../../templates/CustomCells/ActionCell";
interface INewRegistrationsTabProps {
  tbData: ITableCell[] | undefined;
}
export const NewRegistrationsTab: React.FC<INewRegistrationsTabProps> = ({
  tbData,
}: INewRegistrationsTabProps) => {
  const history = useHistory();
  // const approvedTableColumns: ITableColumns[] = [
  //   {
  //     key: "id",
  //     title: "ID",
  //   },
  //   {
  //     key: "user",
  //     title: "Applicant Name",
  //   },
  //   {
  //     key: "country",
  //     title: "Country",
  //   },
  //   {
  //     key: "businessType",
  //     title: "Service Category",
  //   },
  //   {
  //     key: "created",
  //     title: "Created Date",
  //   },
  //   {
  //     key: "status",
  //     title: "Status",
  //     customCell: true,
  //   },
  //   {
  //     key: "action",
  //     title: "",
  //     buttons: [
  //       { key: "id", label: "View" },
  //       { key: "id", label: "Review" },
  //     ],
  //   },
  // ];

  const columns: ColumnsType<Record<string, any>> = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "user",
      title: "Applicant Name",
      dataIndex: "user",
      render: (_, record) => <UserCell data={record} itemKey={"user"} />,
    },
    {
      key: "country",
      title: "Country",
      dataIndex: "country",
    },
    {
      key: "businessType",
      title: "Service Category",
      dataIndex: "businessType",
    },
    {
      key: "created",
      title: "Created Date",
      dataIndex: "created",
    },
    {
      key: "action",
      title: "Action",
      render: (_, record) => (
        <ActionCell
          data={record}
          actions={[
            { key: "id", label: "View" },
            { key: "id", label: "Review" },
          ]}
          handlePrimaryFn={handleViewProfile}
          handleSecondaryFn={handleVerifyProfile}
        />
      ),
    },
  ];
  const handleViewProfile = (id: number | string) => {
    history.push({
      pathname: `/practitionerprofile/${id}`,
    });
  };

  const handleVerifyProfile = (id: number | string) => {
    history.push({
      pathname: `/verifyprofile/${id}`,
      state: { type: "edit" },
    });
  };
  // const handleViewBtnFn = (id: number | string) => {
  //   history.push({
  //     pathname: `/practitionerprofile/${id}`,
  //   });
  // };
  // const handleReviewBtnFn = (id: number | string) => {
  //   history.push({
  //     pathname: `/verifyprofile/${id}`,
  //     state: { type: "edit" },
  //   });
  // };
  return (
    <>
      <div className="mb-3" id="title-bar">
        <h2 className="dark:text-slate-900">New Registrations</h2>
      </div>
      <Table
        dataSource={tbData}
        columns={columns}
        // onRow={(record) => {
        //   return {
        //     onClick: (event) => handleViewProfile(record.id), // click row
        //   };
        // }}
      />
      {/* <DynamicTable
        tbData={tbData}
        tbColumns={approvedTableColumns}
        id="new-registrations-tb"
        handlePrimaryFn={(id) => handleViewBtnFn(id)}
        handleSecondaryFn={(id) => handleReviewBtnFn(id)}
        RenderTableCustomCell={(props: ITableCustomCell) => (
          <TableCustomCell {...props} />
        )}
      /> */}
    </>
  );
};
