import React from "react";
import { useHistory } from "react-router-dom";
import { ColumnsType } from "antd/es/table/InternalTable";
import { UserCell } from "../../../templates/CustomCells/UserCell";
import ActionCell from "../../../templates/CustomCells/ActionCell";
import Table from "antd/es/table";
interface IPendingRegistrationsTabProps {
  tbData: ITableCell[] | undefined;
}
export const PendingRegistrationsTab: React.FC<
  IPendingRegistrationsTabProps
> = ({ tbData }: IPendingRegistrationsTabProps) => {
  const history = useHistory();
  // const approvedTableColumns: ITableColumns[] = [
  //   {
  //     key: "id",
  //     title: "ID",
  //   },
  //   {
  //     key: "user",
  //     title: "Applicant Name",
  //   },
  //   {
  //     key: "country",
  //     title: "Country",
  //   },
  //   {
  //     key: "businessType",
  //     title: "Service Category",
  //   },
  //   {
  //     key: "created",
  //     title: "Created Date",
  //   },
  //   {
  //     key: "status",
  //     title: "Status",
  //     customCell: true,
  //   },
  //   {
  //     key: "action",
  //     title: "",
  //     buttons: [
  //       { key: "id", label: "View" },
  //       { key: "id", label: "Edit" },
  //     ],
  //   },
  // ];
  const columns: ColumnsType<Record<string, any>> = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "user",
      title: "Applicant Name",
      dataIndex: "user",
      render: (_, record) => <UserCell data={record} itemKey={"user"} />,
    },
    {
      key: "country",
      title: "Country",
      dataIndex: "country",
    },
    {
      key: "businessType",
      title: "Service Category",
      dataIndex: "businessType",
    },
    {
      key: "created",
      title: "Created Date",
      dataIndex: "created",
    },
    {
      key: "action",
      title: "Action",
      render: (_, record) => (
        <ActionCell
          data={record}
          actions={[{ key: "id", label: "View" }]}
          handlePrimaryFn={handleViewProfile}
        />
      ),
    },
  ];

  const handleViewProfile = (id: number | string) => {
    history.push({
      pathname: `/practitionerprofile/${id}`,
    });
  };
  // const handleViewBtnFn = (id: number | string) => {
  //   history.push({
  //     pathname: `/practitionerprofile/${id}`,
  //   });
  // };
  const handleReviewBtnFn = (id: number | string) => {
    history.push({
      pathname: `/verifyprofile/${id}`,
      state: { type: "edit" },
    });
  };
  return (
    <>
      <div className="mb-3" id="title-bar">
        <h2 className="dark:text-slate-900">Pending Registrations</h2>
      </div>
      <Table dataSource={tbData} columns={columns} />
      {/* <DynamicTable
        tbData={tbData}
        tbColumns={approvedTableColumns}
        id="pending-registrations-tb"
        handlePrimaryFn={(id) => handleViewBtnFn(id)}
        handleSecondaryFn={(id) => handleReviewBtnFn(id)}
        RenderTableCustomCell={(props: ITableCustomCell) => (
          <TableCustomCell {...props} />
        )}
      /> */}
    </>
  );
};
