import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { memo } from "react";

interface IConfirmationTpl {
  message: string;
  title: string;
}
const ConfirmationTpl = ({ message, title }: IConfirmationTpl): JSX.Element => {
  return (
    <div
      className="relative   md:flex md:items-center md:justify-center"
      id="modal-inner"
    >
      <div
        className="bg-white rounded-lg md:max-w-md md:mx-auto  fixed inset-x-0 bottom-0 z-50  md:relative"
        id="modal-content"
      >
        <div className="md:flex items-center">
          <div className="rounded-full border border-gray-300 flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto">
            <ExclamationCircleIcon />
          </div>
          <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
            <p className="font-bold dark:text-slate-900">{title}</p>
            <p className="dark:text-slate-900">{message}</p>
            {/* <p className="text-sm text-gray-700 mt-1">
              {children && <div className=" pr-8 mb-6">{children}</div>}
            </p> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default memo(ConfirmationTpl);
