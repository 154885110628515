import { PaperClipIcon } from "@heroicons/react/outline";
import React, { Fragment, Key } from "react";

interface ISummaryFormProps {
  summaryData: ISummarySection[];
}
export const SummaryForm: React.FC<ISummaryFormProps> = ({
  summaryData,
}: ISummaryFormProps) => {
  /**
   * Function to generate icon
   *@method generateIcon
   *@param icon icon name
   *@returns JSX icon
   */
  const generateIcon = (icon: string): JSX.Element => {
    let _icon = <div></div>;
    switch (icon.toLowerCase()) {
      case "paperclipicon":
        _icon = (
          <PaperClipIcon
            className="flex-shrink-0 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        );
        break;
    }
    return _icon;
  };
  /**
   * Function to generate dat and labels
   *@method generateSummaryElements
   *@param element type of SummaryData
   *@param elemIndex type of Key
   *@returns JSX elements of data and labels
   */
  const generateSummaryElements = (
    element: SummaryData,
    elemIndex: Key
  ): JSX.Element | JSX.Element[] => {
    let _template: JSX.Element | JSX.Element[];
    if (element.subValue?.length !== 0 && element.subValue !== undefined) {
      _template = element.subValue.map((subObj, subObjIndex) => {
        return (
          <div
            className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
            id={subObj.id}
            key={subObjIndex}
          >
            <dt className="text-sm font-medium text-gray-500">
              {subObj.label}
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-transform: capitalize">
              {subObj.value}
            </dd>
          </div>
        );
      });
    } else if (element.list && element.list.length !== 0) {
      _template = (
        <div
          className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
          id={element.id}
          key={elemIndex}
        >
          <dt className="text-sm font-medium text-gray-500">{element.label}</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
            <ul className="border border-gray-200 rounded-md divide-y divide-gray-200">
              {element.list.map((result) => (
                <li
                  key={result.id}
                  className="pl-3 pr-4 py-3 flex items-center justify-between text-sm"
                >
                  <div className="w-0 flex-1 flex items-center">
                    {/* <PaperClipIcon
                      className="flex-shrink-0 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    /> */}
                    {generateIcon(result.icon)}
                    <span className="ml-2 flex-1 w-0 truncate">
                      {result.label}
                    </span>
                  </div>
                  <div className="ml-4 flex-shrink-0">
                    <a
                      href={result.value}
                      className="font-medium text-indigo-600 hover:text-indigo-500"
                    >
                      Download
                    </a>
                  </div>
                </li>
              ))}
            </ul>
          </dd>
        </div>
      );
    } else {
      _template = (
        <div
          className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
          id={element.id}
          key={elemIndex}
        >
          <dt className="text-sm font-medium text-gray-500">{element.label}</dt>
          <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2 text-transform: capitalize">
            {element.value}
          </dd>
        </div>
      );
    }

    return _template;
  };

  return (
    <div className="bg-white shadow overflow-hidden sm:rounded-lg">
      {summaryData.map((section, sectionIndex) => {
        return (
          <Fragment key={sectionIndex}>
            <div className="px-4 py-5 sm:px-6">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                {section.header.title}
              </h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">
                {section.header.subtitle}
              </p>
            </div>
            <div className="border-t border-gray-200">
              <dl>
                {section.body.map((elem, elemIndex) => {
                  return generateSummaryElements(elem, elemIndex);
                })}
              </dl>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};
