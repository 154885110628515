import React, { Fragment } from "react";
import { NavLink, useHistory } from "react-router-dom";

import logo from "../../../assets/images/nuvad-logo.png";
import userImg from "../../../assets/images/user-1.jpg";

import Dropdown from "antd/es/dropdown";
import type { MenuProps } from "antd";
import { sideMenuItems } from "../../../constants/sideMenu";
import Button from "antd/es/button";
import Menu from "antd/es/menu/menu";

interface ISideBarProps {
  logoutFn: () => void;
}
export const SideBar: React.FC<ISideBarProps> = ({
  logoutFn,
}: ISideBarProps) => {
  const history = useHistory();

  const items: MenuProps["items"] = [
    {
      key: "1",
      label: (
        <Button type="ghost" onClick={() => logoutFn()}>
          Logout
        </Button>
      ),
    },
  ];
  // const menu = (
  //   <Menu>
  //     <Menu.Item key="0" onClick={() => logoutFn()}>
  //       Logout
  //     </Menu.Item>
  //   </Menu>
  // );
  const menuClickHandler: MenuProps["onClick"] = (e) => {
    history.push({
      pathname: `${e.key}`,
    });
  };

  return (
    <Fragment>
      <div
        className="bg-white dark:bg-gray-900 flex-shrink-0 flex-col "
        id="sidebar"
      >
        <div
          className="flex items-center justify-center my-5"
          id="sidebar-logo"
        >
          <img className="w-6/12" src={logo} alt="logo" />
        </div>
        <div
          className="flex flex-col items-center text-gray-900 dark:text-white my-5 p-2"
          id="sidebar-user"
        >
          <img src={userImg} alt="user" className="w-12 mb-2 rounded-full" />

          <Dropdown
            className="border rounded-lg w-full h-10  block relative"
            menu={{ items }}
          >
            <button onClick={(e) => e.preventDefault()}>
              Admin User
              <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </button>
          </Dropdown>
        </div>
        <div className="my-5" id="sidebar-nav-links">
          <Menu
            style={{ width: "220px" }}
            onClick={menuClickHandler}
            theme="dark"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            mode="inline"
            items={sideMenuItems}
          />
          {/* <ul className="flex mx-auto flex-grow mt-4 flex-col text-gray-400 space-y-4">
            <li className="p-2">
              <NavLink
                to="/dashboard"
                className="flex"
                activeClassName="text-nuvadRed font-semibold"
              >
                <HomeIcon className="h-6 w-6 mr-2" />
                Dashboard
              </NavLink>
            </li>
            <li className="p-2">
              <NavLink
                to="/practitioners"
                className="flex"
                activeClassName="text-nuvadRed font-semibold"
              >
                <UsersIcon className="h-6 w-6 mr-2" />
                Practitioners
              </NavLink>
            </li>
            <li className="p-2">
              <NavLink
                to="/spbusiness"
                className="flex"
                activeClassName="text-nuvadRed font-semibold"
              >
                <OfficeBuildingIcon className="h-6 w-6 mr-2" />
                SP Business
              </NavLink>
            </li>

            <li className="p-2">
              <NavLink
                to="/masterdata"
                className="flex"
                activeClassName="text-nuvadRed font-semibold"
              >
                <CogIcon className="h-6 w-6 mr-2" />
                Master Data
              </NavLink>
            </li>
            <li className="p-2">
              <NavLink
                to="/promotions"
                className="flex"
                activeClassName="text-nuvadRed font-semibold"
              >
                <GiftIcon className="h-6 w-6 mr-2" />
                Promotions
              </NavLink>
            </li>
          </ul> */}
        </div>
      </div>
    </Fragment>
  );
};
