import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { ArrowNarrowRightIcon } from "@heroicons/react/outline";

import { DailyOutcallSessionsTab } from "./Tabs/DailyOutcallSessionsTab";
import { DailyIncallSessionsTab } from "./Tabs/DailyIncallSessionsTab";
import axios from "../../lib/axios/axios.nuvad";
import dayjs from "dayjs";
import Card from "antd/es/card/Card";
import Tabs, { TabsProps } from "antd/es/tabs";
import Row from "antd/es/row";
import Col from "antd/es/col";

const oidcStorage: IOIDCStorage = JSON.parse(
  sessionStorage.getItem(
    `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
  ) || "{}"
);
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + oidcStorage.access_token,
  },
};

export const Dashboard: React.FC = () => {
  const [dailyOutcallSessions, setDailyOutcallSessions] =
    useState<ITableCell[]>();
  const [dailyIncallSessions, setdailyIncallSessions] =
    useState<ITableCell[]>();
  const [topupCount, setTopupCount] = useState<number>(0);
  const [payoutCount, setPayoutCount] = useState<number>(0);
  const [tab, setTab] = useState<string>("");

  const tabs: TabsProps["items"] = [
    {
      key: "outcall",
      label: `Daily Outcall`,
      children: <DailyOutcallSessionsTab tbData={dailyOutcallSessions} />,
    },
    {
      key: "incall",
      label: `Daily Incall`,
      children: <DailyIncallSessionsTab tbData={dailyIncallSessions} />,
    },
  ];
  /**
   * fn to call GET method pending payout count
   * @method getPendingPayoutCount
   */
  const getPendingPayoutCount = async () => {
    await axios
      .get("finance-api/Payouts/GetPendingPayoutsCount", config)
      .then((response) => {
        const _count: number = response.data.count;

        setPayoutCount(_count);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /**
   * fn to call GET pending top-up count
   * @method getPendingTopupCount
   */

  const getPendingTopupCount = async () => {
    await axios
      .get("finance-api/TopUp/GetPendingTopUpCount", config)
      .then((response) => {
        const _count: number = response.data.count;

        setTopupCount(_count);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /**
   * Get daily outcall sessions
   * @method getDailyOutcallSessions
   */
  const getDailyOutcallSessions = async () => {
    await axios
      .get("session-api/OutCallSessions/GetTodaysOutcallSessions", config)
      .then((response) => {
        const _data: IDailySessions[] = response.data;

        const _tempOutcallSessions: ITableCell[] = [];
        if (_data.length !== 0) {
          //get keys from response
          const objKeys = _data && Object.keys(_data[0]);

          //Build final array to bind to table
          _data.forEach((session) => {
            const _Obj: ITableCell = {};
            objKeys.forEach((colName: string) => {
              switch (colName) {
                case "id":
                case "paymentMethod":
                case "status":
                  _Obj[colName] = session[colName];
                  break;
                case "totalSessionPrice":
                  _Obj[colName] =
                    session[colName] !== null
                      ? session[colName].toString()
                      : "error";
                  break;
                case "createdDateTime":
                  _Obj[colName] = dayjs(session[colName]).format(
                    "DD/MM/YYYY h:mm A"
                  );

                  break;
              }
            });
            _tempOutcallSessions.push(_Obj);
          });

          setDailyOutcallSessions(_tempOutcallSessions);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /**
   * Get daily incall sessions
   * @method getDailyIncallSessions
   */
  const getDailyIncallSessions = async () => {
    await axios
      .get("session-api/InCallSessions/GetTodaysIncallSessions", config)
      .then((response) => {
        const _data: IDailySessions[] = response.data;
        const _tempIncallSessions: ITableCell[] = [];

        if (_data.length !== 0) {
          //get keys from response
          const objKeys = _data && Object.keys(_data[0]);

          //Build final array to bind to table
          _data.forEach((session) => {
            const _Obj: ITableCell = {};
            objKeys.forEach((colName: string) => {
              switch (colName) {
                case "id":
                case "paymentMethod":
                case "status":
                  _Obj[colName] = session[colName];
                  break;
                case "totalSessionPrice":
                  _Obj[colName] =
                    session[colName] !== null
                      ? session[colName].toString()
                      : "error";
                  break;
                case "createdDateTime":
                  _Obj[colName] = dayjs(session[colName]).format(
                    "DD/MM/YYYY h:mm A"
                  );

                  break;
              }
            });
            _tempIncallSessions.push(_Obj);
          });

          setdailyIncallSessions(_tempIncallSessions);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /**
   * fn to switch tabs
   *@method switchTabs
   *@param index tab number
   */
  const switchTabs = (key: string) => {
    setTab(key);
  };
  useEffect(() => {
    switch (tab) {
      case "outcall":
        getDailyOutcallSessions();
        break;
      case "incall":
        getDailyIncallSessions();
        break;
    }
  }, [tab]);

  useEffect(() => {
    getPendingPayoutCount();
    getPendingTopupCount();
  }, []);
  return (
    <>
      <h1 className="mb-10">Dashboard page</h1>
      <div id="card-wrap" className="mb-8">
        <Row gutter={[16, 24]}>
          <Col className="gutter-row" xs={24} md={12} xl={8}>
            <Card>
              <div className="mb-6">
                <h2 className="text-5xl font-bold mb-2">0</h2>
                <p className="font-bold">Pending Refund Requests</p>
              </div>
              <div className="flex justify-end ">
                <NavLink to="/" className="flex text-sm content-center">
                  View All
                  <ArrowNarrowRightIcon className="h-6 w-6 ml-2" />
                </NavLink>
              </div>
            </Card>
          </Col>
          <Col className="gutter-row" xs={24} md={12} xl={8}>
            <Card>
              <div className="mb-6">
                <h2 className="text-5xl font-bold mb-2">{payoutCount}</h2>
                <p className="font-bold">Total Pending Payouts</p>
              </div>
              <div className="flex justify-end ">
                <NavLink
                  to="/createPayouts"
                  className="flex text-sm content-center"
                >
                  Create Payouts
                  <ArrowNarrowRightIcon className="h-6 w-6 ml-2" />
                </NavLink>
                <NavLink
                  to="/pendingpayouts"
                  className="flex text-sm content-center"
                >
                  View All
                  <ArrowNarrowRightIcon className="h-6 w-6 ml-2" />
                </NavLink>
              </div>
            </Card>
          </Col>
          <Col className="gutter-row" xs={24} md={24} xl={8}>
            <Card>
              <div className="mb-6">
                <h2 className="text-5xl font-bold mb-2">{topupCount}</h2>
                <p className="font-bold">Pending Service Provider Topups</p>
              </div>
              <div className="flex justify-end ">
                <NavLink
                  to="/allpendingtopups"
                  className="flex text-sm content-center"
                >
                  View All
                  <ArrowNarrowRightIcon className="h-6 w-6 ml-2" />
                </NavLink>
              </div>
            </Card>
          </Col>{" "}
          <Col className="gutter-row" xs={24} md={12} xl={8}>
            <Card>
              <NavLink to="/" className="flex">
                <span className="text-base font-medium inline-block align-middle">
                  Manage Assets
                </span>
                <ArrowNarrowRightIcon className="w-6 ml-2 inline-block align-middle" />
              </NavLink>
            </Card>
          </Col>
          <Col className="gutter-row" xs={24} md={12} xl={8}>
            <Card>
              <NavLink to="/allvfcomponents" className="flex">
                <span className="text-base font-medium inline-block align-middle">
                  Manage Finance Components
                </span>
                <ArrowNarrowRightIcon className="w-6 ml-2 inline-block align-middle" />
              </NavLink>
            </Card>
          </Col>
          <Col className="gutter-row" xs={24} md={12} xl={8}>
            <Card>
              <NavLink to="/topupForm" className="flex">
                <span className="text-base font-medium inline-block align-middle">
                  Topup SP Wallet
                </span>
                <ArrowNarrowRightIcon className="w-6 ml-2 inline-block align-middle" />
              </NavLink>
            </Card>
          </Col>
        </Row>
      </div>

      <Tabs
        defaultActiveKey="1"
        items={tabs}
        size="large"
        onChange={switchTabs}
      />
    </>
  );
};
