import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { VFC_FORM_CONFIG } from "../../../constants/vfComponentsForm";

import { FormState, useFormInput } from "../../../hooks";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import FormInput from "../../../components/atoms/FormInput/FormInput";

import AppContext from "../../../context/App/AppContext";
import axios from "../../../lib/axios/axios.nuvad";
import message from "antd/es/message";

interface IFinanceVariablesFromProps {
  type: string;
  vfcId?: number;
}

interface IFinanceVariablesFormConfig {
  countryId: IFormConfig;
  process: IFormConfig;
  name: IFormConfig;
  amount: IFormConfig;
  isPercentage: IFormConfig;
  isDeductable: IFormConfig;
  providerType: IFormConfig;
}

const oidcStorage: IOIDCStorage = JSON.parse(
  sessionStorage.getItem(
    `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
  ) || "{}"
);
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + oidcStorage.access_token,
  },
};
const processOptions = [
  { id: 1, value: "Customer Payment" },
  { id: 2, value: "Customer Refund" },
  { id: 3, value: "Service Provider Earning" },
  { id: 4, value: "Service Provider Topup" },
  { id: 5, value: "Customer Session Booking" },
  { id: 6, value: "Customer Registration" },
  { id: 7, value: "Service Provider Registration" },
  { id: 8, value: "Service Provider Payout" },
];
const initialValues = {
  amount: 0,
  id: 0,
  name: "",
  process: 0,
  countryId: 0,
  isPercentage: false,
  isDeductable: false,
  providerType: 0,
};

const providerOptions = [
  { id: 1, value: "Individual" },
  { id: 2, value: "Business" },
];
/**
 * Finance Variable from to add and edit finance variables
 * @category Forms
 * @component
 * @namespace FinanceVariablesFrom
 * @returns Finance Variable  form page
 */
export const FinanceVariablesFrom: React.FC = () => {
  const { countries, getActiveCountires } = useContext(AppContext);

  const history = useHistory();
  const { state: routeData } = useLocation<IFinanceVariablesFromProps>();
  const [form, setForm] = useState<IFormInput[]>([]);
  const [initialFormValues, setInitialFormValues] =
    useState<IVFComponentPayload>(initialValues);

  // Custom hooks
  const { values, handleChange } = useFormInput(
    initialFormValues as unknown as FormState
  );

  const fetchFormData = async () => {
    const _form: IFinanceVariablesFormConfig = await VFC_FORM_CONFIG.vfcForm; //await statement
    const formElementsArray: IFormInput[] = [];
    Object.keys(_form).forEach((elm) => {
      switch (elm) {
        case "name":
        case "amount":
          formElementsArray.push({
            id: elm,
            label: _form[elm].label,
            elementType: _form[elm].elementType,
            elementConfig: _form[elm].elementConfig,
            value: _form[elm].value !== undefined ? _form[elm].value : "",
          });
          break;
        case "isPercentage":
        case "isDeductable":
          formElementsArray.push({
            id: elm,
            label: _form[elm].label,
            elementType: _form[elm].elementType,
            elementConfig: _form[elm].elementConfig,
            value: _form[elm].checked !== undefined ? _form[elm].checked : "",
          });
          break;
        case "countryId":
          const _countries: SelectOption[] = [];
          countries?.length !== 0 &&
            countries?.map((country) =>
              _countries.push({ id: country.id, value: country.name })
            );
          formElementsArray.push({
            id: elm,
            label: _form[elm].label,
            elementType: _form[elm].elementType,
            elementConfig: _form[elm].elementConfig,
            value: _form[elm].value !== undefined ? _form[elm].value : "",
            options: _countries,
          });
          break;
        case "process":
          formElementsArray.push({
            id: elm,
            label: _form[elm].label,
            elementType: _form[elm].elementType,
            elementConfig: _form[elm].elementConfig,
            value: _form[elm].value !== undefined ? _form[elm].value : "",
            options: processOptions,
          });
          break;
        case "providerType":
          formElementsArray.push({
            id: elm,
            label: _form[elm].label,
            elementType: _form[elm].elementType,
            elementConfig: _form[elm].elementConfig,
            value: _form[elm].value !== undefined ? _form[elm].value : "",
            options: providerOptions,
          });
          break;
      }
    });

    setForm(formElementsArray);
  };
  /**
   * Fn to call POST method to add new finance variable
   *@method createVFComponent
   *@param vfComponent type IVFComponentPayload
   */
  const createVFComponent = async (vfComponent: IVFComponentPayload) => {
    axios
      .post("admin-api/FinanceVariables", vfComponent, config)
      .then((response) => {
        console.log(response, "response");
        if (response.status === 201) {
          message.success(`Add finance variable successful `);
        }
      })
      .catch((error) => {
        message.success(`Add finance variable unsuccessful `);
        console.log(error);
      });
  };
  /**
   * Fn to call PUT method to edit a finance variable
   *@method updateVFComponent
   *@param vfComponent type IVFComponentPayload
   */
  const updateVFComponent = async (vfComponent: IVFComponentPayload) => {
    axios
      .put("admin-api/FinanceVariables", vfComponent, config)
      .then((response) => {
        console.log(response, "response");
        if (response.status === 200) {
          message.success(`Update finance variable successful `);
        }
      })
      .catch((error) => {
        message.success(`Update finance variable unsuccessful `);
        console.log(error);
      });
  };

  /**
   * Fn to call GET method to get finance variable by id
   *@method getFinanceVariableById
   *@param id type number
   */
  const getFinanceVariableById = async (id: number) => {
    axios
      .get(`admin-api/FinanceVariables/${id}`, config)
      .then((response) => {
        const _Obj: IVFComponentPayload = {
          amount: 0,
          id: 0,
          name: "",
          process: 0,
          countryId: 0,
          isPercentage: false,
          isDeductable: false,
          providerType: 0,
        };

        const {
          amount,
          name,
          process,
          isPercentage,
          isDeductable,
          id,
          countryId,
          providerType,
        }: IVFComponent = response.data;
        _Obj.id = id;
        _Obj.amount = amount;
        _Obj.name = name;
        _Obj.process = process.id;
        _Obj.isPercentage = isPercentage;
        _Obj.isDeductable = isDeductable;
        _Obj.countryId = countryId;
        _Obj.providerType = providerType ? providerType.id : null;
        setInitialFormValues(_Obj);
        fetchFormData();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handlePreviousPage = () => {
    history.goBack();
  };

  const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const _Obj = {
      id: 0,
      countryId: 0,
      amount: 0,
      process: 0,
      name: "",
      isPercentage: false,
      isDeductable: false,
      providerType: 0,
    };
    Object.keys(values).forEach((propName) => {
      switch (propName) {
        case "countryId":
          typeof values[propName] === "number" &&
            (_Obj[propName] = routeData.vfcId as number);
          break;
        case "id":
        case "amount":
        case "process":
        case "providerType":
          typeof values[propName] === "number" &&
            (_Obj[propName] = values[propName] as number);
          break;
        case "name":
          typeof values[propName] === "string" &&
            (_Obj[propName] = values[propName] as string);
          break;
        case "isPercentage":
        case "isDeductable":
          typeof values[propName] === "boolean" &&
            (_Obj[propName] = values[propName] as boolean);
          break;
        default:
      }
    });
    console.log(_Obj);
    createVFComponent(_Obj);
  };
  const handleUpdate = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    const _Obj = {
      id: 0,
      countryId: 0,
      amount: 0,
      process: 0,
      name: "",
      isPercentage: false,
      isDeductable: false,
      providerType: 0,
    };
    Object.keys(values).forEach((propName) => {
      switch (propName) {
        case "id":
        case "countryId":
        case "amount":
        case "process":
        case "providerType":
          typeof values[propName] === "number" &&
            (_Obj[propName] = values[propName] as number);
          break;
        case "name":
          typeof values[propName] === "string" &&
            (_Obj[propName] = values[propName] as string);
          break;
        case "isPercentage":
        case "isDeductable":
          typeof values[propName] === "boolean" &&
            (_Obj[propName] = values[propName] as boolean);
          break;
        default:
      }
    });
    console.log(_Obj);
    updateVFComponent(_Obj);
  };
  useEffect(() => {
    switch (routeData.type) {
      case "create":
        countries?.length === 0 ? getActiveCountires() : fetchFormData();
        break;
      case "edit":
        routeData.vfcId !== undefined && countries?.length !== 0
          ? getFinanceVariableById(routeData.vfcId)
          : getActiveCountires();
        break;
      default:
    }
  }, [countries]);

  return (
    <div className="p-8">
      <div className="flex items-center mb-10" id="title-bar">
        <ArrowLeftIcon
          className=" h-7 w-7 mr-4 cursor-pointer text-gray-900"
          onClick={handlePreviousPage}
        />
        <h1 className="mb-0">
          <span className="capitalize">{routeData.type}</span> Finance Variables
        </h1>
      </div>
      <div className="form bg-white p-4">
        <form className="space-y-4 text-gray-700" id="vfc-form">
          {form.map((elem, index) => {
            if (elem.elementType === "select") {
              return (
                <div
                  className="flex flex-wrap -mx-2 space-y-4 md:space-y-0"
                  key={index}
                >
                  <div className="w-full px-2 md:w-1/2">
                    <FormInput
                      id={elem.id}
                      label={elem.label}
                      elementType={elem.elementType}
                      elementConfig={elem.elementConfig}
                      value={
                        values[elem.elementConfig.name] !== undefined
                          ? values[elem.elementConfig.name]
                          : ""
                      }
                      handleChange={handleChange}
                      options={elem.options}
                    />
                  </div>
                </div>
              );
            } else {
              return (
                <div className="flex flex-wrap" key={index}>
                  <div className="w-full px-2 md:w-1/2">
                    <FormInput
                      id={elem.id}
                      label={elem.label}
                      elementType={elem.elementType}
                      elementConfig={elem.elementConfig}
                      value={
                        values[elem.elementConfig.name] !== undefined
                          ? values[elem.elementConfig.name]
                          : ""
                      }
                      handleChange={handleChange}
                    />
                  </div>
                </div>
              );
            }
          })}
          <div className="flex">
            {routeData.type !== undefined && routeData.type === "create" ? (
              <button onClick={(event) => handleSubmit(event)} className="btn">
                Add New
              </button>
            ) : (
              <button onClick={(event) => handleUpdate(event)} className="btn">
                Update
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
