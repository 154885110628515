import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";
import bjImage from "../../assets/images/logn-bg.jpg";
import logo from "../../assets/images/nuvad-logo.png";

export const LoginPage: React.FC = () => {
  const history = useHistory();
  const reRedirect = () => {
    history.push("/dashboard");
  };

  return (
    <Fragment>
      <div className="container mx-auto relative z-10 h-screen">
        <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full lg:w-6/12 bg-white shadow-sm rounded-sm  ">
          <div className="flex-auto p-8 text-center">
            <img src={logo} alt="nuvad logo" className="mx-auto w-36 mb-6" />
            <h1 className="dark: text-neutral-900 ">
              Admin Dashboard {process.env.REACT_APP_VERSION}
            </h1>
            <p className="dark: text-neutral-900 ">
              Login to Nuvad Admin via OpenID. Click below to login
            </p>
            <div className="my-4">
              <button className="btn" onClick={reRedirect}>
                Login OpenID
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-fixed loginBg w-full fixed top-0 left-0">
        <img
          className="object-cover object-center w-full h-full"
          src={bjImage}
          alt="cover"
        />
      </div>
    </Fragment>
  );
};
