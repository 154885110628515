import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { ArrowLeftIcon, SearchIcon } from "@heroicons/react/outline";

import axios from "../../../lib/axios/axios.nuvad";
import DatePicker from "antd/es/date-picker";
import dayjs from "dayjs";
import DynamicTable from "../../../components/organisms/DynamicTable/DynamicTable";

const { RangePicker } = DatePicker;

const oidcStorage: IOIDCStorage = JSON.parse(
  sessionStorage.getItem(
    `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
  ) || "{}"
);
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + oidcStorage.access_token,
  },
};

/**
 * PendingTopups list down all the pending top-ups from service providers
 * @category Pages
 * @component
 * @namespace PendingTopups
 * @returns PendingTopups page
 */
export const PendingTopups: React.FC = () => {
  const history = useHistory();
  const [allPendingTopups, setAllPendingTopups] = useState<ITableCell[]>();
  const topupColumns: ITableColumns[] = [
    {
      key: "rowNo",
      title: "#",
    },
    {
      key: "serviceProviderId",
      title: "ID",
    },

    {
      key: "serviceProviderName",
      title: "Service Provider",
    },
    {
      key: "serviceProviderType",
      title: "Type",
    },
    {
      key: "lastTopUpDate",
      title: "Last Top-up Date",
    },
    {
      key: "walletBalance",
      title: "Wallet Balance",
    },
    {
      key: "amount",
      title: "Amount",
    },
    {
      key: "action",
      title: "",
      buttons: [{ key: "serviceProviderId", label: "View" }],
    },
  ];
  /**
   * fn to call GET all pending top-ups
   *@method getAllPendingTopups
   */
  const getAllPendingTopups = async () => {
    await axios
      .get(`finance-api/TopUp/GetPendingTopUps`, config)
      .then((response) => {
        const _tbData: ITableCell[] = [];
        const _pendingTopups: IPendingTopup[] = response.data.serviceProviders;

        if (_pendingTopups.length !== 0) {
          //get keys from response
          const objKeys = Object.keys(_pendingTopups[0]);

          _pendingTopups.forEach((result) => {
            const _Obj: ITableCell = {};
            objKeys.forEach((propName: string) => {
              switch (propName) {
                case "serviceProviderId":
                case "serviceProviderName":
                case "serviceProviderType":
                  _Obj[propName] = result[propName];

                  break;
                case "amount":
                case "walletBalance":
                  _Obj[propName] = result[propName].toString();
                  break;
                case "lastTopUpDate":
                  _Obj[propName] = result[propName]
                    ? dayjs(result[propName]).format("DD/MM/YYYY h:mm A")
                    : "-";
                  break;
              }
            });
            _tbData.push(_Obj);
          });

          setAllPendingTopups(_tbData);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  // fn to handle routing back to previous page
  const handlePreviousPage = () => {
    history.goBack();
  };
  /**
   * fn to route to top-up form page
   *@method handleRouteTopUpForm
   *@param guid id of session as number or string
   */
  const handleRouteTopUpForm = (guid: string) => {
    let _spName = "";
    let _amount = "";
    allPendingTopups &&
      allPendingTopups.forEach((result) => {
        if (result.serviceProviderId === guid) {
          _spName = result.serviceProviderName as string;
          _amount = result.amount as string;
        }
      });

    history.push({
      pathname: "/topupForm",
      state: { spGuid: guid, spName: _spName, amount: parseFloat(_amount) },
    });
  };
  useEffect(() => {
    getAllPendingTopups();
  }, []);
  return (
    <div className="p-8">
      <div className="flex items-center mb-10" id="title-bar">
        <ArrowLeftIcon
          className=" h-7 w-7 mr-4 cursor-pointer text-gray-900"
          onClick={handlePreviousPage}
        />
        <h1 className="mb-0">All Pending Top Ups</h1>
      </div>
      <div className="flex w-full items-center mb-7" id="filter-sort-bar">
        <div className="w-full max-w-xs">
          <div className="relative text-gray-700">
            <input
              className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline fromInput"
              type="text"
              placeholder="Regular input"
            />
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
              <SearchIcon className=" h-6 w-6 " />
            </div>
          </div>
        </div>
        <div className="flex-grow flex justify-end">
          <div className="text-gray-700 md:flex md:items-center mx-2">
            <div className="mb-1 md:mb-0 pr-1">
              <label htmlFor="date-range-picker">Date Range</label>
            </div>
            <div className="md:w-1/4 md:flex-grow">
              <RangePicker
                className="border rounded-lg w-full h-10 pl-3"
                style={{ outline: "none", border: "" }}
              />
            </div>
          </div>

          <div className="text-gray-700 md:flex md:items-center mx-2">
            <div className="mb-1 md:mb-0  pr-1">
              <label htmlFor="session-type">Status</label>
            </div>
            <div className="relative md:w-1/4  md:flex-grow text-gray-700">
              <select className="w-full h-10 pl-3 pr-6 text-base placeholder-gray-600 border rounded-lg appearance-none focus:shadow-outline">
                <option>A regular sized select input</option>
                <option>Another option</option>
                <option>And one more</option>
              </select>
              <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20">
                  <path
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                    fillRule="evenodd"
                  ></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
      <DynamicTable
        tbData={allPendingTopups}
        tbColumns={topupColumns}
        id="pending-top-ups-tb"
        handlePrimaryFn={(id) => handleRouteTopUpForm(id)}
      />
    </div>
  );
};
