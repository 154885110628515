export const CONFIG = {
    authority: process.env.REACT_APP_AUTH_URL,
    client_id: process.env.REACT_APP_IDENTITY_CLIENT_ID,
    redirect_uri: `${process.env.REACT_APP_HOST_URL}/signin-oidc`,
    response_type: "id_token token",
    scope: "openid profile n_api",
    post_logout_redirect_uri: `${process.env.REACT_APP_HOST_URL}/signout-callback-oidc`,
    automaticSilentRenew: true,
    silent_redirect_uri: `${process.env.REACT_APP_HOST_URL}/silent-refresh.html`,
    includeIdTokenInSilentRenew: true,
    accessTokenExpiringNotificationTime: 300,
}
export const METADATA_OIDC = {
    issuer: "https://identityserver",
    jwks_uri: `${ process.env.REACT_APP_AUTH_URL}/.well-known/openid-configuration/jwks`,
    authorization_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/authorize",
    // token_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/token",
    // userinfo_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/userinfo",
    // end_session_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/endsession",
    // check_session_iframe: process.env.REACT_APP_AUTH_URL + "/connect/checksession",
    // revocation_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/revocation",
    // introspection_endpoint: process.env.REACT_APP_AUTH_URL + "/connect/introspect"
};