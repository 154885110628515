import { UploadFile } from "antd/es/upload";
import { useState } from "react";

export type FormState = {
  [key: string]: string | number | boolean;
};

interface IUserFormInputCb {
  uploadData: cloudUploadPayload;
  fileList: UploadFile[];
  handleFileCloudUpload: (payload: cloudUploadPayload) => void;
  handleSingleFile: (payload: cloudUploadPayload) => void;
  setFileList: React.Dispatch<React.SetStateAction<UploadFile<any>[]>>;
}
export const useFIleUpload = (): IUserFormInputCb => {
  const [uploadData, fileListsetUploadData] = useState<cloudUploadPayload>({
    id: "",
    name: "",
    url: "",
  });
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const handleFileCloudUpload = (payload: cloudUploadPayload) => {
    // setUploadData((prevState) => {
    //   return {
    //     ...prevState,
    //     id: payload.id,
    //     url: payload.url,
    //     name: payload.name,
    //   };
    // });
    setFileList([
      { uid: payload.id, name: "", status: "done", url: payload.url },
    ]);
  };
  const handleSingleFile = (payload: cloudUploadPayload) => {
    setFileList([
      { uid: payload.id, name: "", status: "done", url: payload.url },
    ]);
  };

  return {
    uploadData,
    fileList,
    handleFileCloudUpload,
    handleSingleFile,
    setFileList,
  };
};
