import React, { Fragment } from "react";

export const CountryCell = ({
  data,
  itemKey,
}: ITableCustomCell): JSX.Element => {
  return (
    <Fragment>
      <div className="flex" id={itemKey}>
        <div className="flex-shrink-0 w-12 h-12">
          <img
            className="object-cover h-12 w-full rounded-full"
            src={data.imageUrl as string}
            alt="country-flag"
          />
        </div>
        <div className="ml-3">
          <p className="text-gray-900 whitespace-no-wrap">
            {`${data.id}-${data.name}`}
          </p>
          <p className="text-gray-600 whitespace-no-wrap">{data.isoCode}</p>
        </div>
      </div>
    </Fragment>
  );
};
