import React, { useEffect, useState } from "react";
import { RouteComponentProps, useHistory } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { SummaryForm } from "../../components";
import { getStatusColor, handleTransactionSummary } from "../../utils";

import axios from "../../lib/axios/axios.nuvad";

interface IMatchParams {
  id: string;
}
const oidcStorage: IOIDCStorage = JSON.parse(
  sessionStorage.getItem(
    `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
  ) || "{}"
);
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + oidcStorage.access_token,
  },
};

export const TransactionSummary: React.FC<
  RouteComponentProps<IMatchParams>
> = ({ match }: RouteComponentProps<IMatchParams>) => {
  const { id } = match.params;
  const history = useHistory();
  const [transactionStatus, setTransactionStatus] = useState<string>("");
  const [summarySections, setSummarySections] = useState<ISummarySection[]>([]);

  /**
   * Fn to get transaction summary by id
   *@method getTransactionSummaryById
   */
  const getTransactionSummaryById = async () => {
    await axios
      .get(`finance-api/Payments/GetPaymentSummary/${id}`, config)
      .then((response) => {
        const _data: ITransaction = response.data.summary;
        let _summarySection: ISummarySection[] = [];
        console.log(_data);
        if (_data) {
          _summarySection = handleTransactionSummary(_data);
          setTransactionStatus(_data.status);
          setSummarySections(_summarySection);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Fn to handle route to previous page
  const handlePreviousPage = () => {
    history.goBack();
  };

  useEffect(() => {
    console.log(id);
    getTransactionSummaryById();
  }, [id]);
  return (
    <div className="p-8">
      <div className="flex items-center mb-10" id="title-bar">
        <ArrowLeftIcon
          className=" h-7 w-7 mr-4 cursor-pointer"
          onClick={handlePreviousPage}
        />
        <h1 className="mb-0">Transaction Summary</h1>
        <span
          className={`px-3 py-1 font-semibold  leading-tight rounded-full capitalize ${getStatusColor(
            transactionStatus
          )} ml-2`}
        >
          <span className="text-white text-sm">{transactionStatus}</span>
        </span>
      </div>
      <div className="" id="summary-wrapper">
        <SummaryForm summaryData={summarySections} />
      </div>
    </div>
  );
};
