import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";

import { TableCustomCell } from ".";

import axios from "../../../lib/axios/axios.nuvad";
import MasterDataContext from "../../../context/Masterdata/MasterDataContext";
import message from "antd/es/message";
import { getRateType } from "../../../utils/serviceProviderUtils";
import SearchIcon from "@heroicons/react/outline/SearchIcon";
import { useDebounce } from "../../../hooks/useDebounce";

import Table from "antd/es/table/Table";
import { ColumnsType } from "antd/es/table";
import ActionCell from "../../../templates/CustomCells/ActionCell";
import Modal from "antd/es/modal/Modal";
import ConfirmationTpl from "../../../templates/Modal/ConfirmationTpl";
import ErrorContext from "../../../features/errorhandling/context/errorContext";
import { getErrorObj } from "../../../utils/errorHandlingUtils";

interface ISPIConfigTabProps {
  tbData: ITableCell[] | undefined;
}

interface IServicesGetInput {
  servicesCurrentPage: number;
  servicesPageLimit: number;
  debouncedSearchTerm: string | number;
}

export const SPIndividualsConfigTab: React.FC<ISPIConfigTabProps> = ({
  tbData,
}: ISPIConfigTabProps) => {
  const history = useHistory();
  //Context
  const { SPIndividualTypes, getBusinessTypes } = useContext(MasterDataContext);
  const { setError } = useContext(ErrorContext);
  //States
  const [individualServices, setIndividualServices] = useState<ITableCell[]>();
  const [servicesCurrentPage, setServicesCurrentPage] = useState<number>(1);
  const [servicesPageLimit, setServicesPageLimit] = useState<number>(10);
  const [servicesTotalPages, setServicesTotalPages] = useState<number>(0);
  const [servicesType, setServicesType] = useState<string>("");
  const [showBusinessTypeModal, setShowBusinessTypeModal] =
    useState<boolean>(false);
  const [showIndividualServiceModal, setShowIndividualServiceModal] =
    useState<boolean>(false);

  const currentSelectedId = useRef<number | undefined>(undefined);

  //Custom hooks
  const debouncedSearchTerm = useDebounce(servicesType, 700);

  const memoizedInput: IServicesGetInput = useMemo(() => {
    return {
      servicesCurrentPage,
      servicesPageLimit,
      debouncedSearchTerm,
    };
  }, [servicesCurrentPage, servicesPageLimit, debouncedSearchTerm]);

  const businessTypeColumns: ColumnsType<Record<string, any>> = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "type",
      title: "Type",
      dataIndex: "type",
      render: (_, record) => <TableCustomCell data={record} itemKey={`type`} />,
    },
    {
      key: "providerType",
      title: "Provider Type",
      dataIndex: "providerType",
    },

    {
      key: "action",
      title: "Action",
      render: (_, record) => (
        <ActionCell
          data={record}
          actions={[
            { key: "id", label: "Edit" },
            { key: "id", label: "Delete" },
          ]}
          handlePrimaryFn={handleEditBusinessTypes}
          handleSecondaryFn={handleDeleteBusinessType}
        />
      ),
    },
  ];
  const servicesColumns: ColumnsType<Record<string, any>> = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "type",
      title: "Type",
      dataIndex: "type",
    },
    {
      key: "businessTypeId",
      title: "Business Type",
      dataIndex: "businessTypeId",
    },
    {
      key: "rateType",
      title: "Rate Type",
      dataIndex: "rateType",
    },
    {
      key: "action",
      title: "Action",
      render: (_, record) => (
        <ActionCell
          data={record}
          actions={[
            { key: "id", label: "Edit" },
            { key: "id", label: "Delete" },
          ]}
          handlePrimaryFn={handleEditService}
          handleSecondaryFn={handleDeleteService}
        />
      ),
    },
  ];

  /**
   * Fn to call GET individual services
   *@method getIndividualServiceTypes
   *@param businessTypes array of business types to match id and get business type name
   */
  const getIndividualServiceTypes = (memoizedInput: IServicesGetInput) => {
    axios
      .get(`/admin-api/IndividualServiceTypes/GetByIndividualType`, {
        params: {
          page: memoizedInput.servicesCurrentPage,
          limit: memoizedInput.servicesPageLimit,
          serviceType: memoizedInput.debouncedSearchTerm,
        },
      })
      .then((response) => {
        const _tempRow: ITableCell[] = [];

        const _services: IIndividualServiceType[] =
          response.data.serviceTypes.items;

        if (_services.length !== 0) {
          //get keys from response

          const objKeys = Object.keys(_services[0]);
          _services.forEach((service, index) => {
            const _Obj: ITableCell = { key: index.toString() };
            objKeys.forEach((colName: string) => {
              switch (colName) {
                case "type":
                  _Obj[colName] = service[colName];
                  break;
                case "rateType":
                  _Obj[colName] = getRateType(service[colName]);
                  break;
                case "id":
                  _Obj[colName] = service[colName].toString();
                  break;
                case "businessTypeId":
                  const _business = SPIndividualTypes.find(
                    (business) => business.id === service[colName]
                  );

                  _Obj[colName] = _business
                    ? _business.type
                    : "invalid business type";
                  break;
              }
            });
            _tempRow.push(_Obj);
          });
        }
        setServicesTotalPages(response.data.serviceTypes.totalPages);
        setServicesCurrentPage(response.data.serviceTypes.pageNumber);
        setIndividualServices(_tempRow);
      })
      .catch((error) => {
        const _errorObj = getErrorObj(error.response);
        setError(_errorObj);
      });
  };
  /**
   * Fn to route to new SP business type form page
   *@method addNewHandler
   */
  const addNewHandler = () => {
    history.push({
      pathname: "/businesstypeform",
      state: {
        type: "add",
      },
    });
  };
  /**
   * Fn to route to new SPI service page
   *@method addServiceHandler
   */
  const addServiceHandler = () => {
    history.push({
      pathname: "/practitionerServiceForm",
      state: {
        type: "add",
      },
    });
  };
  /**
   * Fn to handle drop down primary button click
   *@method handleViewBusinessType
   *@param id id of the row clicked
   */
  const handleViewBusinessType = (id: string) => {
    history.push({
      pathname: "/businesstypeform",
      state: {
        type: "view",
        businessTypeId: parseInt(id),
      },
    });
  };
  /**
   * Fn to handle drop down primary button click of SPI services table
   *@method handleViewService
   *@param id id of the row clicked
   */
  const handleViewService = (id: string) => {
    history.push({
      pathname: "/countryform",
      state: {
        type: "view",
        countryId: parseInt(id),
      },
    });
  };
  /**
   * Fn to handle drop down secondary button click of SPI services table
   *@method handleEditService
   *@param id id of the row clicked
   */
  const handleEditService = (id: string) => {
    history.push({
      pathname: "/practitionerServiceForm",
      state: {
        type: "edit",
        serviceTypeId: parseInt(id),
      },
    });
  };
  /**
   * Fn to handle drop down secondary button click of SPI services table
   *@method handleEditBusinessTypes
   *@param id id of the row clicked
   */
  const handleEditBusinessTypes = (id: string) => {
    history.push({
      pathname: "/businesstypeform",
      state: {
        type: "edit",
        businessTypeId: parseInt(id),
      },
    });
  };
  /**
   * Fn to handle drop down tertiary button click
   *@method handleDeleteBusinessType
   *@param id id of the row clicked
   */
  const handleDeleteBusinessType = (id: string) => {
    setShowBusinessTypeModal(true);
    currentSelectedId.current = parseInt(id);
  };
  /**
   * Fn to handle drop down tertiary button click of SPI services table
   *@method handleDeleteService
   *@param id id of the row clicked
   */
  const handleDeleteService = (id: string) => {
    setShowIndividualServiceModal(true);
    currentSelectedId.current = parseInt(id);
  };
  /**
   * Fn to call DELETE endpoint to delete selected business type
   *@method deleteBusinessType
   *@param id id of the row clicked
   */
  const deleteBusinessType = (id: number | undefined) => {
    axios
      .delete(`/admin-api/BusinessTypes/${id}`)
      .then((response) => {
        if (response.status === 200) {
          message.success(`Delete business type successful `);
          getBusinessTypes(1);
          setShowBusinessTypeModal(false);
        }
      })
      .catch((error) => {
        const _errorObj = getErrorObj(error.response);
        setError(_errorObj);
      });
  };
  /**
   * Fn to call DELETE endpoint to delete selected individual service
   *@method deleteIndividualService
   *@param id id of the row clicked
   */
  const deleteIndividualService = (id: number | undefined) => {
    axios
      .delete(`/admin-api/IndividualServiceTypes/${id}`)
      .then((response) => {
        if (response.status === 200) {
          message.success(`Delete individual service type successful `);

          setShowIndividualServiceModal(false);
        }
      })
      .catch((error) => {
        const _errorObj = getErrorObj(error.response);
        setError(_errorObj);
      });
  };
  /**
   * Fn to handle next page in table
   *@method handleNextPage
   */
  const handleNextPage = () => {
    if (servicesCurrentPage < servicesTotalPages) {
      setServicesCurrentPage(servicesCurrentPage + 1);
    }
  };
  /**
   * Fn to handle previous page in table
   *@method handlePrevPage
   */
  const handlePrevPage = () => {
    if (servicesCurrentPage <= servicesTotalPages) {
      setServicesCurrentPage(servicesCurrentPage - 1);
    }
  };
  /**
   * Fn to handle page limit in table
   *@method handlePageLimit
   */
  const handlePageLimit = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.persist();
    let { value } = e.target;

    setServicesPageLimit(parseInt(value));
  };
  /**
   * Fn to handle page number
   *@method handlePageNumber
   */
  const handlePageNumber = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.persist();
    let { value } = e.target;

    setServicesCurrentPage(parseInt(value));
  };

  const handleSearchInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.target;

    setServicesType(value);
  };

  const getDataCallBack = useCallback(() => {
    return getIndividualServiceTypes(memoizedInput);
  }, [memoizedInput]);

  useEffect(() => {
    getDataCallBack();
  }, [getDataCallBack]);

  return (
    <>
      <div className="flex items-center mb-2" id="table-toolbar">
        <h2 className="flex-none dark:text-slate-900">Individual Types</h2>
        <div className="flex flex-grow justify-end">
          <button onClick={addNewHandler} className="btn-sm mr-2">
            Add New
          </button>
        </div>
      </div>
      <Table dataSource={tbData} columns={businessTypeColumns} />

      <div className="flex items-center mb-2" id="table-toolbar">
        <h2 className="flex-none dark:text-slate-900">Individual Services</h2>
      </div>
      <div className="flex w-full items-center  mt-3 mb-2" id="filter-sort-bar">
        <div className="w-full max-w-xs">
          <div className="relative text-gray-700">
            <input
              className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline fromInput"
              type="text"
              placeholder="Search by type"
              onChange={handleSearchInput}
              value={servicesType}
            />
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
              <SearchIcon className=" h-6 w-6 " />
            </div>
          </div>
        </div>
        <div className="flex flex-grow justify-end">
          <button onClick={addServiceHandler} className="btn-sm mr-2">
            Add New
          </button>
        </div>
      </div>
      <Table dataSource={individualServices} columns={servicesColumns} />

      <Modal
        title="Delete"
        open={showBusinessTypeModal}
        onOk={() => deleteBusinessType(currentSelectedId.current)}
        onCancel={() => setShowBusinessTypeModal(false)}
      >
        <ConfirmationTpl
          title="Delete Business Type"
          message="Are you sure you want to delete this individual business type?"
        />
      </Modal>

      <Modal
        title="Delete"
        open={showIndividualServiceModal}
        onOk={() => deleteIndividualService(currentSelectedId.current)}
        onCancel={() => setShowIndividualServiceModal(false)}
      >
        <ConfirmationTpl
          title="Delete Individual Type"
          message="Are you sure you want to delete this individual service type?"
        />
      </Modal>
    </>
  );
};
