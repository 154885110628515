export const PAYOUTS = {
  createPayoutForm: {
    countryId: {
      elementType: "select",
      label: "Country",
      elementConfig: {
        name: "countryId",
      },
      value: "",
      options: [],
    },
    // paymentMethod: {
    //   elementType: "select",
    //   label: "Payment Method",
    //   elementConfig: {
    //     name: "paymentMethod",
    //   },
    //   value: "",
    //   options: [],
    // },
    // payoutBufferDays: {
    //   elementType: "input",
    //   label: "Payout Buffer Days",
    //   elementConfig: {
    //     type: "text",
    //     placeholder: "Enter buffer days",
    //     name: "payoutBufferDays",
    //   },
    //   value: "",
    // },
    // payoutStartDate: {
    //   elementType: "date",
    //   label: "Payout Start Date",
    //   elementConfig: {
    //     type: "date",
    //     name: "payoutStartDate",
    //   },
    //   value: "",
    // },
  },
};
