import CogIcon from "@heroicons/react/outline/CogIcon";
import GiftIcon from "@heroicons/react/outline/GiftIcon";
import HomeIcon from "@heroicons/react/outline/HomeIcon";
import OfficeBuildingIcon from "@heroicons/react/outline/OfficeBuildingIcon";
import UsersIcon from "@heroicons/react/outline/UsersIcon";
import { MenuProps } from "antd/es/menu/menu";
import MenuItem from "antd/es/menu/MenuItem";

type MenuItem = Required<MenuProps>["items"][number];
const getItem = (
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem => {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
};

export const sideMenuItems: MenuProps["items"] = [
  getItem("Dashboard", "/dashboard", <HomeIcon className="h-6 w-6 mr-2" />),
  getItem(
    "Practitioners",
    "/practitioners",
    <UsersIcon className="h-6 w-6 mr-2" />
  ),
  getItem(
    "SP Business",
    "/spbusiness",
    <OfficeBuildingIcon className="h-6 w-6 mr-2" />
  ),
  getItem("Master Data", "/masterdata", <CogIcon className="h-6 w-6 mr-2" />),
  getItem("Promotions", "/promotions", <GiftIcon className="h-6 w-6 mr-2" />),
];
