import { Fragment } from "react";
import { CountryCell, StatusCell, ThumbnailCell } from "../../../templates";
export const TableCustomCell = ({
  data,
  itemKey,
}: ITableCustomCell): JSX.Element => {
  const renderCustomCell = (cellKey: string): JSX.Element => {
    let _tempCell = <div></div>;
    switch (cellKey) {
      case "country":
        _tempCell = <CountryCell data={data} itemKey={itemKey} />;
        break;
      case "status":
        _tempCell = <StatusCell data={data} itemKey={itemKey} />;
        break;
      case "type":
        _tempCell = (
          <ThumbnailCell
            data={data}
            itemKey={itemKey}
            type={data.providerType === "Business" ? "contain" : "cover"}
          />
        );
        break;
    }
    return _tempCell;
  };

  return <Fragment>{renderCustomCell(itemKey)}</Fragment>;
};
