/**
 * Function to set status color
 *@method getStatusColor
 *@param status
 *@returns string style class
 */

export const getStatusColor = (status: string | boolean): string => {
  let _tempColor = "#6b7280";
  if (typeof status === "string") {
    switch (status.toLowerCase()) {
      case "active":
      case "verified":
      case "completed":
      case "succeeded":
        _tempColor = "#4ade80";
        break;
      case "new":
      case "confirmed":
        _tempColor = "#3b82f6";
        break;
      case "pending from author":
      case "pending":
        _tempColor = "#eab308";
        break;
    }
  } else {
    _tempColor = status ? "#4ade80" : "#6b7280";
  }

  return _tempColor;
};
