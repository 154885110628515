import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

import { ArrowLeftIcon, SearchIcon } from "@heroicons/react/outline";
import axios from "../../lib/axios/axios.nuvad";
import DatePicker from "antd/es/date-picker";
import dayjs from "dayjs";
import { ColumnsType } from "antd/es/table/interface";
import Table from "antd/es/table/Table";

const { RangePicker } = DatePicker;

interface IAllSessionsRouteProps {
  type: string;
}
const oidcStorage: IOIDCStorage = JSON.parse(
  sessionStorage.getItem(
    `oidc.user:${process.env.REACT_APP_AUTH_URL}:${process.env.REACT_APP_IDENTITY_CLIENT_ID}`
  ) || "{}"
);
const config = {
  headers: {
    "Content-Type": "application/json",
    Authorization: "Bearer " + oidcStorage.access_token,
  },
};
/**
 * AllSessions list down all the outcall and incall sessions
 * @category Pages
 * @component
 * @namespace AllSessions
 * @returns AllSessions page
 */
export const AllSessions: React.FC = () => {
  const history = useHistory();
  const { state: routeData } = useLocation<IAllSessionsRouteProps>();
  const [allSessions, setAllSessions] = useState<ITableCell[]>();
  const [sessionType, setSessionType] = useState<string>("outcall");

  const sortDate = (a: string, b: string): number => {
    const _a = dayjs(a, "DD/MM/YYYY h:mm A");
    const _b = dayjs(b, "DD/MM/YYYY h:mm A");
    const duration = _a.diff(_b, "second");
    // console.log(duration);
    return duration;
  };
  const columns: ColumnsType<Record<string, any>> = [
    {
      key: "1",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "2",
      title: "Payment Method",
      dataIndex: "paymentMethod",
      filters: [
        { text: "Cash", value: "Cash" },
        { text: "Card", value: "Card" },
      ],
      onFilter: (value, record) => record.paymentMethod.indexOf(value) === 0,
    },
    {
      key: "3",
      title: "Amount",
      dataIndex: "totalSessionPrice",
    },
    {
      key: "4",
      title: "Created Date",
      dataIndex: "createdDateTime",
      defaultSortOrder: "descend",
      sorter: (a, b) => sortDate(a.createdDateTime, b.createdDateTime),
    },
    {
      key: "5",
      title: "Status",
      dataIndex: "status",
      filters: [
        { text: "Completed", value: "Completed" },
        { text: "Pending", value: "Pending" },
        { text: "Cancelled", value: "Cancelled" },
        { text: "Rejected", value: "Rejected" },
      ],
      onFilter: (value, record) => record.status.indexOf(value) === 0,
    },
  ];
  /**
   * fn to call GET all sessions
   *@method getSessions
   *@param type type of session
   */
  const getSessions = async (type: string) => {
    await axios
      .get(
        `session-api/${
          type === "outcall"
            ? "OutCallSessions/GetAllOutcallSessions"
            : "InCallSessions/GetAllIncallSessions"
        }`,
        config
      )
      .then((response) => {
        const _data: IDailySessions[] = response.data;

        const _tempOutcallSessions: ITableCell[] = [];
        if (_data.length !== 0) {
          //get keys from response
          const objKeys = _data && Object.keys(_data[0]);

          //Build final array to bind to table
          _data.forEach((session, sessionIndex) => {
            const _Obj: ITableCell = { key: session.id.toString() };
            objKeys.forEach((colName: string) => {
              switch (colName) {
                case "id":
                case "paymentMethod":
                case "status":
                  _Obj[colName] = session[colName];
                  break;
                case "totalSessionPrice":
                  _Obj[colName] =
                    session[colName] !== null
                      ? session[colName].toString()
                      : "error";
                  break;
                case "createdDateTime":
                  _Obj[colName] = dayjs(session[colName]).format(
                    "DD/MM/YYYY h:mm A"
                  );
                  break;
              }
              _Obj.key = sessionIndex.toString();
            });

            _tempOutcallSessions.push(_Obj);
          });

          setAllSessions(_tempOutcallSessions);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /**
   * fn to handle table's primary button
   *@method handleViewSummary
   *@param id id of session as number or string
   */
  const handleViewSummary = (id: number | string) => {
    history.push({
      pathname: `/sessionsummary/${id}/${sessionType}`,
    });
  };
  // fn to handle routing back to previous page
  const handlePreviousPage = () => {
    history.goBack();
  };
  useEffect(() => {
    if (routeData) {
      switch (routeData.type) {
        case "outcall":
          setSessionType(routeData.type);
          getSessions(routeData.type);
          break;
        case "incall":
          setSessionType(routeData.type);
          getSessions(routeData.type);
          break;
        default:
      }
    } else {
      setSessionType("outcall");
    }
  }, [routeData]);

  return (
    <div className="p-8">
      <div className="flex items-center mb-10" id="title-bar">
        <ArrowLeftIcon
          className=" h-7 w-7 mr-4 cursor-pointer text-gray-900"
          onClick={handlePreviousPage}
        />
        <h1 className="mb-0">
          All <span className="capitalize">{sessionType}</span> Sessions
        </h1>
      </div>
      <div className="flex w-full items-center mb-7" id="filter-sort-bar">
        <div className="w-full max-w-xs">
          <div className="relative text-gray-700">
            <input
              className="w-full h-10 pl-3 pr-8 text-base placeholder-gray-600 border rounded-lg focus:shadow-outline fromInput"
              type="text"
              placeholder="Regular input"
            />
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
              <SearchIcon className=" h-6 w-6 " />
            </div>
          </div>
        </div>
        <div className="flex-grow flex justify-end">
          <div className="text-gray-700 md:flex md:items-center mx-2">
            <div className="mb-1 md:mb-0 pr-1">
              <label htmlFor="date-range-picker" className="dark: text-white">
                Date Range
              </label>
            </div>
            <div className="md:w-1/4 md:flex-grow">
              <RangePicker
                className="border rounded-lg w-full h-10 pl-3"
                style={{ outline: "none", border: "" }}
              />
            </div>
          </div>
        </div>
      </div>
      <Table
        dataSource={allSessions}
        columns={columns}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => handleViewSummary(record.id), // click row
          };
        }}
      />
      ;
    </div>
  );
};
