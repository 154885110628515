import { Fragment } from "react";

export const UserCell = ({ data, itemKey }: ITableCustomCell): JSX.Element => {
  return (
    <Fragment>
      <div className="flex">
        <div className="flex-shrink-0 w-12 h-12">
          <img
            className="object-cover h-12 w-full rounded-full"
            src={data.profilePictureUrl as string}
            alt="user-profile"
          />
        </div>
        <div className="ml-3">
          <p className="text-gray-900 whitespace-no-wrap text-transform: capitalize">
            {data.name}
          </p>
          <p className="text-gray-600 whitespace-no-wrap">{data.mobile}</p>
        </div>
      </div>
    </Fragment>
  );
};
