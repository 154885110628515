import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { MASTER_FORM_CONFIG } from "../../constants/appConfigForms";
import { EditableTable } from "../../components";
import { buildEditable, getDropDownOptions } from "../../utils";

import AppContext from "../../context/App/AppContext";
import MasterDataContext from "../../context/Masterdata/MasterDataContext";
import axios from "../../lib/axios/axios.nuvad";
import message from "antd/es/message";
import Card from "antd/es/card";
import Input from "antd/es/input";
import Select from "antd/es/select";
import Button from "antd/es/button";
import {
  generateCountriesOptions,
  generateSelectOptions,
  getCountryName,
} from "../../utils/formUtils";
import { RateType } from "../../constants/enums/masterData";
import Switch from "antd/es/switch";
import Table from "antd/es/table";
import EditableCell from "./components/EditableCell";
import Typography from "antd/es/typography";
import Popconfirm from "antd/es/popconfirm";
import { StatusCell } from "../../templates";
import Measure from "../../components/atoms/Measure/Measure";
import { Form } from "antd";
import ErrorContext from "../errorhandling/context/errorContext";
import { getErrorObj } from "../../utils/errorHandlingUtils";

interface IIndividualServiceFormRouteProps {
  type: string;
  serviceTypeId?: number;
}
interface IPractitionerForm {
  type: string;
  businessTypeId: number;
  rateType: number;
  availablePressureType: boolean;
  availableGender: boolean;
  availableBookingType: boolean;
  availablePatientCondition: boolean;
  availableTag: boolean;
}

const initialValues = {
  id: 0,
  businessTypeId: null,
  type: "",
  rateType: null,
  availablePressureType: false,
  availableGender: false,
  availableBookingType: false,
  availablePatientCondition: false,
  availableTag: false,
};

const rateOptions = [
  { value: RateType.flat, name: "Flat" },
  { value: RateType.hourly, name: "Hourly" },
  { value: RateType.daily, name: "Daily" },
  { value: RateType.weekly, name: "Weekly" },
];
interface DataType extends CountryRate {
  key: React.Key;
}

/**
 * Individual service form page to and and edit services of individual practitioners
 * @category Forms
 * @component
 * @namespace PractitionerServiceForm
 * @returns PractitionerServiceForm component form page
 */

const PractitionerServiceForm: React.FC = () => {
  const history = useHistory();
  const { state: routeData } = useLocation<IIndividualServiceFormRouteProps>();
  const { countries, getActiveCountires, triggerNotification } =
    useContext(AppContext);
  const { setError } = useContext(ErrorContext);
  const { SPIndividualTypes, getBusinessTypes } = useContext(MasterDataContext);

  const [selectedCountry, setSelectedCountry] = useState<number>();
  const [dataSource, setDataSource] = useState<DataType[]>([]);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editingKey, setEditingKey] = useState<React.Key>("");
  // Custom hooks
  const [form] = Form.useForm();
  const [ratesform] = Form.useForm();

  const before = process.env.NODE_ENV === "development" ? performance.now() : 0;

  const isEditing = (record: DataType) => record.key === editingKey;

  const tbComponents = {
    body: {
      cell: EditableCell,
    },
  };
  /**
   * Function to edit in table
   *@method edit
   *@param record
   */
  const edit = (record: Partial<DataType> & { key: React.Key }) => {
    console.log("edit", record);
    ratesform.setFieldsValue({
      id: 0,
      serviceTypeId: 0,
      countryId: 0,
      rate: 0,
      active: false,
      ...record,
    });
    setEditingKey(record.key);
  };
  /**
   * Function to save in table
   *@method save
   *@param save
   */
  const save = async (key: React.Key) => {
    try {
      const row = (await ratesform.validateFields()) as DataType;

      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);

      if (index > -1) {
        const item = newData[index];
        newData.splice(index, 1, {
          ...item,
          ...row,
        });

        setDataSource(newData);
        setEditingKey("");
      } else {
        newData.push(row);
        setDataSource(newData);
        setEditingKey("");
      }
    } catch (errInfo) {
      console.log("Validate Failed:", errInfo);
    }
  };

  /**
   * Function to cancel in table
   *@method cancel
   */
  const cancel = () => {
    setEditingKey("");
  };
  //keys to build editable table
  const columns = [
    {
      title: "Country",
      dataIndex: "countryId",
      editable: true,
      type: "select",
      render: (_: any, record: DataType) => (
        <p>{getCountryName(record.countryId, countries)}</p>
      ),
    },
    {
      title: "Rate",
      dataIndex: "rate",
      type: "number",
      editable: true,
    },
    {
      title: "Active",
      dataIndex: "active",
      editable: true,
      type: "checkbox",
      render: (_: any, record: DataType) => (
        <StatusCell
          data={{ status: record.active }}
          itemKey={record.key.toString()}
        />
      ),
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: DataType) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{ marginRight: 8 }}
            >
              Save
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <a>Cancel</a>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ""}
            onClick={() => edit(record)}
          >
            Edit
          </Typography.Link>
        );
      },
    },
  ];
  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record: DataType) => ({
        record,
        inputType: col.type ? col.type : "text",
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
        options:
          col.dataIndex === "countryId"
            ? generateCountriesOptions(countries ? countries : [])
            : undefined,
      }),
    };
  });

  /**
   * Fn to call PUT to update individual service
   *@method updateIndividualService
   *@param servicePayload
   */
  const updateIndividualService = (
    servicePayload: IIndividualServicePayload
  ) => {
    axios
      .put("/admin-api/IndividualServiceTypes", servicePayload)
      .then((response) => {
        setIsLoading(false);
        triggerNotification({
          type: "success",
          title: "Edit Service",
          message: <p>{form.getFieldValue("type")} edited successfully.</p>,
        });
      })
      .catch((error) => {
        setIsLoading(false);
        const _errorObj = getErrorObj(error.response);
        setError(_errorObj);
      });
  };

  /**
   * Fn to call POST endpoint to add new service
   *@method addIndividualServiceType
   *@param servicePayload
   */
  const addIndividualServiceType = async (
    servicePayload: IIndividualServicePayload
  ) => {
    axios
      .post("/admin-api/IndividualServiceTypes", servicePayload)
      .then((response) => {
        setIsLoading(false);
        triggerNotification({
          type: "success",
          title: "Add Service",
          message: <p>{form.getFieldValue("type")} added successfully.</p>,
        });
      })
      .catch((error) => {
        setIsLoading(false);
        const _errorObj = getErrorObj(error.response);
        setError(_errorObj);
      });
  };

  /**
   * Fn to generate bank form's state
   *@method fetchFormData
   */
  // const fetchFormData = async () => {
  //   const _form: IIndividualServiceFormConfig =
  //     await MASTER_FORM_CONFIG.individualServiceForm; //await statement
  //   const formElementsArray: IFormInput[] = [];
  //   Object.keys(_form).forEach((elm) => {
  //     switch (elm) {
  //       case "type":
  //         formElementsArray.push({
  //           id: elm,
  //           label: _form[elm].label,
  //           elementType: _form[elm].elementType,
  //           elementConfig: _form[elm].elementConfig,
  //           value: _form[elm].value !== undefined ? _form[elm].value : "",
  //         });
  //         break;
  //       case "availablePressureType":
  //       case "availableGender":
  //       case "availableBookingType":
  //       case "availablePatientCondition":
  //       case "availableTag":
  //         formElementsArray.push({
  //           id: elm,
  //           label: _form[elm].label,
  //           elementType: _form[elm].elementType,
  //           elementConfig: _form[elm].elementConfig,
  //           value: _form[elm].checked !== undefined ? _form[elm].checked : "",
  //         });
  //         break;
  //       case "businessTypeId":
  //         formElementsArray.push({
  //           id: elm,
  //           label: _form[elm].label,
  //           elementType: _form[elm].elementType,
  //           elementConfig: _form[elm].elementConfig,
  //           value: _form[elm].value !== undefined ? _form[elm].value : "",
  //           options:
  //             SPIndividualTypes.length !== 0
  //               ? getDropDownOptions(
  //                   SPIndividualTypes as unknown as ElementConfig[],
  //                   "type"
  //                 )
  //               : [],
  //         });

  //         break;
  //       case "rateType":
  //         formElementsArray.push({
  //           id: elm,
  //           label: _form[elm].label,
  //           elementType: _form[elm].elementType,
  //           elementConfig: _form[elm].elementConfig,
  //           value: _form[elm].value !== undefined ? _form[elm].value : "",
  //           options: _form[elm].options,
  //         });
  //         break;
  //     }
  //   });

  //   // setForm(formElementsArray);
  // };

  /**
   * Fn to get individual service by id
   *@method getIndividualServiceById
   *@param id service type id
   */
  const getIndividualServiceById = (id: number) => {
    axios
      .get(`/admin-api/IndividualServiceTypes/${id}`)
      .then((response) => {
        const {
          id,
          businessTypeId,
          type,
          rateType,
          availablePressureType,
          availableGender,
          availableBookingType,
          availablePatientCondition,
          availableTag,
          rates,
        }: IIndividualServicePayload = response.data;

        const _service: IIndividualServiceType = {
          id: id,
          businessTypeId: businessTypeId,
          type: type,
          rateType: rateType,
          availablePressureType: availablePressureType,
          availableGender: availableGender,
          availableBookingType: availableBookingType,
          availablePatientCondition: availablePatientCondition,
          availableTag: availableTag,
        };

        const _rates = rates.map((obj: CountryRate) => ({
          ...obj,
          key: `${obj.id}`,
        }));
        form.setFieldsValue(_service);
        setDataSource(_rates);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   * Fn to handle form submit
   *@method handleSubmit
   */
  const handleSubmit = async () => {
    try {
      await form.validateFields();
      const _rates = dataSource.map(({ key, ...rate }) => rate);

      const _Obj: IIndividualServicePayload = {
        id: routeData.serviceTypeId !== undefined ? routeData.serviceTypeId : 0,
        rates: _rates,
        ...form.getFieldsValue(),
      };

      setIsLoading(true);

      if (routeData.type === "edit") {
        updateIndividualService(_Obj);
      } else {
        addIndividualServiceType(_Obj);
      }
    } catch (errorInfo) {
      console.log("Failed:", errorInfo);
    }
  };

  /**
   * Function to handle selected country
   *@method handleSelectedCountry
   *@param event
   */
  const handleSelectedCountry = (value: string) => {
    setSelectedCountry(parseInt(value));
  };
  /**
   * Fn to add new country rate
   *@method handleCountryRate
   */
  const handleCountryRate = () => {
    const newData: DataType = {
      key: `${dataSource.length}-${
        selectedCountry !== undefined ? selectedCountry : 0
      }`,
      id: 0,
      serviceTypeId: routeData.type === "edit" ? form.getFieldValue("id") : 0,
      countryId: selectedCountry !== undefined ? selectedCountry : 0,
      rate: 0,
      active: false,
    };
    setDataSource([...dataSource, newData]);
    // const _tbRows: IEditableCell[][] = [...tbRows];
    // const _tempRow: IEditableCell[] = [];
    // get index to set rowId
    // const index = _tbRows.length !== 0 ? _tbRows.length : _tbRows.length;
    // Object.entries(_countryRate).forEach((item) => {
    //   const _tbCell: IEditableCell = {
    //     id: "",
    //     value: "",
    //     isEditable: false,
    //     inputType: "input",
    //     inputConfig: {
    //       name: "",
    //       type: "input",
    //     },
    //     rowId: index,
    //   };
    //   switch (item[0]) {
    //     case "countryId":
    //       _tbCell.id = item[0];
    //       _tbCell.value =
    //         selectedCountry !== undefined ? selectedCountry.toString() : "";
    //       _tbCell.inputType = "select";
    //       _tbCell.options = getDropDownOptions(
    //         countries as unknown as ElementConfig[],
    //         "name"
    //       );
    //       _tbCell.inputConfig = {
    //         name: item[0],
    //         placeholder: "Select country",
    //         type: "select",
    //       };
    //       _tbCell.isEditable = true;
    //       _tempRow.push(_tbCell);
    //       break;
    //     case "serviceTypeId":
    //       _tbCell.id = item[0];
    //       _tbCell.value =
    //         routeData.type === "edit" ? values.id.toString() : "0";
    //       _tempRow.push(_tbCell);
    //       break;
    //     case "active":
    //       _tbCell.id = item[0];
    //       _tbCell.value = item[1].toString();
    //       _tbCell.inputType = "checkbox";
    //       _tbCell.inputConfig = {
    //         name: item[0],
    //         placeholder: "",
    //         type: "checkbox",
    //       };
    //       _tbCell.isEditable = true;
    //       _tempRow.push(_tbCell);
    //       break;
    //     case "rate":
    //       _tbCell.id = item[0];
    //       _tbCell.value = item[1].toString();
    //       _tbCell.isEditable = true;
    //       _tbCell.inputConfig = {
    //         name: "",
    //         placeholder: "Enter rate",
    //         type: "input",
    //       };
    //       _tbCell.inputType = "input";
    //       _tempRow.push(_tbCell);
    //       break;
    //     default:
    //       _tbCell.id = item[0];
    //       _tbCell.value = item[1].toString();
    //       _tempRow.push(_tbCell);
    //   }
    // });

    // _tbRows[0] && _tbRows[0].length === 0
    //   ? (_tbRows[0] = _tempRow)
    //   : _tbRows.push(_tempRow);

    // setTbRows(_tbRows);
  };
  /**
   * Function to go back to previous page
   *@method handlePreviousPage
   */
  const handlePreviousPage = () => {
    history.goBack();
  };
  /**
   * Function to handle form fail
   *@method onFinishFailed
   */
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };
  useEffect(() => {
    if (countries?.length === 0) getActiveCountires();
    if (SPIndividualTypes.length === 0) getBusinessTypes(1);
  }, []);
  useEffect(() => {
    switch (routeData.type) {
      case "add":
        // fetchFormData();
        break;
      case "edit":
        if (countries?.length !== 0 && SPIndividualTypes.length !== 0) {
          routeData.serviceTypeId !== undefined &&
            getIndividualServiceById(routeData.serviceTypeId);
        }
        break;
    }
  }, [countries, SPIndividualTypes]);

  return (
    <>
      <div className="flex items-center mb-10" id="title-bar">
        <ArrowLeftIcon
          className="h-7 w-7 mr-4 cursor-pointer text-gray-900"
          onClick={handlePreviousPage}
        />
        <h1 className="mb-0">
          <span className="capitalize">{routeData.type}</span> Individual
          Service
        </h1>
      </div>
      <Card title="Service Information" bordered={false} className="mb-3">
        <Form
          form={form}
          name="practitionerForm"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 780 }}
          initialValues={initialValues}
          // onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <div className="columns-1">
            <Form.Item
              key={"type"}
              id={"type"}
              label={"Service Name"}
              name={"type"}
              style={{ fontWeight: "500", width: "50%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter service name",
                },
              ]}
            >
              <Input placeholder="thai massage" autoComplete="nope" />
            </Form.Item>
          </div>
          <div className="columns-1 ">
            <Form.Item
              key={"businessTypeId"}
              id={"businessTypeId"}
              label={"Business Category"}
              name={"businessTypeId"}
              style={{ fontWeight: "500", width: "50%" }}
              rules={[
                {
                  required: true,
                  message: "Please select provider type",
                },
              ]}
            >
              <Select
                placeholder="Select business category"
                options={generateSelectOptions(SPIndividualTypes, [
                  "type",
                  "id",
                ])}
              />
            </Form.Item>
          </div>
          <div className="columns-1">
            <Form.Item
              key={"rateType"}
              id={"rateType"}
              label={"Rate Type"}
              name={"rateType"}
              style={{ fontWeight: "500", width: "50%" }}
              rules={[
                {
                  required: true,
                  message: "Please select a rate type",
                },
              ]}
            >
              <Select
                options={generateSelectOptions(rateOptions, ["name", "value"])}
                placeholder="Select rate"
              />
            </Form.Item>
          </div>
          <div className="columns-5">
            <Form.Item
              key={"availablePressureType"}
              id={"availablePressureType"}
              label={"Pressure Type"}
              name={"availablePressureType"}
              style={{ fontWeight: "500" }}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              key={"availableGender"}
              id={"availableGender"}
              label={"Gender"}
              name={"availableGender"}
              style={{ fontWeight: "500" }}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              key={"availableBookingType"}
              id={"availableBookingType"}
              label={"Booking Type"}
              name={"availableBookingType"}
              style={{ fontWeight: "500" }}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              key={"availablePatientCondition"}
              id={"availablePatientCondition"}
              label={"Patient Condition"}
              name={"availablePatientCondition"}
              style={{ fontWeight: "500" }}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              key={"availableTag"}
              id={"availableTag"}
              label={"Tags"}
              name={"availableTag"}
              style={{ fontWeight: "500" }}
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
          </div>
        </Form>
      </Card>
      <Card title="Country Rates" bordered={false} className="mb-3">
        <div className=" flex">
          <div className="px-2 md:w-1/3 mb-2">
            <Select
              style={{ width: "100%" }}
              placeholder="Select country"
              options={generateCountriesOptions(countries ? countries : [])}
              onSelect={handleSelectedCountry}
            />
          </div>
          <div className="w-full px-2 md:w-1/2 mb-3">
            <Button onClick={handleCountryRate} type="primary">
              Add a row
            </Button>
          </div>
        </div>
        <Form form={ratesform} component={false}>
          <Table
            components={tbComponents}
            rowClassName="editable-row"
            bordered
            dataSource={dataSource}
            columns={mergedColumns}
          />
        </Form>
      </Card>
      <div className="flex">
        <Button type="primary" ghost onClick={handlePreviousPage} size="large">
          Back
        </Button>
        <Button
          type="primary"
          htmlType="submit"
          size="large"
          className="btn-antd--primary"
          loading={isLoading}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
      {process.env.NODE_ENV === "development" && <Measure before={before} />}
    </>
  );
};

export default PractitionerServiceForm;
