import { ActionType, AppActions } from "./appActions";

const defaultAppState: AppState = {
  countries: [],
};

const appReducer = (state: AppState, action: AppActions) : AppState=> {
  switch (action.type) {
    case ActionType.AddCountires:
      return{
        ...state,
        countries:  action.payload
      }
    default:
      return defaultAppState;
  }
};


export default appReducer;