import React, { Fragment } from "react";
import { StatusCell } from "../../../templates";
export const TableCustomCell = ({
  data,
  itemKey,
}: ITableCustomCell): JSX.Element => {
  const renderCustomCell = (cellKey: string): JSX.Element => {
    let _tempCell = <div></div>;
    switch (cellKey) {
      case "status":
        _tempCell = <StatusCell data={data} itemKey={itemKey} />;
        break;
    }
    return _tempCell;
  };
  return <Fragment>{renderCustomCell(itemKey)}</Fragment>;
};
