import React, { useCallback } from "react";
import { TableCustomCell } from "../../../pages/Sessions/SessionSummary/TableCustomCell";
import { useHistory } from "react-router-dom";
import Card from "antd/es/card";
import Descriptions from "antd/es/descriptions";
import { Tag, Timeline, TimelineItemProps } from "antd";
import { getStatusColor } from "../../../utils/styleUtils";
import { CashIcon, CreditCardIcon } from "@heroicons/react/outline";
import Table, { ColumnsType } from "antd/es/table";
import ActionCell from "../../../templates/CustomCells/ActionCell";

interface IPaymentDetailsProps {
  payment?: PaymentSummary;
}

const PaymentDetails: React.FC<IPaymentDetailsProps> = ({
  payment,
}: IPaymentDetailsProps) => {
  const history = useHistory();
  const tableColumns: ColumnsType<Record<string, any>> = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "startDate",
      title: "Start Date",
      dataIndex: "startDate",
    },
    {
      key: "endDate",
      title: "End Date",
      dataIndex: "endDate",
    },
    {
      key: "message",
      title: "Message",
      dataIndex: "message",
    },
    {
      key: "providerStatus",
      title: "Provider Status",
      dataIndex: "providerStatus",
    },

    {
      key: "action",
      title: "Action",
      render: (_, record) => (
        <ActionCell
          data={record}
          actions={[{ key: "id", label: "View" }]}
          handlePrimaryFn={() => handleView(record?.id)}
        />
      ),
    },
  ];

  const handleView = (id: string) => {
    history.push({
      pathname: `/paymentSummary/${id}/`,
    });
  };

  const getTimeline = useCallback(() => {
    const _timeLine: TimelineItemProps[] = [
      { children: `Initiated ${payment?.paymentInitiatedDate}` },
    ];
    if (payment?.paymentCompletedDate !== "Invalid Date")
      _timeLine.push({
        children: `Completed ${payment?.paymentCompletedDate}`,
      });
    if (payment?.paymentCancelledDate !== "Invalid Date")
      _timeLine.push({
        children: `Cancelled ${payment?.paymentCancelledDate}`,
        color: "red",
      });

    return _timeLine;
  }, [payment]);

  return (
    <div id="payment-wrapper">
      <div className="grid grid-cols-1 gap-4 mb-4">
        {/*Row */}
        <div className="grid grid-cols-3 gap-4">
          <Card className="col-span-2">
            <Descriptions title="Payment Details" layout="vertical">
              <Descriptions.Item
                label="Payment Provider"
                labelStyle={{ fontWeight: "500" }}
                span={2}
              >
                {payment?.paymentProvider}
              </Descriptions.Item>
              <Descriptions.Item label="Status">
                <Tag
                  color={getStatusColor(payment?.status ? payment?.status : "")}
                >
                  {payment?.status.toString().toUpperCase()}
                </Tag>
              </Descriptions.Item>
              <Descriptions.Item
                label="Amount"
                labelStyle={{ fontWeight: "500" }}
              >
                <span className="uppercase">{payment?.currency}</span>
                {payment?.amount}
              </Descriptions.Item>

              <Descriptions.Item label="Payment Method" span={2}>
                <div className="flex align-middle">
                  {payment?.paymentMethod.toLowerCase() === "card" ? (
                    <CreditCardIcon className="w-6 mr-2  text-gray-900" />
                  ) : (
                    <CashIcon className="w-6 mr-2  text-gray-900" />
                  )}
                  <span>{payment?.paymentMethod}</span>
                </div>
              </Descriptions.Item>
            </Descriptions>
          </Card>
          <Card>
            <Timeline items={getTimeline()} />
          </Card>
        </div>
      </div>
      <Card title="Log">
        <Table
          dataSource={payment?.transactionHistory}
          columns={tableColumns}
        />
      </Card>
    </div>
  );
};

export default PaymentDetails;
