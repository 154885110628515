import {
  ProfileVerificationActions,
  ProfileVerificationSections,
} from "../constants/enums/profile";

/**
 * Function to get provider type from id
 *@method getProviderType
 *@param id
 *@returns  provider type
 */
export const getProviderType = (id: number): string => {
  let _providerType = "";
  switch (id) {
    case 1:
      _providerType = "Individuals";
      break;
    case 2:
      _providerType = "Business";
      break;
  }
  return _providerType;
};

/**
 * Function to get country from id
 *@method getCountryById
 *@param id
 *@param countries
 *@returns  country name
 */
export const getCountryById = (
  id: number,
  countries?: ICountries[]
): string => {
  const _country = countries?.find((result) => result.id === id);

  return _country?.name ?? "";
};

/**
 * Function to get business type from id
 *@method getBusinessTypeById
 *@param id
 *@param businessTypes
 *@returns  business type
 */
export const getBusinessTypeById = (
  id: number,
  businessTypes?: IBusinessType[]
): string => {
  const _type = businessTypes?.find((result) => result.id === id);

  return _type?.type ?? "";
};

export const getRateType = (id: number): string => {
  let _rateType = "";
  switch (id) {
    case 1:
      _rateType = "Flat";
      break;
    case 2:
      _rateType = "Hourly";
      break;
    case 3:
      _rateType = "Daily";
      break;
    case 4:
      _rateType = "Weekly";
      break;
  }
  return _rateType;
};
/**
 * Function to get gender type from id
 *@method getGenderType
 *@param id
 *@returns  gender type
 */

export const getGenderType = (id: number): string => {
  let _gender = "";
  switch (id) {
    case 0:
      _gender = "male";
      break;
    case 1:
      _gender = "female";
      break;
  }
  return _gender;
};
/**
 * Function to get marital status type from id
 *@method getMaritalStatus
 *@param id
 *@returns  marital type
 */
export const getMaritalStatus = (id: number): string => {
  let _status = "";
  switch (id) {
    case 1:
      _status = "single";
      break;
    case 2:
      _status = "married";
      break;
    case 3:
      _status = "separated";
      break;
    case 4:
      _status = "divorced";
      break;
  }
  return _status;
};
/**
 * Function to get contact type from id
 *@method getContactType
 *@param id
 *@returns  contact type
 */
export const getContactType = (id: number): string => {
  let _contactType = "";
  switch (id) {
    case 1:
      _contactType = "parent";
      break;
    case 2:
      _contactType = "relative";
      break;
    case 3:
      _contactType = "Friend / Guardian";
      break;
  }
  return _contactType;
};
/**
 * Function to get working type from id
 *@method getWorkingType
 *@param id
 *@returns  working type
 */
export const getWorkingType = (id: number): string => {
  let _workType = "";
  switch (id) {
    case 1:
      _workType = "fullTime";
      break;
    case 2:
      _workType = "partTime";
      break;
  }
  return _workType;
};

/**
 * Function to get profile status from id
 *@method getProfileStatus
 *@param id
 *@returns  status type
 */
export const getProfileStatus = (id: number): string => {
  let _statusType = "";
  switch (id) {
    case 0:
      _statusType = "pending";
      break;
    case 1:
      _statusType = "verified";
      break;
    case 2:
      _statusType = "pending from author";
      break;
    case 3:
      _statusType = "rejected";
      break;
  }
  return _statusType;
};
/**
 * Function to build individual profile summary
 *@method handleProfileSummary
 *@param profile required profile data from endpoint
 *@returns  array of summary sections
 */
export const handleProfileSummary = (
  profile: IUserProfileSummary
): ISummarySection[] => {
  const _summary: ISummarySection[] = [
    {
      header: {
        title: "Account Details",
        subtitle: "Account details and application.",
      },
      body: [
        { id: "id", label: "Profile ID", value: "" },
        { id: "guid", label: "Profile GUID", value: "" },
        { id: "name", label: "Name", value: "" },
        {
          id: "createdDateTime",
          label: "Date Joined",
          value: "",
        },
        { id: "mobile", label: "Mobile Number", value: "" },
        { id: "email", label: "Email", value: "" },
        { id: "countryId", label: "Country", value: "" },
        { id: "currentAddress", label: "Address", value: "" },
      ],
    },
    {
      header: {
        title: "Profile Details",
        subtitle: "Profile details and application.",
      },
      body: [
        { id: "businessTypeId", label: "Service Type", value: "" },
        { id: "dateOfBirth", label: "DOB", value: "" },
        { id: "gender", label: "Gender", value: "" },
        { id: "identityNumber", label: "IC/Passport Number", value: "" },
        {
          id: "identityAttachmentUrl",
          label: "IC/Passport Attachment",
          value: "",
          list: [],
        },
        { id: "maritalStatus", label: "Martial Status", value: "" },
        { id: "haveCar", label: "Own Transport", value: "" },
      ],
    },
    {
      header: {
        title: "Emergency Contact Details",
        subtitle: "Other contact details incase of an Emergency",
      },
      body: [
        { id: "emergencyContactName", label: "Name", value: "" },
        {
          id: "emergencyContactType",
          label: "Relationship to Applicant",
          value: "",
        },
        { id: "emergencyContactNumber", label: "Active Contact No", value: "" },
      ],
    },
    {
      header: {
        title: "Education & Experience Details",
        subtitle: "Qualification documents and other",
      },
      body: [
        {
          id: "professionalExperience",
          label: "Professional Experience",
          value: "",
        },
        {
          id: "workingType",
          label: "Work Type",
          value: "",
        },
        { id: "qualificationId", label: "Education Qualification", value: "" },
        { id: "spokenLanguges", label: "Spoken Languages", value: "" },
        { id: "documents", label: "Attachments", value: "", list: [] },
      ],
    },
  ];

  Object.entries(profile).forEach((profileProp: Record<string, any>) => {
    _summary.forEach((section) => {
      section.body.forEach((formObj) => {
        if (formObj.id === profileProp[0]) {
          switch (formObj.id) {
            case "documents":
              const _documents: SummaryList[] = [];
              profileProp[1].forEach((result: UserDocument) => {
                const _obj: SummaryList = {
                  id: result.attchementId,
                  label: result.attchementName,
                  value: result.attchementUrl,
                  icon: "PaperClipIcon",
                };

                _documents.push(_obj);
              });

              formObj.list = _documents;
              break;
            case "identityAttachmentUrl":
              const _attachment: SummaryList[] = [];
              const _obj: SummaryList = {
                id: "",
                label: "attachment",
                value: profileProp[1],
                icon: "PaperClipIcon",
              };
              _attachment.push(_obj);
              formObj.list = _attachment;
              break;
            default:
              formObj.value = profileProp[1];
          }
        }
      });
    });
  });
  return _summary;
};
/**
 * Function to build business profile summary
 *@method handleBusinessProfileSummary
 *@param profile required profile data from endpoint
 *@returns  array of summary sections
 */
export const handleBusinessProfileSummary = (
  profile: IUserProfileSummary
): ISummarySection[] => {
  const _summary: ISummarySection[] = [
    {
      header: {
        title: "Account Details",
        subtitle: "Account details and application.",
      },
      body: [
        { id: "id", label: "Profile ID", value: "" },
        { id: "guid", label: "Profile GUID", value: "" },
        { id: "name", label: "Name", value: "" },
        {
          id: "createdDateTime",
          label: "Date Joined",
          value: "",
        },
        { id: "mobile", label: "Mobile Number", value: "" },
        { id: "email", label: "Email", value: "" },
        { id: "countryId", label: "Country", value: "" },
      ],
    },
    {
      header: {
        title: "Business Details",
        subtitle: "Business  details and application.",
      },
      body: [
        { id: "businessTypeId", label: "Service Type", value: "" },
        { id: "businessName", label: "Business Name", value: "" },
        { id: "address", label: "Address", value: "" },
        {
          id: "bussiessContactNumber",
          label: "Bussiess Contact Number",
          value: "",
        },
        {
          id: "registrationNumber",
          label: "Bussiess Registration Number",
          value: "",
        },
        {
          id: "registrationAttachmentUrl",
          label: "Registration Attachment",
          value: "",
          list: [],
        },
        { id: "noOfBranches", label: "No Of Branches", value: "" },
        { id: "employeeSize", label: "Employee Size", value: "" },
        { id: "description", label: "Description", value: "" },
      ],
    },
  ];

  Object.entries(profile).forEach((profileProp: Record<string, any>) => {
    _summary.forEach((section) => {
      section.body.forEach((formObj) => {
        if (formObj.id === profileProp[0]) {
          switch (formObj.id) {
            case "registrationAttachmentUrl":
              const _attachment: SummaryList[] = [];
              const _obj: SummaryList = {
                id: "",
                label: "attachment",
                value: profileProp[1],
                icon: "PaperClipIcon",
              };
              _attachment.push(_obj);
              formObj.list = _attachment;
              break;
            default:
              formObj.value = profileProp[1];
          }
        }
      });
    });
  });
  return _summary;
};
/**
 * Function to get verification action
 *@method getVerificationAction
 *@param id
 *@returns  action name
 */
export const getVerificationAction = (id: number): string => {
  let _action = "";
  switch (id) {
    case ProfileVerificationActions.pending:
      _action = "Pending";
      break;
    case ProfileVerificationActions.verified:
      _action = "Verified";
      break;
    case ProfileVerificationActions.pendingFromServiceProvider:
      _action = "Pending from SP";
      break;
    case ProfileVerificationActions.rejected:
      _action = "Rejected";
      break;
  }
  return _action;
};
/**
 * Function to get verification section
 *@method getVerificationSections
 *@param id
 *@returns  section name
 */
export const getVerificationSections = (id: number): string => {
  let _section = "";
  switch (id) {
    case ProfileVerificationSections.all:
      _section = "All";
      break;
    case ProfileVerificationSections.education:
      _section = "Education";
      break;
    case ProfileVerificationSections.profile:
      _section = "Profile";
      break;
  }
  return _section;
};
