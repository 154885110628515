import { MasterDataActionType, MasterDataActions } from "./masterDataActions";

const defaulMasterDataState: TMasterDataState = {
  SPIndividualTypes: [],
  SPBusinessTypes: [],
};

const masterDataReducer = (
  state: TMasterDataState,
  action: MasterDataActions
): TMasterDataState => {
  switch (action.type) {
    case MasterDataActionType.GetSPIndividualTypes:
      return {
        ...state,
        SPIndividualTypes: action.payload,
      };
    case MasterDataActionType.GetSPBusinessTypes:
      return {
        ...state,
        SPBusinessTypes: action.payload,
      };
    default:
      return defaulMasterDataState;
  }
};

export default masterDataReducer;
