import { ColumnsType } from "antd/es/table/interface";
import Table from "antd/es/table/Table";
import React from "react";
import { useHistory } from "react-router-dom";
import Measure from "../../../components/atoms/Measure/Measure";
import ActionCell from "../../../templates/CustomCells/ActionCell";

import { UserCell } from "../../../templates/CustomCells/UserCell";

interface IVerifiedBusinessesTabProps {
  tbData: ITableCell[] | undefined;
}

export const VerifiedBusinessesTab: React.FC<IVerifiedBusinessesTabProps> = ({
  tbData,
}: IVerifiedBusinessesTabProps) => {
  const history = useHistory();

  const columns: ColumnsType<Record<string, any>> = [
    {
      key: "id",
      title: "ID",
      dataIndex: "id",
    },
    {
      key: "user",
      title: "Applicant Name",
      dataIndex: "user",
      render: (_, record) => <UserCell data={record} itemKey={"user"} />,
    },
    {
      key: "country",
      title: "Country",
      dataIndex: "country",
    },
    {
      key: "businessType",
      title: "Service Category",
      dataIndex: "businessType",
    },
    {
      key: "created",
      title: "Created Date",
      dataIndex: "created",
    },
    {
      key: "action",
      title: "Action",
      render: (_, record) => (
        <ActionCell
          data={record}
          actions={[{ key: "id", label: "View" }]}
          handlePrimaryFn={handleViewProfile}
        />
      ),
    },
  ];

  const handleViewProfile = (id: number | string) => {
    history.push({
      pathname: `/businessprofile/${id}`,
    });
  };
  const before = process.env.NODE_ENV === "development" ? performance.now() : 0;
  return (
    <>
      <div className="mb-3" id="title-bar">
        <h2 className="dark:text-slate-900">Verified Registrations</h2>
      </div>

      <Table dataSource={tbData} columns={columns} />

      {process.env.NODE_ENV === "development" && <Measure before={before} />}
    </>
  );
};
