import React, { useContext, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { FormState, useFormInput } from "../../hooks";
import { ArrowLeftIcon } from "@heroicons/react/outline";

import axios from "../../lib/axios/axios.nuvad";
import Card from "antd/es/card";
import Form from "antd/es/form";
import Button from "antd/es/button";
import Select from "antd/es/select";
import { generateSelectOptions } from "../../utils/formUtils";
import Input from "antd/es/input";
import { ProviderType } from "../../constants/enums/masterData";
import ErrorContext from "../errorhandling/context/errorContext";
import AppContext from "../../context/App/AppContext";
import { getErrorObj } from "../../utils/errorHandlingUtils";

interface IBusinessTypeFormRouteProps {
  type: string;
  businessTypeId?: number;
}
interface IBusinessTypeFormConfig {
  providerType: IFormConfig;
  type: IFormConfig;
  coverImageUrl: IFormConfig;
}

const initialValues = {
  id: 0,
  coverImageUrl: "",
  type: "",
  providerType: 0,
};

const providerOptions = [
  { name: "Individual", value: ProviderType.individual },
  { name: "Business", value: ProviderType.business },
];
/**
 * Business type form page to and and edit business types
 * @category Forms
 * @component
 * @namespace CategoryForm
 * @returns CategoryForm component form page
 */

const CategoryForm: React.FC = () => {
  //context
  const { triggerNotification } = useContext(AppContext);
  const { setError } = useContext(ErrorContext);

  const history = useHistory();
  const { state: routeData } = useLocation<IBusinessTypeFormRouteProps>();

  // const [initialFormValues, setInitialFormValues] =
  //   useState<IBusinessType>(initialValues);

  // const [form, setForm] = useState<IFormInput[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  // Custom hooks
  const [form] = Form.useForm();
  // const { values, handleChange } = useFormInput(
  //   initialFormValues as unknown as FormState
  // );

  /**
   * Function to handle form submit
   *@method handleSubmit
   *@param event
   */
  const handleSubmit = () => {
    const _businessType: IBusinessType = {
      id: routeData.businessTypeId !== undefined ? routeData.businessTypeId : 0,
      ...form.getFieldsValue(),
    };

    if (routeData.type === "edit") {
      editBusinessType(_businessType);
    } else {
      addNewBusinessType(_businessType);
    }
  };
  /**
   * Function to add new business type
   *@method addNewBusinessType
   *@param businessTypePayload
   */
  const addNewBusinessType = (businessTypePayload: IBusinessType) => {
    axios
      .post("/admin-api/BusinessTypes", businessTypePayload)
      .then((response) => {
        setIsLoading(false);
        triggerNotification({
          type: "success",
          title: "Add Business Category",
          message: <p>{form.getFieldValue("type")} added successfully.</p>,
        });
      })
      .catch((error) => {
        setIsLoading(false);
        const _errorObj = getErrorObj(error.response);
        setError(_errorObj);
      });
  };
  /**
   * Function to edit business type
   *@method editBusinessType
   *@param businessTypePayload
   */
  const editBusinessType = (businessTypePayload: IBusinessType) => {
    axios
      .put("/admin-api/BusinessTypes", businessTypePayload)
      .then((response) => {
        setIsLoading(false);
        triggerNotification({
          type: "success",
          title: "Edit Business Category",
          message: <p>{form.getFieldValue("type")} edited successfully.</p>,
        });
      })
      .catch((error) => {
        setIsLoading(false);
        const _errorObj = getErrorObj(error.response);
        setError(_errorObj);
      });
  };
  /**
   * Function to add new bank
   *@method getBusinessTypeById
   *@param id id of business type
   */
  const getBusinessTypeById = (id: number) => {
    axios
      .get(`/admin-api/BusinessTypes/${id}`)
      .then((response) => {
        const { id, providerType, type, coverImageUrl }: IBusinessType =
          response.data;
        const _businessType = {
          providerType: providerType,
          type: type,
          coverImageUrl: coverImageUrl,
        };

        form.setFieldsValue(_businessType);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /**
   * Function to go back to previous page
   *@method handlePreviousPage
   */
  const handlePreviousPage = () => {
    history.goBack();
  };
  /**
   * Function to handle form fail
   *@method onFinishFailed
   */
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  useEffect(() => {
    switch (routeData.type) {
      case "add":
        // fetchFormData();
        break;
      case "edit":
        routeData.businessTypeId !== undefined &&
          getBusinessTypeById(routeData.businessTypeId);
        break;
      default:
    }
  }, []);
  return (
    <>
      <div className="flex items-center mb-10" id="title-bar">
        <ArrowLeftIcon
          className="h-7 w-7 mr-4 cursor-pointer text-gray-900"
          onClick={handlePreviousPage}
        />
        <h1 className="mb-0">
          <span className="capitalize">{routeData.type}</span> Business Category
        </h1>
      </div>
      <Card title="Category Information" bordered={false}>
        <Form
          form={form}
          name="countryForm"
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          style={{ maxWidth: 780 }}
          initialValues={{ remember: true }}
          onFinish={handleSubmit}
          onFinishFailed={onFinishFailed}
          layout="vertical"
        >
          <div className="columns-1 ">
            <Form.Item
              key={"providerType"}
              id={"providerType"}
              label={"Provider Type"}
              name={"providerType"}
              style={{ fontWeight: "500", width: "50%" }}
              rules={[
                {
                  required: true,
                  message: "Please select provider type",
                },
              ]}
            >
              <Select
                options={generateSelectOptions(providerOptions, [
                  "name",
                  "value",
                ])}
              />
            </Form.Item>
          </div>
          <div className="columns-1">
            <Form.Item
              key={"type"}
              id={"type"}
              label={"Category Name"}
              name={"type"}
              style={{ fontWeight: "500", width: "50%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter category name",
                },
              ]}
            >
              <Input placeholder="massage therapist" autoComplete="nope" />
            </Form.Item>
          </div>
          <div className="columns-1">
            <Form.Item
              key={"coverImageUrl"}
              id={"isoCocoverImageUrlde"}
              label={"Image Url"}
              name={"coverImageUrl"}
              style={{ fontWeight: "500", width: "50%" }}
              rules={[
                {
                  required: true,
                  message: "Please enter img url",
                },
              ]}
            >
              <Input autoComplete="nope" />
            </Form.Item>
          </div>

          <Form.Item wrapperCol={{ span: 16 }}>
            <Button
              type="primary"
              ghost
              onClick={handlePreviousPage}
              size="large"
            >
              Back
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              className="btn-antd--primary"
              loading={isLoading}
            >
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </>
  );
};

export default CategoryForm;
