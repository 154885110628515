import React, { useState, useEffect } from "react";

import {
  NewRegistrationsTab,
  PendingRegistrationsTab,
  VerifiedRegistrationsTab,
} from "./";

import axios from "../../lib/axios/axios.nuvad";
import Tabs, { TabsProps } from "antd/es/tabs";
import dayjs from "dayjs";

export const Practitioners: React.FC = () => {
  const [tab, setTab] = useState<string>("new");
  const [newPractitioners, setNewPractitioners] = useState<ITableCell[]>();
  const [pendingPractitioners, setPendingPractitioners] =
    useState<ITableCell[]>();
  const [verifiedPractitioners, setVerifiedPractitioners] =
    useState<ITableCell[]>();

  /**
   * Get new user registrations
   * @method getNewRegistrations
   */
  const getNewRegistrations = async () => {
    const params = {
      page: 0,
      pageSize: 50,
      isFiltered: false,
      filterText: "",
      columnsToFilter: [],
      sorts: [],
    };
    await axios
      .post("provider-api/UserProfiles/GetUserProfilesforVerification", params)
      .then((response) => {
        const _tempUsers: ITableCell[] = [];
        // get new user registrations
        const _newProfiles: CreatedProfile[] =
          response.data.userProfiles.filter((elm: CreatedProfile) => {
            return (
              elm["providerType"]["type"] === "Individual" &&
              elm["status"] === 0
            );
          });

        if (_newProfiles.length !== 0) {
          //get keys from response
          const objKeys = _newProfiles && Object.keys(_newProfiles[0]);

          //Build final array to bind to table
          _newProfiles.forEach((profile) => {
            const _Obj: ITableCell = {};
            objKeys.forEach((colName: string) => {
              switch (colName) {
                case "country":
                  _Obj[colName] = profile[colName].name;
                  break;
                case "id":
                  _Obj[colName] = profile[colName].toString();
                  break;
                case "user":
                  _Obj["profilePictureUrl"] = profile["profilePictureUrl"];
                  _Obj[
                    "mobile"
                  ] = `${profile["country"].countryCode}${profile[colName].mobile}`;
                  _Obj["name"] = profile[colName].name;
                  _Obj["email"] = profile[colName].email;
                  break;
                case "businessType":
                  _Obj[colName] = profile[colName] && profile[colName].type;
                  break;
                case "created":
                  _Obj[colName] = dayjs(profile[colName]).format(
                    "DD/MM/YYYY h:mm A"
                  );
                  break;
                case "status":
                  if (profile[colName] === 0) _Obj[colName] = "Pending";
                  if (profile[colName] === 1) _Obj[colName] = "Verified";
                  if (profile[colName] === 2)
                    _Obj[colName] = "Pending from Author";
                  if (profile[colName] === 2) _Obj[colName] = "Rejected";
                  break;
                default:
              }
            });
            _tempUsers.push(_Obj);
          });

          setNewPractitioners(_tempUsers);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /**
   * Get pending user registrations
   * @method getPendingRegistrations
   */
  const getPendingRegistrations = async () => {
    const params = {
      page: 0,
      pageSize: 50,
      isFiltered: false,
      filterText: "",
      columnsToFilter: [],
      sorts: [],
    };
    await axios
      .post("provider-api/UserProfiles/GetUserProfilesforVerification", params)
      .then((response) => {
        const _tempUsers: ITableCell[] = [];

        // get pending user registrations
        const pendingProfiles: CreatedProfile[] =
          response.data.userProfiles.filter((elm: CreatedProfile) => {
            return (
              elm["providerType"]["type"] === "Individual" &&
              elm["status"] === 2
            );
          });
        if (pendingProfiles.length !== 0) {
          //get keys from response
          const objKeys = Object.keys(pendingProfiles[0]);
          //Build final array to bind to table
          pendingProfiles.forEach((profile) => {
            const _Obj: ITableCell = {};
            objKeys.forEach((colName: string) => {
              switch (colName) {
                case "country":
                  _Obj[colName] = profile[colName].name;
                  break;
                case "id":
                  _Obj[colName] = profile[colName].toString();
                  break;
                case "user":
                  _Obj["profilePictureUrl"] = profile["profilePictureUrl"];
                  _Obj[
                    "mobile"
                  ] = `${profile["country"].countryCode}${profile[colName].mobile}`;
                  _Obj["name"] = profile[colName].name;
                  _Obj["email"] = profile[colName].email;
                  break;
                case "businessType":
                  _Obj[colName] = profile[colName] && profile[colName].type;
                  break;
                case "created":
                  _Obj[colName] = dayjs(profile[colName]).format(
                    "DD/MM/YYYY h:mm A"
                  );
                  break;
                case "status":
                  if (profile[colName] === 0) _Obj[colName] = "New";
                  if (profile[colName] === 1) _Obj[colName] = "Verified";
                  if (profile[colName] === 2)
                    _Obj[colName] = "Pending from Author";
                  if (profile[colName] === 3) _Obj[colName] = "Rejected";
                  break;
                default:
              }
            });
            _tempUsers.push(_Obj);
          });
          setPendingPractitioners(_tempUsers);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  /**
   * Get verified user profiles
   * @method getVerifiedUserProfiles
   */
  const getVerifiedUserProfiles = async () => {
    await axios
      .get(`provider-api/UserProfiles/GetUserProfiles?Page=${0}&PageSize=${50}`)
      .then((response) => {
        const _tempUsers: ITableCell[] = [];

        // get verified user registrations
        const _verifiedProfiles: CreatedProfile[] =
          response.data.userProfiles.filter((elm: CreatedProfile) => {
            return (
              elm["providerType"]["type"] === "Individual" &&
              elm["status"] === 1
            );
          });
        if (_verifiedProfiles.length !== 0) {
          //get keys from response
          const objKeys =
            _verifiedProfiles && Object.keys(_verifiedProfiles[0]);

          //Build final array to bind to table
          _verifiedProfiles.forEach((profile) => {
            const _Obj: ITableCell = {};
            objKeys.forEach((colName: string) => {
              switch (colName) {
                case "country":
                  _Obj[colName] = profile[colName].name;
                  break;
                case "id":
                  _Obj[colName] = profile[colName].toString();
                  break;
                case "user":
                  _Obj["profilePictureUrl"] = profile["profilePictureUrl"];
                  _Obj[
                    "mobile"
                  ] = `${profile["country"].countryCode}${profile[colName].mobile}`;
                  _Obj["name"] = profile[colName].name;
                  _Obj["email"] = profile[colName].email;
                  break;
                case "businessType":
                  _Obj[colName] = profile[colName] && profile[colName].type;
                  break;
                case "created":
                  _Obj[colName] = dayjs(profile[colName]).format(
                    "DD/MM/YYYY h:mm A"
                  );
                  break;
                case "status":
                  if (profile[colName] === 0) _Obj[colName] = "New";
                  if (profile[colName] === 1) _Obj[colName] = "Verified";
                  if (profile[colName] === 2)
                    _Obj[colName] = "Pending from Author";
                  if (profile[colName] === 3) _Obj[colName] = "Rejected";
                  break;
                default:
              }
            });
            _tempUsers.push(_Obj);
          });

          setVerifiedPractitioners(_tempUsers);
        }
      })
      .catch((error) => {
        console.log(error, "getVerifiedUserProfiles");
      });
  };

  useEffect(() => {
    switch (tab) {
      case "new":
        getNewRegistrations();
        break;
      case "pending":
        getPendingRegistrations();
        break;
      case "verified":
        getVerifiedUserProfiles();
        break;
    }
  }, [tab]);

  /**
   * fn to switch tabs
   *@method switchTabs
   *@param index tab number
   */
  const switchTabs = (key: string) => {
    setTab(key);
  };
  const tabs: TabsProps["items"] = [
    {
      key: "new",
      label: `New`,
      children: <NewRegistrationsTab tbData={newPractitioners} />,
    },
    {
      key: "pending",
      label: `Pending`,
      children: <PendingRegistrationsTab tbData={pendingPractitioners} />,
    },
    {
      key: "verified",
      label: `Verified`,
      children: <VerifiedRegistrationsTab tbData={verifiedPractitioners} />,
    },
  ];
  return (
    <>
      <h1 className="mb-10">Practitioners</h1>
      <div id="tab-wrapper">
        <Tabs
          defaultActiveKey="new"
          items={tabs}
          size="large"
          onChange={switchTabs}
        />
      </div>
    </>
  );
};
