import { Fragment } from "react";
import { getStatusColor } from "../../utils";
import { Tag } from "antd";
export const StatusCell = ({
  data,
  itemKey,
}: ITableCustomCell): JSX.Element => {
  return (
    <Fragment>
      <div className="flex" id={itemKey}>
        <Tag color={getStatusColor(data.status)} key={itemKey}>
          {data?.status?.toString().toUpperCase()}
        </Tag>
        {/* <span className="relative inline-block px-3 py-1 font-semibold text-green-900 leading-tight ">
          <span
            aria-hidden
            className={`absolute inset-0 rounded-full ${getStatusColor(
              data.status
            )}`}
          ></span>
          <span className="relative text-white capitalize">{data.status}</span>
        </span> */}
      </div>
    </Fragment>
  );
};
