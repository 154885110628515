import React from "react";
import AuthService from "../auth/authService";

interface AuthProviderProps {
  children: React.ReactNode;
}
interface AuthContextData {
  signinRedirectCallback: () => void;
  logout: () => void;
  signoutRedirectCallback: () => void;
  isAuthenticated: () => boolean;
  signinRedirect: () => void;
  signinSilentCallback: () => void;
  createSigninRequest: () => void;
}
export const AuthContext = React.createContext({
  signinRedirectCallback: () => ({}),
  logout: () => ({}),
  signoutRedirectCallback: () => ({}),
  isAuthenticated: () => false,
  signinRedirect: () => ({}),
  signinSilentCallback: () => ({}),
  createSigninRequest: () => ({}),
} as AuthContextData);

export const AuthConsumer = AuthContext.Consumer;

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children,
}: AuthProviderProps) => {
  const [authService] = React.useState<AuthContextData>(new AuthService());
  return (
    <AuthContext.Provider value={authService}>{children}</AuthContext.Provider>
  );
};
